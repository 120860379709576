import { Component, OnInit, OnDestroy, inject } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";
import { SharedService } from "src/app/services/shared.service";
import { TargetsService } from "../targets.service";

import { RtmpPushTarget, TargetApiType } from "../../channels/channel";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TitleService } from "../../../services/title.service";
import { ClipboardService } from "ngx-clipboard";
import { UntypedFormControl, Validators } from "@angular/forms";
import { urlBuilder } from "@zixi/shared-utils";
import { TranscodingProfile } from "../../transcoding-profiles/transcoding-profile";
@Component({
    selector: "app-target-rtmp-form",
    templateUrl: "./target-rtmp-form.component.html"
})
export class TargetRtmpFormComponent implements OnInit, OnDestroy {
    target: RtmpPushTarget;
    targetId: number;
    targetName: string;
    targetNames: string[];
    existingTarget: RtmpPushTarget;
    action: string;

    channelType: "passthrough_channel" | "medialive_channel" = "passthrough_channel";

    type: string;
    subtype: string;
    id: string;
    pid_mapping_profile_id: number | null;

    ingestURLs: string[];

    showAdvanced = false;
    loading = true;
    saving = false;

    submitted = false;
    minLength = 2;
    isEdit = false;
    isClone = false;
    startDisabled = false;
    constants = Constants;
    transcodeProfile: TranscodingProfile;

    private targetsSubscription: Subscription;

    selectedChannelID: number | null = null;

    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.source_name),
        Validators.pattern(Constants.validators.no_blanc_start_or_end)
    ]);
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private sharedService = inject(SharedService);
    private modalService = inject(ModalService);
    private ts = inject(TargetsService);
    private mixpanelService = inject(MixpanelService);
    private titleService = inject(TitleService);
    private cbs = inject(ClipboardService);
    private location = inject(Location);

    prepForm() {
        if (!this.target && this.action) {
            return;
        }

        if (this.action) {
            this.tagsControl.setValue(this.target.resourceTags);

            if (this.target.pid_mapping) {
                this.pid_mapping_profile_id = this.target.pid_mapping.id;
            }

            if (this.action === "edit") {
                this.nameControl.setValue(this.target.name);
                this.isEdit = true;
            } else if (this.action === "clone") {
                this.isClone = true;
                this.target.name = "";
                this.target.muted = this.target.active_mute ? 1 : 0;
            }
        }

        if (!this.target && !this.isClone && !this.isEdit) {
            this.resetForm();
        }

        if (this.isEdit || this.isClone) {
            this.channelType = this.target.medialive_channel_id != null ? "medialive_channel" : "passthrough_channel";
            this.selectedChannelID = this.target.medialive_channel_id || this.target.delivery_channel_id;
            this.transcodeProfile = this.target.transcodingProfile;
        }

        // Set Title
        this.titleService.setTitle("TARGET", this.action, this.target);
    }

    resetForm() {
        this.tagsControl.setValue([]);
        this.nameControl.setValue(null);

        // Channel
        this.target = new RtmpPushTarget();
        this.target.billing_code = null;
        this.target.billing_password = null;
        this.target.single_output = 1;
        this.target.location = {};
        this.channelType = "passthrough_channel";
        this.transcodeProfile = undefined;
    }

    async ngOnInit() {
        const params = this.route.snapshot.params;
        if (params.targetId) this.targetId = urlBuilder.decode(params.targetId);
        this.targetName = params.name;
        this.action = params.action;
        this.type = params.type;
        this.subtype = params.subtype;
        this.id = params.id;

        if (this.action === "edit") this.isEdit = true;
        if (this.action === "clone") this.isClone = true;

        let anyTarget = null;

        if (this.targetId) {
            anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, TargetApiType.Rtmp));

            // Check if target found in cache, if not get targets and target
            if (this.sharedService.isEmptyObject(anyTarget)) {
                await this.ts.refreshTargets(TargetApiType.Rtmp).toPromise();
                anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, TargetApiType.Rtmp));
                await this.ts.refreshTarget(TargetApiType.Rtmp, anyTarget.objId, true).toPromise();
                anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, TargetApiType.Rtmp));
            } else if (!anyTarget.target.hasFullDetails) {
                await this.ts.refreshTarget(TargetApiType.Rtmp, anyTarget.objId, true).toPromise();
                anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, TargetApiType.Rtmp));
            }
        }

        if (anyTarget && anyTarget.target instanceof RtmpPushTarget) {
            this.target = anyTarget.target;
            this.existingTarget = _.cloneDeep(this.target);
        }

        this.loading = false;

        if (this.type === "channel" && this.subtype && this.id) {
            this.selectedChannelID = parseInt(this.id, 10);
            if (this.subtype === "delivery") {
                this.channelType = "passthrough_channel";
            } else if (this.subtype === "medialive") {
                this.channelType = "medialive_channel";
            }
        }

        this.ts.getAllTargets();

        this.targetsSubscription = this.ts.targets.subscribe(targets => {
            this.targetNames = _.map(
                _.filter(targets, t => t.apiType === TargetApiType.Rtmp),
                "target.name"
            );
            this.ingestURLs = _.map(targets, "target.ingest_url");

            if (this.isEdit) {
                this.targetNames = _.without(this.targetNames, this.targetName);
            }
        });

        this.prepForm();
    }

    ngOnDestroy() {
        this.targetsSubscription.unsubscribe();
    }

    resetChannelId() {
        this.selectedChannelID = null;
    }

    async onSubmit() {
        this.saving = true;

        const model = {
            name: this.nameControl.value,

            resource_tag_ids: _.map(this.tagsControl.value, "id"),
            alerting_profile_id: this.target.alertingProfile.id,
            type: "rtmp",
            username: this.target.username,
            password: this.target.password,
            target: this.target.target,
            alt_target: this.target.alt_target,
            stream_name: this.target.stream_name,
            kbps: this.target.kbps,
            delivery_channel_id: this.channelType === "passthrough_channel" ? this.selectedChannelID : null,
            medialive_channel_id: this.channelType === "medialive_channel" ? this.selectedChannelID : null,
            transcoding_profile_id: this.transcodeProfile ? this.transcodeProfile.id : null,
            location: this.target.location.address,
            muted: this.target.muted,
            is_enabled:
                !this.isEdit && this.startDisabled
                    ? 0
                    : !this.isEdit && !this.startDisabled
                    ? 1
                    : this.target.is_enabled,
            billing_code: this.target.billing_code,
            billing_password: this.target.billing_password,
            preferred_source: this.target.preferred_source,
            single_output: this.target.single_output,
            pid_mapping_profile_id: this.channelType === "passthrough_channel" ? this.pid_mapping_profile_id : null,
            external_id: this.target.external_id || "",
            pois_latency_offset: this.target.pois_latency_offset ?? null
        };

        if (this.isEdit) {
            const objects = {
                resource_tag_ids: {
                    objectsKey: "resourceTags",
                    valuePath: "id",
                    pid_mapping_profile_id: { objectsKey: "pid_mapping", valuePath: "id" }
                }
            };
            const changedData = this.sharedService.getZixiObjDiff(model, this.existingTarget, [], objects);
            const isEmptyData = this.sharedService.isEmptyObject(changedData);

            if (!isEmptyData) {
                const updatedTarget = await this.ts.updateTarget(this.existingTarget, {
                    ...changedData,
                    restart_confirmed: false
                });
                const showPopupMessageDialog = updatedTarget;
                // Restart Notice
                if (showPopupMessageDialog === true) {
                    await this.modalService.confirm(
                        "SAVE_RESTART",
                        "TARGET",
                        async () => {
                            const updateAndRestartTarget = await this.ts.updateTarget(this.target, {
                                ...changedData,
                                restart_confirmed: true
                            });
                            if (updateAndRestartTarget) {
                                this.saving = false;
                                this.mixpanelService.sendEvent("update & restart rtmp target", {
                                    updated: Object.keys(changedData)
                                });
                                this.router.navigate(
                                    urlBuilder.getRegularTargetUrl(
                                        this.targetId,
                                        Constants.urls.targetTypes.rtmp,
                                        model.name
                                    )
                                );
                            } else this.saving = false;
                        },
                        this.target.name
                    );
                    this.saving = false;
                } else if (updatedTarget) {
                    this.saving = false;
                    this.mixpanelService.sendEvent("update rtmp target target", {
                        updated: Object.keys(changedData)
                    });
                    this.router.navigate(
                        urlBuilder.getRegularTargetUrl(this.targetId, Constants.urls.targetTypes.rtmp, model.name)
                    );
                } else this.saving = false;
            } else {
                this.saving = false;
                this.router.navigate(
                    urlBuilder.getRegularTargetUrl(this.targetId, Constants.urls.targetTypes.rtmp, model.name)
                );
            }
        } else {
            const result = await this.ts.addTarget(model, TargetApiType.Rtmp);
            if (result) {
                this.saving = false;
                this.mixpanelService.sendEvent("create rtmp target");
                this.router.navigate(
                    urlBuilder.getRegularTargetUrl(result.id, Constants.urls.targetTypes.rtmp, model.name)
                );
            } else this.saving = false;
        }
    }

    cancel() {
        if (this.isEdit || this.isClone)
            this.router.navigate(
                urlBuilder.getRegularTargetUrl(this.targetId, Constants.urls.targetTypes.rtmp, this.targetName)
            );
        else this.router.navigate([Constants.urls.targets]);
    }

    back() {
        this.location.back();
    }

    copyPassword(pw: string) {
        this.cbs.copy(pw);
    }
}
