import {
    Component,
    OnInit,
    OnDestroy,
    QueryList,
    ViewChildren,
    ViewChild,
    ElementRef,
    ComponentRef
} from "@angular/core";
import { Router, ActivationEnd, RouterOutlet } from "@angular/router";
import { BehaviorSubject, Subscription, interval, firstValueFrom } from "rxjs";
import { filter, take } from "rxjs/operators";
import _ from "lodash";

import { NgbSortableHeader } from "../../../directives/sortable.directive";

import { TranslateService } from "@ngx-translate/core";
import { StatusTextPipe } from "../../../pipes/status-text.pipe";
import { Constants } from "../../../constants/constants";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { SharedService } from "../../../services/shared.service";
import { UsersService } from "../../account-management/users/users.service";
import { Broadcaster, KeyMap, Tag, UserPermissions } from "../../../models/shared";
import { ClusterComponent } from "../cluster/cluster.component";
import { ClustersService } from "../clusters.service";
import { Cluster } from "../cluster";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TitleService } from "../../../services/title.service";
import { UptimePipe } from "src/app/pipes/uptime.pipe";
import { DecimalPipe } from "@angular/common";
import { urlBuilder } from "@zixi/shared-utils";
import { TableListComponent, TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxStatusFullComponent } from "src/app/components/shared/zx-status-full/zx-status-full.component";
import { assignComponentsStatusInputsFactory } from "src/app/components/shared/zx-status-full/zx-status-full.table-adapter";
import { ZxTagsListComponent } from "src/app/components/shared/zx-tags-list/zx-tags-list.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ZxClusterBroadcastersColComponent } from "src/app/components/shared/zx-cluster-broadcasters-col/zx-cluster-broadcasters-col.component";
import { ColumnFilterType } from "src/app/components/shared/filter/filter.component";
import {
    IconColumnComponent,
    IconTypes
} from "src/app/components/shared/table-list/tables-components/icon-column/icon-column.component";
import { TagsService } from "../../configuration/tags/tags.service";
import { BroadcastersService } from "src/app/components/broadcasters/broadcasters.service";
import { ZxBroadcasterStatusColComponent } from "../cluster/cluster-broadcasters/broadcaster-status-col/broadcaster-status-col.component";
import { ZxBroadcasterStreamsColComponent } from "../cluster/cluster-broadcasters/broadcaster-streams-col/broadcaster-streams-col.component";
import { ZxNumericColComponent } from "src/app/components/shared/zx-numeric-col/zx-numeric-col.component";
import { LocationDisplayNamePipe } from "src/app/pipes/location-display-name.pipe";
import { NetworkPipe } from "src/app/pipes/network.pipe";
import { assignComponentsClusterAdapter } from "src/app/components/shared/zx-cluster/zx-cluster.table-adapter";
import { ZxClusterComponent } from "src/app/components/shared/zx-cluster/zx-cluster.component";
import { TourSteps } from "src/app/constants/tour-steps";
import { TourService } from "ngx-ui-tour-md-menu";
import { ZxBroadcasterNameColComponent } from "../cluster/cluster-broadcasters/broadcaster-name-col/broadcaster-name-col.component";
import { ZxBroadcasterIPColComponent } from "../cluster/cluster-broadcasters/broadcaster-ip-col/broadcaster-ip-col.component";
import { VersionPipe } from "src/app/pipes/version.pipe";
import { ZxBroadcasterActionsColComponent } from "../cluster/cluster-broadcasters/broadcaster-actions-col/broadcaster-actions-col.component";

@Component({
    selector: "app-cluster-list",
    templateUrl: "./cluster-list.component.html"
})
export class ClusterListComponent implements OnInit, OnDestroy {
    @ViewChildren(NgbSortableHeader) headers: QueryList<NgbSortableHeader>;
    @ViewChild(RouterOutlet) child: RouterOutlet;
    @ViewChild("leftContainer", { static: true }) leftContainer: ElementRef;

    get Math() {
        return Math;
    }

    loading = true;
    loadingBroadcasters = true;
    refreshing = false;

    clusters: Cluster[];
    broadcasters: Broadcaster[];

    clusterId: number = null;
    broadcasterId: number = null;
    broadcasterName: string;

    selectedCluster: Cluster;
    selectedBroadcaster: Broadcaster;
    private rawClustersBS$ = new BehaviorSubject<Cluster[]>([]);
    private rawBroadcastersBS$ = new BehaviorSubject<Broadcaster[]>([]);

    private tourSteps = TourSteps.bxInsights;

    selectedRows: Array<Cluster> = [];
    expandedRows: Array<Cluster> = [];
    selectedBroadcasterRows: Array<Broadcaster> = [];
    selectedClusterBroadcasterRows: Array<Broadcaster> = [];

    isAdmin: boolean;
    isZixi: boolean;
    userPermissions: UserPermissions;

    resourceTags: Tag[];
    urls = Constants.urls;
    isResizing: boolean;
    currentSortDirection: string;
    currentBroadcasterSortDirection: string;
    viewOption = "clusters";
    splitterPosition = null;

    get tableColumnsSchema(): TableSchema[] {
        return this._tableColumnsSchema;
    }
    set tableColumnsSchema(newValue: TableSchema[]) {
        this._tableColumnsSchema = newValue;
    }

    get tableBroadcastersColumnsSchema(): TableSchema[] {
        return this._tableBroadcastersColumnsSchema;
    }
    set tableBroadcastersColumnsSchema(newValue: TableSchema[]) {
        this._tableBroadcastersColumnsSchema = newValue;
    }

    get tableClusterBroadcastersColumnsSchema(): TableSchema[] {
        return this._tableClusterBroadcastersColumnsSchema;
    }
    set tableClusterBroadcastersColumnsSchema(newValue: TableSchema[]) {
        this._tableClusterBroadcastersColumnsSchema = newValue;
    }

    _tableBroadcastersColumnsSchema: TableSchema[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            visible: true,
            width: 160,
            sticky: 1,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row => row.name,
                row => row.name,
                () => true
            ),
            sortBy: (row: KeyMap<Broadcaster>) => row.name,
            textValue: (row: KeyMap<Broadcaster>) => row.name,
            valueToExport: (row: KeyMap<Broadcaster>) => row.name,
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("STATUS"),
            columnDef: "status",
            width: 120,
            visible: true,
            component: ZxBroadcasterStatusColComponent,
            assignComponentsInputs: (
                broadcasterComponentRef: ComponentRef<ZxBroadcasterStatusColComponent>,
                row: KeyMap<Broadcaster>
            ) => {
                const broadcasterCompRef = broadcasterComponentRef.instance;
                const props = {
                    broadcaster: row
                };
                for (const key in props) {
                    const value = props[key];
                    broadcasterCompRef[key] = value;
                }
            },
            sortBy: (row: KeyMap<Broadcaster>) =>
                this.currentBroadcasterSortDirection === "asc"
                    ? row._sortData.sortableStatusAsc
                    : row._sortData.sortableStatusDesc,
            textValue: (row: KeyMap<Broadcaster>) => this.translate.instant(this.stp.transform(row)),
            valueToExport: (row: KeyMap<Broadcaster>) => this.translate.instant(this.stp.transform(row)),
            columnFilterType: ColumnFilterType.SELECT,
            columnFilterValue: (row: KeyMap<Broadcaster>) => this.translate.instant(this.stp.simpleTransform(row)),
            columnSelectOptions: ["Ok", "Warning", "Error", "Other"]
        },
        {
            header: this.translate.instant("CLUSTER"),
            columnDef: "cluster",
            width: 120,
            visible: true,
            component: ZxClusterComponent,
            assignComponentsInputs: assignComponentsClusterAdapter,
            textValue: (row: KeyMap<Broadcaster>) => row.broadcaster_cluster?.name ?? "-",
            sortBy: (row: KeyMap<Broadcaster>) => row.broadcaster_cluster?.name ?? "-",
            valueToExport: (row: KeyMap<Broadcaster>) => row.broadcaster_cluster?.name ?? "",
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("CPU"),
            columnDef: "cpu",
            width: 70,
            align: "right",
            visible: true,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Broadcaster>
            ) => {
                const compRef = bitrateComponentRef.instance;
                compRef.number = this.decimalPipe.transform(row.status?.cpu, "1.0-2") ?? "-";
                compRef.unit = "%";
            },
            sortBy: (row: KeyMap<Broadcaster>) => row.status?.cpu ?? "-",
            bgValue: (row: KeyMap<Broadcaster>) => row.status?.cpu ?? null,
            valueToExport: (row: KeyMap<Broadcaster>) => row.status?.cpu,
            columnFilterType: ColumnFilterType.NUMBER,
            columnFilterValue: (row: KeyMap<Broadcaster>) => row.status?.cpu ?? 0
        },
        {
            header: this.translate.instant("RAM"),
            columnDef: "ram",
            width: 70,
            align: "right",
            visible: true,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Broadcaster>
            ) => {
                const compRef = bitrateComponentRef.instance;
                compRef.number = this.decimalPipe.transform(row.status?.ram, "1.0-2") ?? "-";
                compRef.unit = "%";
            },
            sortBy: (row: KeyMap<Broadcaster>) => row.status?.ram ?? "-",
            bgValue: (row: KeyMap<Broadcaster>) => row.status?.ram ?? null,
            valueToExport: (row: KeyMap<Broadcaster>) => row.status?.ram,
            columnFilterType: ColumnFilterType.NUMBER,
            columnFilterValue: (row: KeyMap<Broadcaster>) => row.status?.ram ?? 0
        },
        {
            header: this.translate.instant("HDD"),
            columnDef: "hdd",
            width: 70,
            align: "right",
            visible: true,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Broadcaster>
            ) => {
                const compRef = bitrateComponentRef.instance;
                compRef.number =
                    this.decimalPipe.transform(
                        Math.max(row.status?.disk_space, row.status?.disk_space_install),
                        "1.0-2"
                    ) ?? "-";
                compRef.unit = "%";
            },
            sortBy: (row: KeyMap<Broadcaster>) =>
                row.status?.disk_space &&
                row.status?.disk_space_install &&
                Math.max(row.status?.disk_space, row.status?.disk_space_install)
                    ? Math.max(row.status?.disk_space, row.status?.disk_space_install)
                    : "",
            bgValue: (row: KeyMap<Broadcaster>) =>
                Math.max(row.status?.disk_space, row.status?.disk_space_install) ?? null,
            valueToExport: (row: KeyMap<Broadcaster>) =>
                this.getValueToExportForDecimalPipe(Math.max(row.status?.disk_space, row.status?.disk_space_install)),
            columnFilterType: ColumnFilterType.NUMBER,
            columnFilterValue: (row: KeyMap<Broadcaster>) =>
                Math.max(row.status?.disk_space, row.status?.disk_space_install) ?? 0
        },
        {
            header: this.translate.instant("GPU"),
            columnDef: "gpu",
            width: 70,
            align: "right",
            visible: false,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Broadcaster>
            ) => {
                const compRef = bitrateComponentRef.instance;
                compRef.number = this.decimalPipe.transform(row.recentLoad?.nvidia_utilization, "1.0-2") ?? "-";
                compRef.unit = "%";
            },
            sortBy: (row: KeyMap<Broadcaster>) => row.recentLoad?.nvidia_utilization ?? "-",
            bgValue: (row: KeyMap<Broadcaster>) => row.recentLoad?.nvidia_utilization ?? null,
            valueToExport: (row: KeyMap<Broadcaster>) => row.recentLoad?.nvidia_utilization,
            columnFilterType: ColumnFilterType.NUMBER,
            columnFilterValue: (row: KeyMap<Broadcaster>) => row.recentLoad?.nvidia_utilization ?? 0
        },
        {
            header: this.translate.instant("GPU_MEM"),
            columnDef: "gpu_mem",
            width: 80,
            align: "right",
            visible: false,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Broadcaster>
            ) => {
                const compRef = bitrateComponentRef.instance;
                compRef.number = this.decimalPipe.transform(row.recentLoad?.nvidia_mem_utilization, "1.0-2") ?? "-";
                compRef.unit = "%";
            },
            sortBy: (row: KeyMap<Broadcaster>) => row.recentLoad?.nvidia_mem_utilization ?? "-",
            bgValue: (row: KeyMap<Broadcaster>) => row.recentLoad?.nvidia_mem_utilization ?? null,
            valueToExport: (row: KeyMap<Broadcaster>) => row.recentLoad?.nvidia_mem_utilization,
            columnFilterType: ColumnFilterType.NUMBER,
            columnFilterValue: (row: KeyMap<Broadcaster>) => row.recentLoad?.nvidia_mem_utilization ?? 0
        },
        {
            header: this.translate.instant("GPU_ENC"),
            columnDef: "gpu_enc",
            width: 80,
            align: "right",
            visible: false,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Broadcaster>
            ) => {
                const compRef = bitrateComponentRef.instance;
                compRef.number = this.decimalPipe.transform(row.recentLoad?.nvidia_encoder_utilization, "1.0-2") ?? "-";
                compRef.unit = "%";
            },
            sortBy: (row: KeyMap<Broadcaster>) => row.recentLoad?.nvidia_encoder_utilization ?? "-",
            bgValue: (row: KeyMap<Broadcaster>) => row.recentLoad?.nvidia_encoder_utilization ?? null,
            valueToExport: (row: KeyMap<Broadcaster>) => row.recentLoad?.nvidia_encoder_utilization,
            columnFilterType: ColumnFilterType.NUMBER,
            columnFilterValue: (row: KeyMap<Broadcaster>) => row.recentLoad?.nvidia_encoder_utilization ?? 0
        },
        {
            header: this.translate.instant("GPU_DEC"),
            columnDef: "gpu_dec",
            width: 80,
            align: "right",
            visible: false,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Broadcaster>
            ) => {
                const compRef = bitrateComponentRef.instance;
                compRef.number = this.decimalPipe.transform(row.recentLoad?.nvidia_decoder_utilization, "1.0-2") ?? "-";
                compRef.unit = "%";
            },
            sortBy: (row: KeyMap<Broadcaster>) => row.recentLoad?.nvidia_decoder_utilization ?? "-",
            bgValue: (row: KeyMap<Broadcaster>) => row.recentLoad?.nvidia_decoder_utilization ?? null,
            valueToExport: (row: KeyMap<Broadcaster>) => row.recentLoad?.nvidia_decoder_utilization,
            columnFilterType: ColumnFilterType.NUMBER,
            columnFilterValue: (row: KeyMap<Broadcaster>) => row.recentLoad?.nvidia_decoder_utilization ?? 0
        },
        {
            header: this.translate.instant("IN_BITRATE"),
            columnDef: "in_bitrate",
            width: 100,
            align: "right",
            visible: true,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Broadcaster>,
                searchTerm: string[]
            ) => {
                const bitrateCompRef = bitrateComponentRef.instance;
                const props = {
                    number: this.decimalPipe.transform(row.status?.input_kbps, "1.0-0")
                        ? this.decimalPipe.transform(row.status?.input_kbps, "1.0-0")
                        : 0,
                    unit: "kbps",
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    bitrateCompRef[key] = value;
                }
            },
            textValue: (row: KeyMap<Broadcaster>) => {
                const title = this.decimalPipe.transform(row.status?.input_kbps, "1.0-0");
                return title ? `${title}` : "0";
            },
            sortBy: (row: KeyMap<Broadcaster>) => row.status?.input_kbps,
            valueToExport: (row: KeyMap<Broadcaster>) => row.status?.input_kbps,
            columnFilterType: ColumnFilterType.NUMBER,
            columnFilterValue: (row: KeyMap<Broadcaster>) => row.status?.input_kbps
        },
        {
            header: this.translate.instant("OUT_BITRATE"),
            columnDef: "out_bitrate",
            width: 100,
            align: "right",
            visible: true,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Broadcaster>,
                searchTerm: string[]
            ) => {
                const bitrateCompRef = bitrateComponentRef.instance;
                const props = {
                    number: this.decimalPipe.transform(row.status?.output_kbps, "1.0-0")
                        ? this.decimalPipe.transform(row.status?.output_kbps, "1.0-0")
                        : 0,
                    unit: "kbps",
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    bitrateCompRef[key] = value;
                }
            },
            textValue: (row: KeyMap<Broadcaster>) => {
                const title = this.decimalPipe.transform(row.status?.output_kbps, "1.0-0");
                return title ? `${title}` : "0";
            },
            sortBy: (row: KeyMap<Broadcaster>) => row.status?.output_kbps,
            valueToExport: (row: KeyMap<Broadcaster>) => row.status?.output_kbps,
            columnFilterType: ColumnFilterType.NUMBER,
            columnFilterValue: (row: KeyMap<Broadcaster>) => row.status?.output_kbps
        },
        {
            header: this.translate.instant("STREAMS"),
            columnDef: "streams",
            width: 120,
            visible: true,
            sortBy: (row: KeyMap<Broadcaster>) => row._frontData?.streams,
            component: ZxBroadcasterStreamsColComponent,
            assignComponentsInputs: (
                broadcasterComponentRef: ComponentRef<ZxBroadcasterStreamsColComponent>,
                row: KeyMap<Broadcaster>
            ) => {
                const broadcasterCompRef = broadcasterComponentRef.instance;
                const props = {
                    broadcaster: row
                };
                for (const key in props) {
                    const value = props[key];
                    broadcasterCompRef[key] = value;
                }
            }
        },
        {
            header: this.translate.instant("UPTIME"),
            columnDef: "uptime",
            width: 100,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                (row: KeyMap<Broadcaster>) =>
                    row.status?.up_time_seconds ? this.uptimePipe.transform(row.status?.up_time_seconds) : "-",
                (row: KeyMap<Broadcaster>) =>
                    row.status?.up_time_seconds ? this.uptimePipe.transform(row.status?.up_time_seconds) : "-",
                (row: KeyMap<Broadcaster>) => !!row?.status?.up_time_seconds
            ),
            sortBy: (row: KeyMap<Broadcaster>) => row.status?.up_time_seconds ?? "-",
            textValue: (row: KeyMap<Broadcaster>) => row.status?.up_time_seconds ?? "-",
            valueToExport: (row: KeyMap<Broadcaster>) => row.status?.up_time_seconds
            // TODO: add advanced filter?
        },
        {
            header: this.translate.instant("PRIMARY"),
            columnDef: "primary",
            width: 80,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                (row: KeyMap<Broadcaster>) => (row.is_backup ? "No" : "Yes"),
                (row: KeyMap<Broadcaster>) => (row.is_backup ? "No" : "Yes"),
                () => true
            ),
            sortBy: (row: KeyMap<Broadcaster>) => (row.is_backup ? "No" : "Yes"),
            textValue: (row: KeyMap<Broadcaster>) => (row.is_backup ? "No" : "Yes"),
            valueToExport: (row: KeyMap<Broadcaster>) => (row.is_backup ? "No" : "Yes"),
            columnFilterType: ColumnFilterType.SELECT,
            columnSelectOptions: ["Yes", "No"],
            columnFilterValue: (row: KeyMap<Broadcaster>) => (row.is_backup ? "No" : "Yes")
        },
        {
            header: this.translate.instant("PUBLIC_IP"),
            columnDef: "public_ip",
            width: 120,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                (row: KeyMap<Broadcaster>) => row.status?.ip?.toString(),
                (row: KeyMap<Broadcaster>) => row.status?.ip?.toString(),
                (row: KeyMap<Broadcaster>) => !!row.status?.ip
            ),
            textValue: (row: KeyMap<Broadcaster>) => row.status?.ip ?? "-",
            sortBy: (row: KeyMap<Broadcaster>) => row.status?.ip ?? "-",
            valueToExport: (row: KeyMap<Broadcaster>) => row.status?.ip,
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("PRIVATE_IP"),
            columnDef: "private_ip",
            width: 120,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                (row: KeyMap<Broadcaster>) =>
                    row?.status?.private_ip && row.status.private_ip !== row.status.ip
                        ? row.status?.private_ip?.toString()
                        : "-",
                (row: KeyMap<Broadcaster>) =>
                    row?.status?.private_ip && row.status.private_ip !== row.status.ip
                        ? row.status?.private_ip?.toString()
                        : "-",
                (row: KeyMap<Broadcaster>) => !!row.status?.private_ip
            ),
            textValue: (row: KeyMap<Broadcaster>) =>
                row?.status?.private_ip && row.status.private_ip !== row.status.ip
                    ? row.status?.private_ip?.toString()
                    : "-",
            sortBy: (row: KeyMap<Broadcaster>) =>
                row?.status?.private_ip && row.status.private_ip !== row.status.ip
                    ? row.status?.private_ip?.toString()
                    : "-",
            valueToExport: (row: KeyMap<Broadcaster>) => row.status?.private_ip,
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("VERSION"),
            columnDef: "version",
            width: 100,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row =>
                    this.versionPipe.transform(row.status?.about?.version || "-") +
                    (row.can_transcode ? " Transcoder" : ""),
                row =>
                    this.versionPipe.transform(row.status?.about?.version || "-") +
                    (row.can_transcode ? " Transcoder" : ""),
                row => !!row?.status?.about?.version
            ),
            sortBy: (row: KeyMap<Broadcaster>) => this.versionPipe.transform(row.status?.about?.version || "-"),
            textValue: (row: KeyMap<Broadcaster>) =>
                this.versionPipe.transform(row.status?.about?.version || "-") +
                (row.can_transcode ? " Transcoder" : ""),
            valueToExport: (row: KeyMap<Broadcaster>) =>
                this.versionPipe.transform(row.status?.about?.version || "-") +
                (row.can_transcode ? " Transcoder" : ""),
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("AGENTZ"),
            columnDef: "agentz",
            width: 120,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row => this.agentZStatus(row),
                row => this.agentZStatus(row),
                () => true
            ),
            sortBy: (row: KeyMap<Broadcaster>) => this.agentZStatus(row),
            textValue: (row: KeyMap<Broadcaster>) => this.agentZStatus(row),
            valueToExport: (row: KeyMap<Broadcaster>) => this.agentZStatus(row),
            columnFilterType: ColumnFilterType.SELECT,
            columnFilterValue: (row: KeyMap<Broadcaster>) => this.agentZStatus(row),
            columnSelectOptions: ["Working", "Not Reporting", "Not Installed"]
        },
        {
            header: this.translate.instant("HOST_ID"),
            columnDef: "host_id",
            width: 120,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row => row.status?.hostid ?? "-",
                row => row.status?.hostid ?? "-",
                () => true
            ),
            sortBy: (row: KeyMap<Broadcaster>) => row.status?.hostid ?? "-",
            textValue: (row: KeyMap<Broadcaster>) => row.status?.hostid ?? "-",
            valueToExport: (row: KeyMap<Broadcaster>) => row.status?.hostid,
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("INSTANCE_TYPE"),
            columnDef: "instance_type",
            width: 120,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row => row.instance_type,
                row => (row.instance_type ? row.instance_type.toString() : "-"),
                () => true
            ),
            sortBy: (row: KeyMap<Broadcaster>) => row.instance_type,
            textValue: (row: KeyMap<Broadcaster>) => row.instance_type ?? "-",
            valueToExport: (row: KeyMap<Broadcaster>) => row.instance_type,
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("MANAGEMENT"),
            columnDef: "management",
            width: 120,
            visible: false,
            sortBy: (row: KeyMap<Broadcaster>) => row._frontData.scaling,
            textValue: (row: KeyMap<Broadcaster>) => this.translate.instant(row._frontData.scaling),
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row => row._frontData.scaling,
                row => this.translate.instant(row._frontData.scaling),
                () => true
            ),
            valueToExport: (row: KeyMap<Broadcaster>) => this.translate.instant(row._frontData.scaling),
            columnFilterType: ColumnFilterType.SELECT,
            columnSelectOptions: ["AWS", "Azure", "GCP", "Manual"]
        },
        {
            header: this.translate.instant("LOCATION"),
            columnDef: "location",
            width: 100,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row => this.locationPipe.transform(row.location),
                row => this.locationPipe.transform(row.location),
                () => true
            ),
            sortBy: (row: KeyMap<Broadcaster>) => this.locationPipe.transform(row.location) ?? "-",
            textValue: (row: KeyMap<Broadcaster>) => this.locationPipe.transform(row.location) ?? "-",
            valueToExport: (row: KeyMap<Broadcaster>) => this.locationPipe.transform(row.location),
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("NETWORK"),
            columnDef: "network",
            width: 100,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row => this.networkPipe.transform(row.location),
                row => this.networkPipe.transform(row.location),
                () => true
            ),
            sortBy: (row: KeyMap<Broadcaster>) => this.networkPipe.transform(row.location) ?? "-",
            textValue: (row: KeyMap<Broadcaster>) => this.networkPipe.transform(row.location) ?? "-",
            valueToExport: (row: KeyMap<Broadcaster>) => this.networkPipe.transform(row.location),
            columnFilterType: ColumnFilterType.STRING
        }
    ];

    _tableColumnsSchema: TableSchema[] = [
        {
            columnDef: "expand",
            header: "Expand",
            visible: true,
            sticky: 1,
            width: 32,
            expandDisabled: (row: KeyMap<Cluster>) => (row?.broadcasters?.length ? false : true),
            component: TableListComponent,
            assignComponentsInputs: (
                compRef: ComponentRef<TableListComponent>,
                row: KeyMap<Cluster>,
                searchTerm: string[]
            ) => {
                const ref = compRef.instance;
                ref.rowSelected.subscribe((b: Broadcaster) => {
                    this.selectClusterBroadcasterRow(b);
                });
                const props = {
                    tableName: "cluster-broadcasters-update",
                    displayTableName: this.translate.instant("CLUSTER_BROADCASTERS"),
                    childTable: true,
                    data: row.broadcasters,
                    tableSchema: this.tableClusterBroadcastersColumnsSchema,
                    forceCollapseWithSelection:
                        this.showInsights || this.clusterId || this.broadcasterId ? true : false,
                    collapsed: this.clusterId ? true : false,
                    selectedRows: this.selectedClusterBroadcasterRows,
                    selectable: true,
                    isColumnSelectionNeeded: true,
                    showFilter: false,
                    showReport: false,
                    showPagination: false,
                    showSelectionCheckbox: false,
                    showColumnsSelection: true,
                    showExpandButton: false,
                    id: row.id,
                    searchTerm: searchTerm,
                    hiddenSearchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    ref[key] = value;
                }
            }
        },
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            visible: true,
            sticky: 2,
            width: 160,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Cluster>>(
                row => row.name,
                row => row.name,
                () => true
            ),
            sortBy: (row: KeyMap<Cluster>) => row.name,
            textValue: (row: KeyMap<Cluster>) => row.name,
            valueToExport: (row: KeyMap<Cluster>) => row.name,
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("STATUS"),
            columnDef: "status",
            width: 160,
            visible: true,
            component: ZxStatusFullComponent,
            assignComponentsInputs: assignComponentsStatusInputsFactory({ showOtherIcons: true }),
            textValue: (row: KeyMap<Cluster>) => this.translate.instant(this.stp.transform(row)),
            sortBy: (row: KeyMap<Cluster>) =>
                this.currentSortDirection === "asc"
                    ? row._sortData.sortableStatusAsc
                    : row._sortData.sortableStatusDesc,
            valueToExport: (row: KeyMap<Cluster>) => this.translate.instant(this.stp.transform(row)),
            columnFilterType: ColumnFilterType.SELECT,
            columnFilterValue: (row: KeyMap<Cluster>) => this.translate.instant(this.stp.simpleTransform(row)),
            columnSelectOptions: ["Ok", "Warning", "Error", "Other"]
        },
        {
            header: this.translate.instant("BROADCASTERS"),
            columnDef: "broadcasters",
            width: 120,
            visible: true,
            component: ZxClusterBroadcastersColComponent,
            assignComponentsInputs: (
                compRef: ComponentRef<ZxClusterBroadcastersColComponent>,
                row: KeyMap<Cluster>
            ) => {
                const ref = compRef.instance;
                const props = {
                    cluster: row
                };
                for (const key in props) {
                    const value = props[key];
                    ref[key] = value;
                }
            },
            textValue: (row: KeyMap<Cluster>) => row?.broadcasters.length ?? "-",
            sortBy: (row: KeyMap<Cluster>) => row?.broadcasters.length,
            valueToExport: (row: KeyMap<Cluster | Broadcaster>) =>
                this.isRowCluster(row) ? this.exportDataForBroadcastersColumn(row) : ""
        },
        {
            header: this.translate.instant("MANAGEMENT"),
            columnDef: "management",
            width: 120,
            visible: true,
            sortBy: (row: KeyMap<Cluster>) => row._frontData.scaling,
            textValue: (row: KeyMap<Cluster>) => this.translate.instant(row._frontData.scaling),
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Cluster>>(
                row => row._frontData.scaling,
                row => this.translate.instant(row._frontData.scaling),
                () => true
            ),
            valueToExport: (row: KeyMap<Cluster | Broadcaster>) =>
                this.isRowCluster(row) ? this.translate.instant(row._frontData.scaling) : "",
            columnFilterType: ColumnFilterType.SELECT,
            columnSelectOptions: ["AWS", "Azure", "GCP", "Manual"]
        },
        {
            header: this.translate.instant("INSTANCE_TYPE"),
            columnDef: "instance_type",
            width: 160,
            visible: true,
            sortBy: (row: KeyMap<Cluster>) => row.instance_type,
            textValue: (row: KeyMap<Cluster>) => row.instance_type,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Cluster>>(
                row => row.instance_type,
                row => (row.instance_type ? row.instance_type.toString() : "-"),
                () => true
            ),
            valueToExport: (row: KeyMap<Cluster | Broadcaster>) => row.instance_type,
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("REGION"),
            columnDef: "region",
            width: 80,
            visible: true,
            sortBy: (row: KeyMap<Cluster>) => (row.region ? row.region.toString() : "-"),
            textValue: (row: KeyMap<Cluster>) => (row.region ? row.region.toString() : "-"),
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Cluster>>(
                row => (row.region ? row.region.toString() : "-"),
                row => (row.region ? row.region.toString() : "-"),
                () => true
            ),
            valueToExport: (row: KeyMap<Cluster>) => row.region,
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("TAGS"),
            columnDef: "tags",
            width: 160,
            visible: false,
            component: ZxTagsListComponent,
            assignComponentsInputs: (
                sourceComponentRef: ComponentRef<ZxTagsListComponent>,
                row: KeyMap<Cluster>,
                searchTerm: string[]
            ) => {
                const sourceCompRef = sourceComponentRef.instance;
                const props = {
                    model: row,
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    sourceCompRef[key] = value;
                }
            },
            textValue: (row: KeyMap<Cluster>) => row.resourceTags.map(t => t.name).join(", "),
            sortBy: (row: KeyMap<Cluster>) => row.resourceTags.map(t => t.name).join(""),
            valueToExport: (row: KeyMap<Cluster>) => row.resourceTags?.map(t => t.name).join(", ") || ""
        },
        {
            header: this.translate.instant("PRIORITY"),
            columnDef: "priority",
            width: 80,
            visible: false,
            component: IconColumnComponent,
            assignComponentsInputs: (componentRef: ComponentRef<IconColumnComponent>, row: KeyMap<Cluster>) => {
                const componentInstance = componentRef.instance;
                componentInstance.iconType = IconTypes.CHECK;
                componentInstance.showIcon = this.tagsService.isObjectVip(row.resourceTags);
            },
            sortBy: (row: Cluster) => (this.tagsService.isObjectVip(row.resourceTags) ? "priority" : ""),
            textValue: (row: Cluster) => (this.tagsService.isObjectVip(row.resourceTags) ? "priority" : ""),
            columnFilterType: ColumnFilterType.SELECT,
            columnSelectOptions: ["Yes", "No"],
            columnFilterValue: (row: KeyMap<Cluster>) => (this.tagsService.isObjectVip(row.resourceTags) ? "Yes" : "No")
        },
        {
            header: this.translate.instant("ERROR"),
            columnDef: "error",
            hideFromColumnChooser: true,
            width: 160,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Cluster>>(
                row => row.activeStates?.map(as => as.short_message)?.join(","),
                row => row.activeStates?.map(as => as.short_message)?.join(","),
                () => true
            ),
            valueToExport: (row: KeyMap<Cluster>) => row.activeStates?.map(as => as.short_message)?.join(",")
        },
        {
            header: this.translate.instant("EVENT"),
            hideFromColumnChooser: true,
            columnDef: "message",
            width: 160,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Cluster>>(
                row => row.activeStates?.map(as => as.message)?.join(","),
                row => row.activeStates?.map(as => as.message)?.join(","),
                () => true
            ),
            valueToExport: (row: KeyMap<Cluster>) => row.activeStates?.map(as => as.message)?.join(",")
        },
        {
            header: this.translate.instant("MUTED"),
            columnDef: "muted",
            width: 0,
            visible: false,
            textValue: (row: KeyMap<Cluster>) => (row.active_mute ? "muted" : ""),
            sortBy: (row: KeyMap<Cluster>) => (row.active_mute ? "muted" : ""),
            columnFilterType: ColumnFilterType.SELECT,
            columnSelectOptions: ["Yes", "No"],
            columnFilterValue: (row: KeyMap<Cluster>) => (row.active_mute ? "Yes" : "No")
        },
        {
            header: "",
            columnDef: "hidden_broadcasters",
            visible: false,
            hideFromColumnChooser: true,
            textValue: (row: KeyMap<Cluster>) => row.broadcasters?.map(b => b.name)?.join(","),
            sortBy: (row: KeyMap<Cluster>) => row.broadcasters?.map(b => b.name)?.join(",")
        },
        {
            header: this.translate.instant("BROADCASTER_IP"),
            columnDef: "hidden_broadcasters_ip",
            visible: false,
            hideFromColumnChooser: true,
            columnFilterType: ColumnFilterType.STRING_ARRAY,
            columnFilterValue: (row: KeyMap<Cluster>) => row.broadcasters?.map(b => b.status?.source_ip ?? ""),
            textValue: (row: KeyMap<Cluster>) => row.broadcasters?.map(b => b.status?.source_ip)?.join(",")
        },
        {
            header: this.translate.instant("BROADCASTER_HOST_ID"),
            columnDef: "hidden_broadcasters_hostid",
            visible: false,
            hideFromColumnChooser: true,
            columnFilterType: ColumnFilterType.STRING_ARRAY,
            columnFilterValue: (row: KeyMap<Cluster>) => row.broadcasters?.map(b => b.status?.hostid ?? ""),
            textValue: (row: KeyMap<Cluster>) => row.broadcasters?.map(b => b.status?.hostid)?.join(",")
        },
        {
            header: this.translate.instant("BROADCASTER_CPU"),
            columnDef: "hidden_broadcasters_cpu",
            visible: false,
            hideFromColumnChooser: true,
            columnFilterType: ColumnFilterType.NUMBER_ARRAY,
            columnFilterValue: (row: KeyMap<Cluster>) => row.broadcasters?.map(b => b.status?.cpu ?? 0)
        },
        {
            header: this.translate.instant("BROADCASTER_MEM"),
            columnDef: "hidden_broadcasters_ram",
            visible: false,
            hideFromColumnChooser: true,
            columnFilterType: ColumnFilterType.NUMBER_ARRAY,
            columnFilterValue: (row: KeyMap<Cluster>) => row.broadcasters?.map(b => b.status?.ram ?? 0)
        },
        {
            header: this.translate.instant("BROADCASTER_HDD"),
            columnDef: "hidden_broadcasters_hdd",
            visible: false,
            hideFromColumnChooser: true,
            columnFilterType: ColumnFilterType.NUMBER_ARRAY,
            columnFilterValue: (row: KeyMap<Cluster>) =>
                row.broadcasters?.map(b => Math.max(b.status?.disk_space, b.status?.disk_space_install, 0))
        },
        {
            header: this.translate.instant("BROADCASTER_GPU"),
            columnDef: "hidden_broadcasters_gpu",
            visible: false,
            hideFromColumnChooser: true,
            columnFilterType: ColumnFilterType.NUMBER_ARRAY,
            columnFilterValue: (row: KeyMap<Cluster>) =>
                row.broadcasters?.map(b => b.recentLoad?.nvidia_utilization ?? 0)
        },
        {
            header: this.translate.instant("BROADCASTER_GPU_MEM"),
            columnDef: "hidden_broadcasters_gpu_mem",
            visible: false,
            hideFromColumnChooser: true,
            columnFilterType: ColumnFilterType.NUMBER_ARRAY,
            columnFilterValue: (row: KeyMap<Cluster>) =>
                row.broadcasters?.map(b => b.recentLoad?.nvidia_mem_utilization ?? 0)
        },
        {
            header: this.translate.instant("BROADCASTER_GPU_ENC"),
            columnDef: "hidden_broadcasters_gpu_enc",
            visible: false,
            hideFromColumnChooser: true,
            columnFilterType: ColumnFilterType.NUMBER_ARRAY,
            columnFilterValue: (row: KeyMap<Cluster>) =>
                row.broadcasters?.map(b => b.recentLoad?.nvidia_encoder_utilization ?? 0)
        },
        {
            header: this.translate.instant("BROADCASTER_GPU_DEC"),
            columnDef: "hidden_broadcasters_gpu_dec",
            visible: false,
            hideFromColumnChooser: true,
            columnFilterType: ColumnFilterType.NUMBER_ARRAY,
            columnFilterValue: (row: KeyMap<Cluster>) =>
                row.broadcasters?.map(b => b.recentLoad?.nvidia_decoder_utilization ?? 0)
        },
        {
            header: this.translate.instant("BROADCASTER_LOCATION"),
            columnDef: "hidden_broadcasters_location",
            visible: false,
            hideFromColumnChooser: true,
            columnFilterType: ColumnFilterType.STRING_ARRAY,
            textValue: (row: KeyMap<Cluster>) =>
                row.broadcasters?.map(b => this.locationPipe.transform(b.location))?.join(","),
            columnFilterValue: (row: KeyMap<Cluster>) =>
                row.broadcasters?.map(b => this.locationPipe.transform(b.location) ?? "")
        },
        {
            header: this.translate.instant("BROADCASTER_NETWORK"),
            columnDef: "hidden_broadcasters_network",
            visible: false,
            hideFromColumnChooser: true,
            columnFilterType: ColumnFilterType.STRING_ARRAY,
            textValue: (row: KeyMap<Cluster>) =>
                row.broadcasters?.map(b => this.networkPipe.transform(b.location))?.join(","),
            columnFilterValue: (row: KeyMap<Cluster>) =>
                row.broadcasters?.map(b => this.networkPipe.transform(b.location) ?? "")
        },
        //  Those next column shouldn't be visible from the table they only exist for report function
        {
            header: this.translate.instant("PUBLIC_IP"),
            columnDef: "hidden_public_ip",
            visible: false,
            hideFromColumnChooser: true,
            valueToExport: (row: KeyMap<Cluster | Broadcaster>) =>
                !this.isRowCluster(row) && row.status?.ip ? row.status.ip.toString() : ""
        },
        {
            header: this.translate.instant("PRIVATE_IP"),
            columnDef: "hidden_private_ip",
            visible: false,
            hideFromColumnChooser: true,
            valueToExport: (row: KeyMap<Cluster | Broadcaster>) =>
                !this.isRowCluster(row) && row?.status?.private_ip && row.status.private_ip !== row.status.ip
                    ? row.status?.private_ip?.toString()
                    : ""
        },
        {
            header: this.translate.instant("STREAMS"),
            columnDef: "hidden_streams",
            visible: false,
            hideFromColumnChooser: true,
            valueToExport: (row: KeyMap<Cluster | Broadcaster>) =>
                this.isRowCluster(row) ? "" : this.getValueToExportStreamsColumn(row)
        },
        {
            header: this.translate.instant("UPTIME"),
            columnDef: "hidden_uptime",
            visible: false,
            hideFromColumnChooser: true,
            valueToExport: (row: KeyMap<Cluster | Broadcaster>) =>
                this.isRowCluster(row) || !row.status?.up_time_seconds
                    ? ""
                    : this.uptimePipe.transform(row.status?.up_time_seconds)
        },
        {
            header: this.translate.instant("CPU"),
            columnDef: "hidden_cpu",
            visible: false,
            hideFromColumnChooser: true,
            valueToExport: (row: KeyMap<Cluster | Broadcaster>) =>
                this.isRowCluster(row) ? "" : this.getValueToExportForDecimalPipe(row.status?.cpu)
        },
        {
            header: this.translate.instant("RAM"),
            columnDef: "hidden_ram",
            visible: false,
            hideFromColumnChooser: true,
            valueToExport: (row: KeyMap<Cluster | Broadcaster>) =>
                this.isRowCluster(row) ? "" : this.getValueToExportForDecimalPipe(row.status?.ram)
        },
        {
            header: this.translate.instant("HDD"),
            columnDef: "hidden_hdd",
            visible: false,
            hideFromColumnChooser: true,
            valueToExport: (row: KeyMap<Cluster | Broadcaster>) =>
                this.isRowCluster(row)
                    ? ""
                    : this.getValueToExportForDecimalPipe(
                          Math.max(row.status?.disk_space, row.status?.disk_space_install)
                      )
        },
        {
            header: this.translate.instant("GPU"),
            columnDef: "hidden_gpu",
            visible: false,
            hideFromColumnChooser: true,
            valueToExport: (row: KeyMap<Cluster | Broadcaster>) =>
                this.isRowCluster(row) ? "" : this.getValueToExportForDecimalPipe(row.recentLoad?.nvidia_utilization)
        },
        {
            header: this.translate.instant("GPU_MEM"),
            columnDef: "hidden_gpu_mem",
            visible: false,
            hideFromColumnChooser: true,
            valueToExport: (row: KeyMap<Cluster | Broadcaster>) =>
                this.isRowCluster(row)
                    ? ""
                    : this.getValueToExportForDecimalPipe(row.recentLoad?.nvidia_mem_utilization)
        },
        {
            header: this.translate.instant("GPU_DEC"),
            columnDef: "hidden_gpu_dec",
            visible: false,
            hideFromColumnChooser: true,
            valueToExport: (row: KeyMap<Cluster | Broadcaster>) =>
                this.isRowCluster(row)
                    ? ""
                    : this.getValueToExportForDecimalPipe(row.recentLoad?.nvidia_decoder_utilization)
        },
        {
            header: this.translate.instant("GPU_ENC"),
            columnDef: "hidden_gpu_enc",
            visible: false,
            hideFromColumnChooser: true,
            valueToExport: (row: KeyMap<Cluster | Broadcaster>) =>
                this.isRowCluster(row)
                    ? ""
                    : this.getValueToExportForDecimalPipe(row.recentLoad?.nvidia_decoder_utilization)
        },
        {
            header: this.translate.instant("VERSION"),
            columnDef: "hidden_version",
            visible: false,
            hideFromColumnChooser: true,
            valueToExport: (row: KeyMap<Cluster | Broadcaster>) =>
                this.isRowCluster(row) || !row.status?.about?.version
                    ? ""
                    : row.status.about.version + (row.can_transcode ? " Transcoder" : ""),
            textValue: (row: KeyMap<Cluster>) =>
                row.broadcasters?.map(b => b.status?.about?.version + (b.can_transcode ? " Transcoder" : ""))?.join(",")
        }
    ];

    _tableClusterBroadcastersColumnsSchema: TableSchema<KeyMap<Broadcaster>>[] = [
        {
            header: this.translate.instant("BROADCASTER"),
            columnDef: "name",
            width: 160,
            sticky: 1,
            visible: true,
            sortBy: (row: KeyMap<Broadcaster>) => row.name,
            component: ZxBroadcasterNameColComponent,
            assignComponentsInputs: (
                broadcasterComponentRef: ComponentRef<ZxBroadcasterNameColComponent>,
                row: KeyMap<Broadcaster>,
                searchTerm: string | string[]
            ) => {
                const broadcasterCompRef = broadcasterComponentRef.instance;
                const props = {
                    broadcaster: row,
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    broadcasterCompRef[key] = value;
                }
            }
        },
        {
            header: this.translate.instant("STATUS"),
            columnDef: "status",
            width: 120,
            visible: true,
            component: ZxBroadcasterStatusColComponent,
            assignComponentsInputs: (
                broadcasterComponentRef: ComponentRef<ZxBroadcasterStatusColComponent>,
                row: KeyMap<Broadcaster>,
                searchTerm: string | string[]
            ) => {
                const broadcasterCompRef = broadcasterComponentRef.instance;
                const props = {
                    broadcaster: row,
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    broadcasterCompRef[key] = value;
                }
            },
            sortBy: (row: KeyMap<Broadcaster>) => this.translate.instant(this.stp.transform(row))
        },
        {
            header: this.translate.instant("CPU"),
            columnDef: "cpu",
            width: 70,
            align: "right",
            visible: true,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Broadcaster>,
                searchTerm: string | string[]
            ) => {
                const compRef = bitrateComponentRef.instance;
                compRef.number = this.decimalPipe.transform(row.status?.cpu, "1.0-2") ?? "-";
                compRef.unit = "%";
                compRef.searchTerm = searchTerm;
            },
            sortBy: row => row.status?.cpu ?? "-",
            bgValue: row => row.status?.cpu ?? null
        },
        {
            header: this.translate.instant("RAM"),
            columnDef: "ram",
            width: 70,
            align: "right",
            visible: true,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Broadcaster>,
                searchTerm: string | string[]
            ) => {
                const compRef = bitrateComponentRef.instance;
                compRef.number = this.decimalPipe.transform(row.status?.ram, "1.0-2") ?? "-";
                compRef.unit = "%";
                compRef.searchTerm = searchTerm;
            },
            sortBy: row => row.status?.ram ?? "-",
            bgValue: row => row.status?.ram ?? null
        },
        {
            header: this.translate.instant("HDD"),
            columnDef: "hdd",
            width: 70,
            align: "right",
            visible: true,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Broadcaster>,
                searchTerm: string | string[]
            ) => {
                const compRef = bitrateComponentRef.instance;
                compRef.number =
                    this.decimalPipe.transform(
                        Math.max(row.status?.disk_space, row.status?.disk_space_install),
                        "1.0-2"
                    ) ?? "-";
                compRef.unit = "%";
                compRef.searchTerm = searchTerm;
            },
            sortBy: row => Math.max(row.status?.disk_space, row.status?.disk_space_install) ?? "-",
            bgValue: row => Math.max(row.status?.disk_space, row.status?.disk_space_install) ?? null
        },
        {
            header: this.translate.instant("IN_BITRATE"),
            columnDef: "in_bitrate",
            width: 100,
            align: "right",
            visible: true,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Broadcaster>,
                searchTerm: string[]
            ) => {
                const bitrateCompRef = bitrateComponentRef.instance;
                const props = {
                    number: this.decimalPipe.transform(row.status?.input_kbps, "1.0-0")
                        ? this.decimalPipe.transform(row.status?.input_kbps, "1.0-0")
                        : 0,
                    unit: "kbps",
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    bitrateCompRef[key] = value;
                }
            },
            textValue: (row: KeyMap<Broadcaster>) => {
                const title = this.decimalPipe.transform(row.status?.input_kbps, "1.0-0");
                return title ? `${title}` : "0";
            },
            sortBy: (row: KeyMap<Broadcaster>) => row.status?.input_kbps
        },
        {
            header: this.translate.instant("OUT_BITRATE"),
            columnDef: "out_bitrate",
            width: 100,
            align: "right",
            visible: true,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Broadcaster>,
                searchTerm: string[]
            ) => {
                const bitrateCompRef = bitrateComponentRef.instance;
                const props = {
                    number: this.decimalPipe.transform(row.status?.output_kbps, "1.0-0")
                        ? this.decimalPipe.transform(row.status?.output_kbps, "1.0-0")
                        : 0,
                    unit: "kbps",
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    bitrateCompRef[key] = value;
                }
            },
            textValue: (row: KeyMap<Broadcaster>) => {
                const title = this.decimalPipe.transform(row.status?.output_kbps, "1.0-0");
                return title ? `${title}` : "0";
            },
            sortBy: (row: KeyMap<Broadcaster>) => row.status?.output_kbps
        },
        {
            header: this.translate.instant("STREAMS"),
            columnDef: "streams",
            width: 140,
            visible: true,
            sortBy: (row: KeyMap<Broadcaster>) => row._frontData?.streams,
            component: ZxBroadcasterStreamsColComponent,
            assignComponentsInputs: (
                broadcasterComponentRef: ComponentRef<ZxBroadcasterStreamsColComponent>,
                row: KeyMap<Broadcaster>
            ) => {
                const broadcasterCompRef = broadcasterComponentRef.instance;
                const props = {
                    broadcaster: row
                };
                for (const key in props) {
                    const value = props[key];
                    broadcasterCompRef[key] = value;
                }
            }
        },
        {
            header: this.translate.instant("UPTIME"),
            columnDef: "uptime",
            width: 100,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row => (row.status?.up_time_seconds ? this.uptimePipe.transform(row.status?.up_time_seconds) : "-"),
                row => (row.status?.up_time_seconds ? this.uptimePipe.transform(row.status?.up_time_seconds) : "-"),
                row => !!row?.status?.up_time_seconds
            ),
            sortBy: row => row.status?.up_time_seconds
        },
        {
            header: this.translate.instant("PRIMARY"),
            columnDef: "primary",
            width: 80,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row => (row.is_backup ? "No" : "Yes"),
                row => (row.is_backup ? "No" : "Yes"),
                () => true
            ),
            sortBy: row => (row.is_backup ? "No" : "Yes")
        },
        {
            header: this.translate.instant("PUBLIC_IP"),
            columnDef: "public_ip",
            width: 140,
            visible: false,
            sortBy: (row: KeyMap<Broadcaster>) => row.status?.ip,
            component: ZxBroadcasterIPColComponent,
            assignComponentsInputs: (
                broadcasterComponentRef: ComponentRef<ZxBroadcasterIPColComponent>,
                row: KeyMap<Broadcaster>,
                searchTerm: string | string[]
            ) => {
                const broadcasterCompRef = broadcasterComponentRef.instance;
                const props = {
                    broadcaster: row,
                    ip: row.status?.ip?.toString() ?? "",
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    broadcasterCompRef[key] = value;
                }
            }
        },
        {
            header: this.translate.instant("PRIVATE_IP"),
            columnDef: "private_ip",
            width: 120,
            visible: false,
            sortBy: (row: KeyMap<Broadcaster>) => row.status?.private_ip,
            component: ZxBroadcasterIPColComponent,
            assignComponentsInputs: (
                broadcasterComponentRef: ComponentRef<ZxBroadcasterIPColComponent>,
                row: KeyMap<Broadcaster>,
                searchTerm: string | string[]
            ) => {
                const broadcasterCompRef = broadcasterComponentRef.instance;
                const props = {
                    broadcaster: row,
                    ip:
                        row?.status?.private_ip && row.status.private_ip !== row.status.ip
                            ? row.status?.private_ip?.toString()
                            : "",
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    broadcasterCompRef[key] = value;
                }
            }
        },
        {
            header: this.translate.instant("VERSION"),
            columnDef: "version",
            width: 100,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row =>
                    this.versionPipe.transform(row.status?.about?.version || "-") +
                    (row.can_transcode ? " Transcoder" : ""),
                row =>
                    this.versionPipe.transform(row.status?.about?.version || "-") +
                    (row.can_transcode ? " Transcoder" : ""),
                row => !!row?.status?.about?.version
            ),
            sortBy: row => this.versionPipe.transform(row.status?.about?.version || "-")
        },
        {
            header: this.translate.instant("AGENTZ"),
            columnDef: "agentz",
            width: 120,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row => this.agentZStatus(row),
                row => this.agentZStatus(row),
                () => true
            ),
            sortBy: row => this.agentZStatus(row)
        },
        {
            header: this.translate.instant("HOST_ID"),
            columnDef: "host_id",
            width: 120,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row => row.status?.hostid ?? "-",
                row => row.status?.hostid ?? "-",
                () => true
            ),
            sortBy: row => row.status?.hostid ?? "-"
        },
        {
            header: this.translate.instant("INSTANCE_TYPE"),
            columnDef: "instance_type",
            width: 120,
            visible: false,
            sortBy: (row: KeyMap<Broadcaster>) => row.instance_type,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row => row.instance_type,
                row => (row.instance_type ? row.instance_type?.toString() : "-"),
                () => true
            )
        },
        {
            header: this.translate.instant("LOCATION"),
            columnDef: "location",
            width: 100,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row => this.locationPipe.transform(row.location),
                row => this.locationPipe.transform(row.location),
                () => true
            ),
            sortBy: (row: KeyMap<Broadcaster>) => this.locationPipe.transform(row.location) ?? "-"
        },
        {
            header: this.translate.instant("NETWORK"),
            columnDef: "network",
            width: 100,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row => this.networkPipe.transform(row.location),
                row => this.networkPipe.transform(row.location),
                () => true
            ),
            sortBy: (row: KeyMap<Broadcaster>) => this.networkPipe.transform(row.location) ?? "-"
        },
        {
            header: "",
            columnDef: "actions",
            width: 50,
            visible: true,
            hideFromColumnChooser: true,
            align: "right",
            component: ZxBroadcasterActionsColComponent,
            assignComponentsInputs: (
                broadcasterComponentRef: ComponentRef<ZxBroadcasterActionsColComponent>,
                row: KeyMap<Broadcaster>
            ) => {
                const broadcasterCompRef = broadcasterComponentRef.instance;
                broadcasterCompRef.broadcaster = row;
                const relatedCluster = this.clusters.find(c => c.id === row.broadcaster_cluster_id);
                broadcasterCompRef.cluster = relatedCluster;
                broadcasterCompRef.resourceTags = this.resourceTags;
                broadcasterCompRef.userPermissions = this.userPermissions;
            }
        }
    ];

    iframeDisabled = true;
    model: unknown;
    @ViewChild("iframe", { static: false }) iframe: ElementRef;

    showInsights = false;

    private routeSubscription: Subscription;
    private clustersSubscription: Subscription;
    private broadcastersSubscription: Subscription;
    private refreshSubscription: Subscription;
    private splitterPositionSubscription: Subscription;

    constructor(
        private stp: StatusTextPipe,
        private router: Router,
        private cs: ClustersService,
        private bs: BroadcastersService,
        public sharedService: SharedService,
        private modalService: ModalService,
        private userService: UsersService,
        public mixpanelService: MixpanelService,
        public tourService: TourService,
        private translate: TranslateService,
        private titleService: TitleService,
        private uptimePipe: UptimePipe,
        private decimalPipe: DecimalPipe,
        private tagsService: TagsService,
        private locationPipe: LocationDisplayNamePipe,
        private networkPipe: NetworkPipe,
        private versionPipe: VersionPipe
    ) {
        // Set Title
        this.titleService.setTitle("BROADCASTER_CLUSTERS", "");
        //
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof ActivationEnd && event.snapshot.children.length === 0))
            .subscribe((event: ActivationEnd) => {
                if (event.snapshot.params && event.snapshot.params.clusterId) {
                    this.clusterId = urlBuilder.decode(event.snapshot.params.clusterId);
                    this.updateSelectedCluster();
                } else {
                    this.clusterId = null;
                    this.selectedRows = [];
                    this.selectedClusterBroadcasterRows = [];
                }

                if (event.snapshot.params && event.snapshot.params.broadcasterId) {
                    this.broadcasterId = urlBuilder.decode(event.snapshot.params.broadcasterId);
                    this.updateSelectedBroadcaster();
                    this.updateSelectedClusterBroadcaster();
                } else {
                    this.broadcasterId = null;
                    this.selectedBroadcasterRows = [];
                    this.selectedClusterBroadcasterRows = [];
                }

                if (event.snapshot.params && event.snapshot.params.name) {
                    this.broadcasterName = event.snapshot.params.name;
                } else this.broadcasterName = null;

                if (this.clusterId && !this.broadcasterId) this.viewOption = "clusters";
            });
    }

    private updateSelectedCluster() {
        if (!this.clusterId) return;
        const cluster = this.clusters?.find(c => c.id === this.clusterId);
        this.showInsights = false;
        this.selectedCluster = cluster;
        if (!this.broadcasterId) this.selectedClusterBroadcasterRows = [];
        if (cluster) this.selectedRows = [cluster];
        else this.selectedRows = [];
        this.prepTableData();
    }

    private updateSelectedBroadcaster() {
        if (!this.broadcasterId) return;
        const broadcaster = this.broadcasters?.find(c => c.id === this.broadcasterId);
        this.showInsights = false;
        this.selectedBroadcaster = broadcaster;
        if (broadcaster) this.selectedBroadcasterRows = [broadcaster];
    }

    private updateSelectedClusterBroadcaster() {
        if (!this.clusterId || !this.broadcasterId) return;
        const cluster = this.clusters?.find(c => c.id === this.clusterId);
        const broadcaster = cluster?.broadcasters?.find(b => b.id === this.broadcasterId);
        this.showInsights = false;
        if (broadcaster) this.selectedClusterBroadcasterRows = [broadcaster];
        else this.selectedClusterBroadcasterRows = [];
    }

    ngOnInit() {
        this.loading = true;

        // isAdmin
        this.userService.isAdmin.pipe(take(1)).subscribe(bool => {
            this.isAdmin = bool;
        });

        this.userService.userPermissions.pipe(take(1)).subscribe(perm => {
            this.userPermissions = perm;
        });

        this.userService
            .getCurrentUser()
            .pipe(take(1))
            .subscribe(user => {
                this.isZixi = !!(user.is_zixi_admin || user.is_zixi_support_write || user.is_zixi_support);
            });

        // resourceTags
        this.sharedService
            .getResourceTagsByType("broadcaster_cluster")
            .pipe(take(1))
            .subscribe((tags: Tag[]) => {
                this.resourceTags = tags;
            });

        // local storage
        if (localStorage.getItem("cluster-list-view")) {
            this.viewOption = localStorage.getItem("cluster-list-view");
            if (this.clusterId && !this.broadcasterId) this.viewOption = "clusters";
        }

        if (localStorage.getItem("splitter-width"))
            this.leftContainer.nativeElement.style.flexBasis = localStorage.getItem("splitter-width");

        if (localStorage.getItem("splitter-position"))
            this.sharedService.setSplitterPosition(parseInt(localStorage.getItem("splitter-position"), 10));

        // subs
        this.splitterPositionSubscription = this.sharedService.getSplitterPosition.subscribe(p => {
            this.splitterPosition = p;
        });

        this.clustersSubscription = this.cs.clusters.subscribe(clusters => {
            this.clusters = clusters;
            if (this.clusters) {
                this.updateSelectedCluster();
                this.updateSelectedClusterBroadcaster();
                this.prepTableData();
                this.loading = false;
            }
        });

        this.broadcastersSubscription = this.bs.broadcasters.subscribe(broadcasters => {
            this.broadcasters = broadcasters;
            if (this.broadcasters) {
                this.updateSelectedBroadcaster();
                this.prepBroadcasterTableData();
                this.loading = false;
                this.loadingBroadcasters = false;
            }
        });
        this.bs.refreshAllBroadcasters(false);

        // Start Auto Refresh
        this.startRefresh();

        setTimeout(() => {
            if (this.clusterId) {
                this.updateSelectedCluster();
            } else {
                this.selectedRows = [];
                this.selectedBroadcasterRows = [];
                this.selectedClusterBroadcasterRows = [];
            }

            if (this.broadcasterId) {
                this.updateSelectedBroadcaster();
                this.updateSelectedClusterBroadcaster();
            } else {
                this.selectedBroadcasterRows = [];
                this.selectedClusterBroadcasterRows = [];
            }
        });

        this.tourService.initialize(this.tourSteps);
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
        this.clustersSubscription?.unsubscribe();
        this.broadcastersSubscription.unsubscribe();
        this.splitterPositionSubscription.unsubscribe();
        this.stopRefresh();
    }

    resizing(isResizing: boolean) {
        this.isResizing = isResizing;
    }

    selectRow = (cluster: Cluster) => {
        this.selectedCluster = cluster;
        this.showInsights = false;
        this.selectedClusterBroadcasterRows = [];
        this.router.navigate(urlBuilder.getRegularClusterUrl(cluster.id, cluster.name));
    };

    selectBroadcasterRow = (broadcaster: Broadcaster) => {
        this.selectedBroadcaster = broadcaster;
        this.showInsights = false;
        this.router.navigate(
            urlBuilder.getRegularBroadcasterUrl(broadcaster.broadcaster_cluster_id, broadcaster.id, broadcaster.name)
        );
    };

    selectClusterBroadcasterRow = (broadcaster: Broadcaster) => {
        this.selectedClusterBroadcasterRows = [broadcaster];
        this.showInsights = false;
        this.router.navigate(
            urlBuilder.getRegularBroadcasterUrl(broadcaster.broadcaster_cluster_id, broadcaster.id, broadcaster.name)
        );
    };

    async refresh(force = false) {
        this.refreshing = true;
        //
        const clustersRefresh = this.viewOption === "clusters" ? firstValueFrom(this.cs.refreshClusters(force)) : null;
        const broadcastersRefresh =
            this.viewOption === "broadcasters" ? firstValueFrom(this.bs.refreshAllBroadcasters(force)) : null;
        const childComponentRefresh =
            this.child && this.child.component ? (this.child.component as ClusterComponent).refresh() : null;

        await Promise.all([clustersRefresh, broadcastersRefresh, childComponentRefresh]);
        //
        this.refreshing = false;
    }

    async multiAction(action: string, func: (cluster: Cluster) => Promise<unknown>) {
        const result = await this.modalService.confirmMultiple(action, "CLUSTER", this.selectedRows, func);
        if (!result.keepSelected && action !== "DELETE") this.selectedRows = [];
        if (result.actionTaken) {
            this.mixpanelService.sendEvent(
                this.translate.instant(action).toLowerCase() + " multiple broadcaster clusters"
            );
            if (action === "DELETE") this.selectedRows = [];
        }
    }

    async multiActionBroadcaster(action: string, func: (broadcaster: Broadcaster) => Promise<unknown>) {
        const result = await this.modalService.confirmMultiple(
            action,
            "BROADCASTER",
            this.selectedBroadcasterRows,
            func
        );
        if (!result.keepSelected && action !== "DELETE") this.selectedBroadcasterRows = [];
        if (result.actionTaken) {
            this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " multiple broadcaster");
            if (action === "DELETE") this.selectedBroadcasterRows = [];
        }
    }

    multiDelete() {
        this.multiAction("DELETE", async (cluster: Cluster) => this.cs.deleteCluster(cluster));
    }

    multiDeleteBroadcaster() {
        this.multiActionBroadcaster("DELETE", async (broadcaster: Broadcaster) =>
            this.bs.deleteBroadcaster(broadcaster)
        );
    }

    multiToggleMute(mute: boolean) {
        this.multiAction(mute ? "MUTE" : "UNMUTE", async (cluster: Cluster) =>
            this.cs.updateCluster(cluster, {
                muted: mute,
                muted_until: null,
                flapping: null
            })
        );
    }

    multiToggleMuteBroadcaster(mute: boolean) {
        this.multiActionBroadcaster(mute ? "MUTE" : "UNMUTE", async (broadcaster: Broadcaster) =>
            this.bs.updateBroadcaster(broadcaster, {
                muted: mute,
                muted_until: null,
                flapping: null
            })
        );
    }

    async multiEdit() {
        await this.modalService.editMultiple("CLUSTER", this.selectedRows, async (cluster: Cluster, model) => {
            return this.cs.updateCluster(cluster, model);
        });
    }

    async multiEditBroadcaster() {
        await this.modalService.editMultiple(
            "BROADCASTER",
            this.selectedBroadcasterRows,
            async (broadcaster: Broadcaster, model) => {
                return this.bs.updateBroadcaster(broadcaster, model);
            }
        );
    }

    startRefresh() {
        this.refreshSubscription = interval(60000).subscribe(() => {
            this.refresh();
        });
    }

    stopRefresh() {
        this.refreshSubscription.unsubscribe();
    }

    onSort(s: string) {
        this.currentSortDirection = s;
    }

    onSortBroadcaster(s: string) {
        this.currentBroadcasterSortDirection = s;
    }

    get allClusterObservable() {
        return this.rawClustersBS$;
    }

    private prepTableData() {
        if (this.clusters) {
            const clusters = [...this.clusters];
            this.rawClustersBS$.next(clusters);
        }
    }

    get allBroadcastersObservable() {
        return this.rawBroadcastersBS$;
    }

    private prepBroadcasterTableData() {
        if (this.broadcasters) {
            const broadcasters = [...this.broadcasters];
            this.rawBroadcastersBS$.next(broadcasters);
        }
    }

    toggleBroadcasterInsights() {
        this.toggleInsights();
        if (this.showInsights) {
            this.mixpanelService.sendEvent("view broadcaster insights", {
                ids: this.selectedBroadcasterRows.map(row => row.id)
            });
        }
    }

    toggleClusterInsights() {
        this.toggleInsights();
        if (this.showInsights) {
            this.mixpanelService.sendEvent("view cluster insights", { ids: this.selectedRows.map(row => row.id) });
        }
    }

    toggleInsights() {
        this.showInsights = !this.showInsights;
    }

    viewChange() {
        localStorage.setItem("cluster-list-view", this.viewOption);
        this.refresh(true);
    }

    private exportDataForBroadcastersColumn(row: KeyMap<Cluster>): string {
        let broadcasters = "";
        if (row.broadcastersSummary) {
            if (row.broadcastersSummary.good) broadcasters += "Ok(" + row.broadcastersSummary.good + ") ";
            if (row.broadcastersSummary.warning) broadcasters += "Warning(" + row.broadcastersSummary.warning + ") ";
            if (row.broadcastersSummary.bad) broadcasters += "Error(" + row.broadcastersSummary.bad + ") ";
            if (row.broadcastersSummary.acknowledged)
                broadcasters += "Acknowledged(" + row.broadcastersSummary.acknowledged + ") ";
            if (row.broadcastersSummary.disabled) broadcasters += "Disabled(" + row.broadcastersSummary.disabled + ") ";
            if (row.broadcastersSummary.pending) broadcasters += "Pending(" + row.broadcastersSummary.pending + ") ";
        }
        return broadcasters;
    }
    private isRowCluster(row: KeyMap<Cluster | Broadcaster>): row is Cluster {
        return !!(row as Cluster).broadcasters;
    }

    private getValueToExportStreamsColumn(row: KeyMap<Broadcaster>): string {
        return (
            "Sources(" +
            (row.status?.inputs_count || "0") +
            ") Channels(" +
            (row.status?.adaptives_count || "0") +
            ") Targets(" +
            (row.status?.outputs_count || "0") +
            ")"
        );
    }

    private getValueToExportForDecimalPipe(value: number | undefined) {
        if (!value) return "";
        return parseFloat(this.decimalPipe.transform(value, "1.0-2"));
    }

    getBroadcastersForInsights() {
        if (this.viewOption === "broadcasters") return this.selectedBroadcasterRows;
        else
            return this.selectedRows.reduce(
                (broadcasters, cluster) => broadcasters.concat(cluster.broadcasters),
                [] as Broadcaster[]
            );
    }

    private agentZStatus(broadcaster: Broadcaster) {
        return !broadcaster.is_enabled
            ? "-"
            : this.bs.checkAgentZHasRecentReport(broadcaster) && broadcaster.agentz_installed
            ? this.translate.instant("WORKING")
            : !this.bs.checkAgentZHasRecentReport(broadcaster) && broadcaster.agentz_installed
            ? this.translate.instant("NOT_REPORTING")
            : !broadcaster.agentz_installed
            ? this.translate.instant("NOT_INSTALLED")
            : "-";
    }
}
