<div class="details-form d-flex flex-wrap flex-row grid-gap-3">
    <div class="flex-1 flex-basis-min">
        <div class="form-group">
            <h5 translate>API_CREDENTIALS</h5>
            <div *ngIf="broadcaster.api_user || broadcaster.api_password">
                <a href="javascript:void(0)" (click)="pwVisible = true" *ngIf="!pwVisible">{{ "SHOW" | translate }}</a>
                <span *ngIf="!pwVisible">&nbsp;/&nbsp;</span>
                <a href="javascript:void(0)" (click)="copyPassword(broadcaster.api_password)" *ngIf="!pwVisible">{{ "COPY_PASSWORD" | translate }}</a>
                <span *ngIf="pwVisible"
                    >{{ broadcaster.api_user }}
                    <a href="javascript:void(0)" title="{{ 'COPY_USER' | translate }}" (click)="copyPassword(broadcaster.api_user)"
                        ><fa-icon icon="clipboard" size="sm"></fa-icon
                    ></a>
                    / {{ broadcaster.api_password }}
                    <a href="javascript:void(0)" title="{{ 'COPY_PASSWORD' | translate }}" (click)="copyPassword(broadcaster.api_password)"
                        ><fa-icon icon="clipboard" size="sm"></fa-icon></a
                    ><app-password-strength-icon class="d-inline-block ms-1" [password]="broadcaster.api_password"></app-password-strength-icon></span
                ><a class="ms-1" href="javascript:void(0)" (click)="pwVisible = false" *ngIf="pwVisible">{{ "HIDE" | translate }}</a>
            </div>
            <div *ngIf="!broadcaster.api_user && !broadcaster.api_password">
                {{ "NONE" | translate }}
            </div>
        </div>
        <!-- Open -->
        <div class="form-group" *ngIf="broadcaster?.configure_link && canEdit">
            <h5 translate>OPEN</h5>
            <div>
                <a href="javascript:void(0)" (click)="openLink(broadcaster)">
                    <fa-icon icon="external-link-alt" size="sm"></fa-icon>
                    {{ "BROADCASTER" | translate }}
                </a>
            </div>
        </div>
        <!-- PUBLIC_IP -->
        <div class="form-group" *ngIf="broadcaster?.status?.ip">
            <h5 translate>PUBLIC_IP</h5>
            <div>
                <span>{{ broadcaster.status?.ip }}</span
                ><span class="ms-1 text-primary cursor-pointer"
                    ><fa-icon icon="clipboard" size="sm" (click)="copyPassword(broadcaster?.status?.ip)"></fa-icon
                ></span>
            </div>
        </div>
        <!-- PRIVATE_IP -->
        <div class="form-group" *ngIf="broadcaster?.status?.private_ip && broadcaster.status.private_ip !== broadcaster.status.ip">
            <h5 translate>PRIVATE_IP</h5>
            <div>
                <span>{{ broadcaster.status?.private_ip }}</span
                ><span class="ms-1 text-primary cursor-pointer"
                    ><fa-icon icon="clipboard" size="sm" (click)="copyPassword(broadcaster?.status?.private_ip)"></fa-icon
                ></span>
            </div>
        </div>
        <!-- HOSTNAME -->
        <div class="form-group" *ngIf="broadcaster?.hostname">
            <h5 translate>HOSTNAME</h5>
            <div>
                <span>{{ broadcaster.hostname }}</span
                ><span class="ms-1 text-primary cursor-pointer"
                    ><fa-icon icon="clipboard" size="sm" (click)="copyPassword(broadcaster?.hostname)"></fa-icon
                ></span>
            </div>
        </div>
        <div class="form-group" *ngIf="broadcaster.status && broadcaster.status.hostid">
            <h5 translate>HOST_ID</h5>
            <div>
                <span>{{ broadcaster.status?.hostid }}</span
                ><span class="ms-1 text-primary cursor-pointer"
                    ><fa-icon icon="clipboard" size="sm" (click)="copyPassword(broadcaster?.status?.hostid)"></fa-icon
                ></span>
            </div>
        </div>
        <div class="form-group" *ngIf="broadcaster?.broadcaster_cluster?.aws_account_id">
            <h5 translate>INSTANCE_ID</h5>
            <div>
                <span>{{ broadcaster.cloud_id }}</span
                ><span class="ms-1 text-primary cursor-pointer"
                    ><fa-icon icon="clipboard" size="sm" (click)="copyPassword(broadcaster?.cloud_id)"></fa-icon
                ></span>
            </div>
        </div>
        <div class="form-group" *ngIf="broadcaster.instance_type">
            <h5 translate>INSTANCE_TYPE</h5>
            <div>
                <span>{{ broadcaster.instance_type }}</span
                ><span class="ms-1 text-primary cursor-pointer"
                    ><fa-icon icon="clipboard" size="sm" (click)="copyPassword(broadcaster?.instance_type)"></fa-icon
                ></span>
            </div>
        </div>
        <!-- Backup -->
        <div class="form-group">
            <h5 translate>PRIMARY</h5>
            <div>
                <span>{{ !broadcaster.is_backup ? ("YES" | translate) : ("NO" | translate) }}</span>
            </div>
        </div>
        <div class="form-group" *ngIf="broadcaster.status && broadcaster.status.about && broadcaster.status.about.version">
            <h5 translate>VERSION</h5>
            <div>
                <span>{{ versionPipe.transform(broadcaster.status?.about?.version) }}{{ broadcaster.can_transcode ? " Transcoder" : "" }}</span>
            </div>
        </div>
        <!-- Activation Key -->
        <div class="form-group" *ngIf="broadcaster.status && broadcaster.status.activation_key_link">
            <h5 translate>ACTIVATION_KEY</h5>
            <div>
                <span
                    ><a [href]="broadcaster.status?.activation_key_link" target="_blank"
                        ><fa-icon icon="external-link-alt" size="sm"></fa-icon>&nbsp;<span *ngIf="cluster?.activation_key">{{ cluster?.activation_key }}</span
                        >&nbsp;<span *ngIf="!cluster?.activation_key">License Details</span> </a
                    ><span class="text-primary cursor-pointer" *ngIf="cluster?.activation_key"
                        ><fa-icon icon="clipboard" size="sm" (click)="copyPassword(cluster?.activation_key)"></fa-icon></span
                ></span>
            </div>
        </div>
        <!-- License -->
        <div class="form-group" *ngIf="!broadcaster?.status?.activation_key_link && broadcaster?.status?.license?.error">
            <h5 translate>LICENSE</h5>
            <div>{{ broadcaster?.status?.license?.error }}</div>
        </div>
        <!-- License -->
        <!-- System Logs -->
        <div class="form-group">
            <h5 translate>SYSTEM_LOGS</h5>
            <a target="_blank" href="/api/broadcasters/{{ broadcaster.id }}/system_logs">
                <fa-icon icon="download" size="sm"></fa-icon>
                {{ "DOWNLOAD_LOGS" | translate }}
            </a>
        </div>
        <!-- Created at -->
        <div class="form-group" *ngIf="broadcaster.created_at">
            <h5 translate>CREATED_AT</h5>
            <div>
                <span>
                    <zx-date-time-zone [dateTime]="broadcaster.created_at" />
                </span>
            </div>
        </div>
    </div>
    <div class="flex-1 flex-basis-min">
        <!-- Location -->
        <app-details-location [location]="broadcaster.location"></app-details-location>
        <!-- AgentZ -->
        <div class="form-group" *ngIf="allowAgentZ">
            <h5 translate>AGENTZ</h5>
            <div class="d-inline-block">
                <span *ngIf="!broadcaster.is_enabled">{{ "-" | translate }}</span>
                <span *ngIf="broadcaster.is_enabled && activeAgentZ && broadcaster.agentz_installed">{{ "WORKING" | translate }}</span>
                <span *ngIf="broadcaster.is_enabled && !activeAgentZ && broadcaster.agentz_installed">{{ "NOT_REPORTING" | translate }}</span>
                <span *ngIf="broadcaster.is_enabled && !broadcaster.agentz_installed">{{ "NOT_INSTALLED" | translate }}</span>
            </div>
            <div class="d-inline-block">
                &nbsp;-&nbsp;<a
                    href="javascript:void(0)"
                    class="link-underline-primary"
                    [disabled]="applying"
                    (click)="installAgentZ()"
                    *ngIf="!broadcaster.agentz_installed"
                    >{{ "INSTALL_AGENTZ" | translate }}</a
                ><a
                    href="javascript:void(0)"
                    class="link-underline-primary"
                    [disabled]="applying"
                    (click)="installAgentZ()"
                    *ngIf="broadcaster.agentz_installed"
                    >{{ "REINSTALL_AGENTZ" | translate }}
                </a>
                <span *ngIf="!applying && successfullyInstalledAgentZ" class="status-good"
                    >&nbsp;-&nbsp;<fa-icon [icon]="['far', 'check-square']" size="md" class="me-1 status-good"></fa-icon> {{ "SUCCESSFULLY" | translate }}
                    {{ "INSTALLED_AGENTZ" | translate }}
                </span>
            </div>
        </div>
        <!-- Tunnel Connection Status -->
        <div class="form-group" *ngIf="isZixiUser">
            <h5 translate>CONNECTION_STATUS</h5>
            <ng-container *ngIf="!tunnelStatus">
                <span>{{ "UNKNOWN" | translate }}</span>
            </ng-container>
            <ng-container *ngIf="tunnelStatus">
                <div class="mb-2">
                    <zx-status-icon [model]="tunnelStatus?.status" [status]="tunnelStatus?.status" class="me-1"></zx-status-icon>{{ tunnelStatus?.text }}
                </div>
                <table class="table table-sm mb-0 table-borderless w-auto mt-1" *ngIf="broadcaster.Customer?.tunnel_servers.length">
                    <thead>
                        <tr>
                            <th scope="col" translate>DNS_PREFIX</th>
                            <th scope="col" translate>STATUS</th>
                            <th scope="col">{{ "LATENCY" | translate }} [ms]</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let tunnel of broadcaster.Customer?.tunnel_servers">
                            <td>{{ tunnel.dns_prefix }}</td>
                            <td>
                                <zx-status-icon [model]="getSingleTunnelStatus(tunnel)" [status]="getSingleTunnelStatus(tunnel)" class="me-1"></zx-status-icon
                                ><span>{{ getSingleTunnelStatus(tunnel) | titlecase }}</span>
                            </td>
                            <td>{{ getSingleTunnelLatency(tunnel) }}</td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>
        </div>
        <div class="form-group row">
            <!-- Protocol -->
            <h4 class="col-12" translate>CONFIGURATION</h4>
            <div class="col-12 mb-1">
                <div class="d-flex">
                    <div>
                        <div class="form-group mb-0 me-3">
                            <h5>{{ "PROTOCOL" | translate }}</h5>
                            <div *ngIf="portsState?.regular?.public?.length || portsState?.regular?.private?.length">{{ "ZIXI" | translate }}</div>
                            <div *ngIf="portsState?.dtls?.public?.length || portsState?.dtls?.private?.length">{{ "ZIXI_DTLS" | translate }}</div>
                        </div>
                    </div>
                    <div>
                        <div class="form-group mb-0 me-3">
                            <h5>{{ "OUTPUT" | translate }}</h5>
                            <div>
                                <span *ngFor="let port of portsState?.regular.public" class="me-1 badge rounded-pill font-weight-normal {{ port.color }}">{{
                                    port.port
                                }}</span>
                            </div>
                            <div>
                                <span *ngFor="let port of portsState?.dtls.public" class="me-1 badge rounded-pill font-weight-normal {{ port.color }}">{{
                                    port.port
                                }}</span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="form-group mb-0">
                            <h5>{{ "INPUT" | translate }}</h5>
                            <div>
                                <span *ngFor="let port of portsState?.regular.private" class="me-1 badge rounded-pill font-weight-normal {{ port.color }}">{{
                                    port.port
                                }}</span>
                            </div>
                            <div>
                                <span *ngFor="let port of portsState?.dtls.private" class="me-1 badge rounded-pill font-weight-normal {{ port.color }}">{{
                                    port.port
                                }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Ports -->
            <div class="col-12 mt-2">
                <div *ngIf="hasPortsState" class="mb-2">
                    <span class="me-3"><fa-icon icon="circle" size="sm" class="me-1 status-good"></fa-icon>{{ "OK" | translate }}</span>
                    <span class="me-3"><fa-icon icon="circle" size="sm" class="me-1 status-bad"></fa-icon>{{ "MISSING" | translate }}</span>
                </div>
                <div *ngIf="!hasPortsState" class="mb-2">
                    <span><fa-icon icon="circle" size="sm" class="me-1 status-none"></fa-icon>{{ "UNKNOWN" | translate }}</span>
                </div>
                <!-- DTLS -->
                <div *ngIf="cluster.dtls" class="mb-2">
                    <div>
                        <strong>{{ "DTLS_CERTIFICATE" | translate }}&nbsp;-&nbsp;</strong>
                        <span *ngIf="!hasPortsState" class="status-none">{{ "UNKNOWN" | translate }}</span>
                        <span
                            *ngIf="hasPortsState && broadcaster.status.dtls && broadcaster.status.dtls.ssl_cert_exists && broadcaster.status.dtls.ssl_pk_exists"
                            class="status-good"
                        >
                            <fa-icon [icon]="['far', 'check-square']" size="md" class="me-1 status-good"></fa-icon>{{ "INSTALLED" | translate }}
                        </span>
                    </div>
                    <span *ngIf="hasPortsState && broadcaster.status.dtls && broadcaster.status.dtls.ssl_cert_exists && broadcaster.status.dtls.ssl_pk_exists">
                        {{ "EXPIRES" | translate }}:&nbsp;{{ broadcaster.status.dtls.details.valid_until || ("UNKNOWN" | translate) }}
                    </span>
                    <span
                        *ngIf="
                            hasPortsState && (!broadcaster.status.dtls || !(broadcaster.status.dtls.ssl_cert_exists && broadcaster.status.dtls.ssl_pk_exists))
                        "
                        class="status-bad"
                    >
                        <fa-icon icon="ban" size="sm" class="me-1 status-bad"></fa-icon>{{ "MISSING" | translate }}
                    </span>
                </div>
                <!-- Broadcaster Config -->
                <div>
                    <button type="button" class="btn btn-link p-0 text-start" [disabled]="applying" (click)="applyConfig()">
                        {{ "UPDATE_BROADCASTER_CONFIGURATION" | translate
                        }}<span *ngIf="applying" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                    </button>
                    <span *ngIf="!applying && successfullyUpdateConfig" class="status-good">
                        - <fa-icon [icon]="['far', 'check-square']" size="md" class="me-1 status-good"></fa-icon> {{ "SUCCESSFULLY" | translate }}
                        {{ "UPDATED" | translate }} {{ "CONFIGURATION" | translate }}
                    </span>
                    <div>
                        <small *ngIf="needsPortsConfig" class="status-error"
                            >{{ "WARNING_WRITING_PORTS_CONFIG_WILL_REQUIRE_BROADCASTER_BE_RESTARTED" | translate }}<br
                        /></small>
                        <small>{{ "UPDATE_AUTH_DTLS_PORTS_CONFIG_ON_BROADCASTER" | translate }}</small>
                    </div>
                </div>
                <app-error [marginBottom]="false" [marginTop]="true"></app-error>
            </div>
        </div>
    </div>
</div>
