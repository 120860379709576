<div class="d-flex justify-content-start" *ngIf="sources">
    <div *ngFor="let source of sources" class="position-relative">
        <zx-status-icon
            class="me-1"
            icon="square"
            triggers="manual"
            container="body"
            [model]="source"
            [showTooltip]="false"
            (mouseenter)="popover.open()"
            (mouseleave)="onButtonMouseLeave()"
            [ngbPopover]="popoverContent"
            #popover="ngbPopover"
        ></zx-status-icon>
        <ng-template #popoverContent>
            <div class="thumbnail-wrapper" (mouseenter)="onPopoverMouseEnter()" (mouseleave)="onPopoverMouseLeave()">
                <app-source-thumbnail [source]="source" [info]="true" [grid]="true"></app-source-thumbnail>
            </div>
        </ng-template>
    </div>
</div>
