import { Component, Input, OnInit } from "@angular/core";
import { Source, ZixiObject } from "src/app/models/shared";
import { TargetsService } from "src/app/pages/targets/targets.service";
import { Grid } from "../../grid";
import { SharedService } from "src/app/services/shared.service";
import { TargetObjectType } from "src/app/pages/channels/channel";
import { SourcesService } from "src/app/pages/sources/sources.service";

@Component({
    selector: "app-grid-content",
    templateUrl: "./grid-content.component.html"
})
export class GridContentComponent implements OnInit {
    @Input() grid: Grid;
    @Input() object: ZixiObject;
    @Input() popover?: boolean;
    @Input() overlay?: boolean;
    @Input() displayAlerts?: boolean;
    @Input() displayAudio?: boolean;
    @Input() displayTime?: boolean;
    @Input() infoBtm?: boolean;
    @Input() searchTerm?: string | string[];

    targetSource: Source = null;

    constructor(
        private targetsService: TargetsService,
        private sharedService: SharedService,
        private sourcesService: SourcesService
    ) {}

    async ngOnInit() {
        if (this.grid.thumbnails) {
            if (this.object.category === "target") {
                const targetSourceID = this.targetsService.getTargetSourceId(this.object as TargetObjectType);
                if (targetSourceID) {
                    this.targetSource = this.sourcesService.getCachedSource(null, null, targetSourceID);
                    if (!this.targetSource || this.isTargetDown(this.object)) this.targetSource = null;
                } else this.targetSource = null;
            }
        }
    }

    isTargetDown(target) {
        if (!target?.status?.stats?.net_bitrate) return true;
        return false;
    }
}
