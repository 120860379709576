<div class="grid-detail-container" #gridDetail>
    <content-loading-animation *ngIf="loadingDetails"></content-loading-animation>

    <div class="grid-page" [hidden]="loadingDetails" [ngClass]="{ expanded: isExpanded }">
        <div class="title-bar" [ngClass]="{ 'p-0 border-0': !showTopBar }">
            <div class="title" *ngIf="showTopBar">
                <h1 class="ellipsis" title="{{ grid?.name }}">
                    <button type="button" class="btn btn-round me-1 back-button" (click)="back()" title="{{ 'BACK' | translate }}">
                        <fa-icon icon="chevron-left" size="md"></fa-icon></button
                    >{{ grid?.name }}
                </h1>
            </div>
            <div class="actions mb-0" *ngIf="showTopBar">
                <div class="form-inline justify-content-end">
                    <fa-icon
                        class="me-2 mb-2 tourStartButton"
                        icon="question-circle"
                        #tooltip="matTooltip"
                        matTooltip="{{ 'YOU_CAN_LEARN_MORE_ABOUT_STATUS_GRID_DISPLAY_BY_CLICKING_HERE' | translate }}"
                        matTooltipPosition="below"
                        matTooltipClass="below"
                        size="lg"
                        (click)="tourService.start(); mixpanelService.sendEvent('start status grid display feature guide')"
                        *ngIf="!grid?.thumbnails && viewOption !== 'list'"
                    ></fa-icon>
                    <fa-icon
                        class="me-2 mb-2 tourStartButton"
                        icon="question-circle"
                        #tooltip="matTooltip"
                        matTooltip="{{ 'YOU_CAN_LEARN_MORE_ABOUT_THUMBNAIL_GRID_DISPLAY_BY_CLICKING_HERE' | translate }}"
                        matTooltipPosition="below"
                        matTooltipClass="below"
                        size="lg"
                        (click)="tourService.start(); mixpanelService.sendEvent('start thumbnail grid display feature guide')"
                        *ngIf="grid?.thumbnails && viewOption !== 'list'"
                    ></fa-icon>
                    <button
                        *ngIf="selectedObjects.length > 0"
                        type="button"
                        class="btn btn-outline-secondary mb-2 me-2"
                        title="{{ 'GO_TO_ALTERNATIVE' | translate }}"
                        (click)="toggleDR()"
                        [disabled]="loadingDisasterRecovery"
                    >
                        <fa-icon icon="alt" size="sm"></fa-icon>
                        <span class="d-none d-xl-inline-block ms-1">
                            {{ "GO_TO_ALTERNATIVE" | translate }}
                        </span>
                        <span *ngIf="loadingDisasterRecovery" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                    </button>
                    <!-- Search -->
                    <div class="form-group mb-2 me-2" [ngClass]="{ 'd-none': viewOption === 'list' }">
                        <label class="sr-only" for="searchTerm">{{ "FILTER" | translate }}</label>
                        <div class="input-group">
                            <input
                                class="form-control"
                                type="text"
                                id="searchTerm"
                                name="searchTerm"
                                [(ngModel)]="searchTerm"
                                placeholder="{{ 'FILTER' | translate }}"
                                title="{{ 'BOOLEAN_SEARCH_SUPPORTED' | translate }}"
                            />
                            <button class="btn btn-outline-light" type="button" (click)="resetSearch()" *ngIf="searchTerm && searchTerm.length > 0">
                                <fa-icon icon="times" size="sm"></fa-icon>
                            </button>
                        </div>
                    </div>
                    <!-- Report -->
                    <button
                        type="button"
                        class="btn btn-outline-secondary mb-2 me-2"
                        title="{{ 'DOWNLOAD_EXCEL_FILE' | translate }}"
                        (click)="exportToExcel()"
                        [ngClass]="{ 'd-none': viewOption === 'list' }"
                    >
                        <fa-icon icon="file-excel" size="md"></fa-icon>
                    </button>
                    <div class="form-group mb-2 me-2" tourAnchor="secondGridDetailAnchor">
                        <div
                            class="d-inline"
                            ngbDropdown
                            container="body"
                            placement="bottom-right"
                            tourAnchor="secondAnchorThumb"
                            [ngClass]="{ 'd-none': viewOption === 'list' }"
                        >
                            <button class="btn btn-outline-secondary" id="settingsDropdown" ngbDropdownToggle title="{{ 'SETTINGS' | translate }}">
                                <fa-icon icon="cog"></fa-icon>
                                <span class="sr-only">{{ "SETTINGS" | translate }}</span>
                            </button>
                            <div ngbDropdownMenu aria-labelledby="settingsDropdown" class="shadow-sm minw-220px">
                                <form class="px-3 py-2" *ngIf="grid?.thumbnails">
                                    <div class="form-group">
                                        <label class="font-weight-normal" for="thumbnailRefresh"
                                            >{{ "THUMBNAIL_REFRESH" | translate }} [{{ "SECONDS" | translate | lowercase }}]</label
                                        >
                                        <ngx-slider
                                            class="thumbnail-refresh-slider"
                                            [(value)]="thumbnailRefreshSeconds"
                                            (userChangeEnd)="thumbnailRefreshChanged()"
                                            id="thumbnailRefresh"
                                            name="thumbnailRefresh"
                                            [options]="{ minLimit: 2, floor: 2, maxLimit: 20, ceil: 20, showSelectionBar: true }"
                                        ></ngx-slider>
                                    </div>
                                </form>
                                <button class="text-secondary" title="{{ 'FIT_TO_SCREEN' | translate }}" ngbDropdownItem (click)="fitToScreen = !fitToScreen">
                                    <div class="dropdown-checkbox">
                                        <input type="checkbox" class="form-check-input" id="fitToScreen" name="fitToScreen" [(ngModel)]="fitToScreen" />
                                        <label class="form-check-label" for="fitToScreen">{{ "FIT_TO_SCREEN" | translate }}</label>
                                    </div>
                                </button>
                                <button
                                    class="text-secondary"
                                    title="{{ 'SHOW_ALERTS' | translate }}"
                                    ngbDropdownItem
                                    (click)="displayAlerts = !displayAlerts"
                                    *ngIf="grid?.thumbnails"
                                >
                                    <div class="dropdown-checkbox">
                                        <input type="checkbox" class="form-check-input" id="displayAlerts" name="displayAlerts" [(ngModel)]="displayAlerts" />
                                        <label class="form-check-label" for="displayAlerts">{{ "SHOW_ALERTS" | translate }}</label>
                                    </div>
                                </button>
                                <button
                                    class="text-secondary"
                                    title="{{ 'SHOW_AUDIO' | translate }}"
                                    ngbDropdownItem
                                    (click)="displayAudio = !displayAudio"
                                    *ngIf="grid?.thumbnails"
                                >
                                    <div class="dropdown-checkbox">
                                        <input type="checkbox" class="form-check-input" id="displayAudio" name="displayAudio" [(ngModel)]="displayAudio" />
                                        <label class="form-check-label" for="displayAudio">{{ "SHOW_AUDIO" | translate }}</label>
                                    </div>
                                </button>
                                <button
                                    class="text-secondary"
                                    title="{{ 'SHOW_TIME' | translate }}"
                                    ngbDropdownItem
                                    (click)="displayTime = !displayTime"
                                    *ngIf="grid?.thumbnails"
                                >
                                    <div class="dropdown-checkbox">
                                        <input type="checkbox" class="form-check-input" id="displayTime" name="displayTime" [(ngModel)]="displayTime" />
                                        <label class="form-check-label" for="displayTime">{{ "SHOW_TIME" | translate }}</label>
                                    </div>
                                </button>
                                <button
                                    class="text-secondary"
                                    title="{{ 'SHOW_NAME_ON_BOTTOM' | translate }}"
                                    ngbDropdownItem
                                    (click)="showInfoBtm = !showInfoBtm"
                                    *ngIf="grid?.thumbnails"
                                >
                                    <div class="dropdown-checkbox">
                                        <input type="checkbox" class="form-check-input" id="showInfoBtm" name="showInfoBtm" [(ngModel)]="showInfoBtm" />
                                        <label class="form-check-label" for="showInfoBtm">{{ "SHOW_NAME_ON_BOTTOM" | translate }}</label>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- View -->
                    <div class="d-inline-block mb-2 me-2">
                        <label for="viewOptions" class="sr-only">{{ "VIEW" | translate }}</label>
                        <mat-button-toggle-group
                            id="viewOptions"
                            name="viewOption"
                            [(ngModel)]="viewOption"
                            tourAnchor="thirdGridDetailAnchor"
                            (change)="viewChange()"
                        >
                            <mat-button-toggle value="grid" title="{{ 'GRID' | translate }}">
                                <fa-icon icon="grid" size="sm"></fa-icon><span class="ms-1">{{ "GRID" | translate }}</span>
                            </mat-button-toggle>
                            <mat-button-toggle value="list" title="{{ 'TABLE' | translate }}">
                                <fa-icon icon="th-list" size="sm" flip="horizontal"></fa-icon><span class="ms-1">{{ "TABLE" | translate }}</span>
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    <!-- Edit/Clone -->
                    <div class="btn-group mb-2 me-2" role="group">
                        <button
                            *ngIf="canEditGrid(grid)"
                            type="button"
                            class="btn btn-outline-primary"
                            (click)="editGrid(grid.id)"
                            title="{{ 'EDIT' | translate }}"
                        >
                            <fa-icon icon="pencil" size="sm"></fa-icon>
                            <span class="d-none d-md-inline-block ms-1">{{ "EDIT" | translate }}</span>
                        </button>
                        <button
                            *ngIf="canEditGrid(grid)"
                            type="button"
                            class="btn btn-outline-primary"
                            (click)="cloneGrid(grid.id)"
                            title="{{ 'CLONE' | translate }}"
                        >
                            <fa-icon [icon]="['far', 'clone']" size="sm"></fa-icon>
                            <span class="d-none d-md-inline-block ms-1">{{ "CLONE" | translate }}</span>
                        </button>
                    </div>
                    <!-- Settings -->
                    <div class="form-group mb-2">
                        <!-- Refresh -->
                        <button type="button" class="btn btn-outline-secondary me-2" (click)="refresh()" title="{{ 'REFRESH' | translate }}">
                            <fa-icon icon="sync-alt" size="sm" [spin]="refreshing"></fa-icon>
                            <span class="sr-only">{{ "REFRESH" | translate }}</span>
                        </button>
                        <!-- Expand -->
                        <button type="button" class="btn btn-outline-secondary" (click)="expand()" title="{{ 'EXPAND' | translate }}" *ngIf="!isExpanded">
                            <fa-icon icon="expand" size="sm"></fa-icon>
                            <span class="sr-only">{{ "EXPAND" | translate }}</span>
                        </button>
                        <!-- Contract -->
                        <button type="button" class="btn btn-outline-secondary" (click)="contract()" title="{{ 'CONTRACT' | translate }}" *ngIf="isExpanded">
                            <fa-icon icon="compress" size="sm"></fa-icon>
                            <span class="sr-only">{{ "CONTRACT" | translate }}</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="toggle-topbar-btn" *ngIf="isExpanded" (click)="toggleTopBar()">
                <fa-icon icon="angle-double-up" size="xs" *ngIf="showTopBar"></fa-icon>
                <fa-icon icon="angle-double-down" size="xs" *ngIf="!showTopBar"></fa-icon>
            </div>
        </div>

        <div class="panel-container">
            <!-- List View -->
            <div class="list-panel" [ngClass]="{ 'd-none': viewOption !== 'list' }">
                <div class="list-container overflow-y-hidden">
                    <!-- Table -->
                    <app-table-list
                        *ngIf="viewOption === 'list' && !grid.thumbnails"
                        [tableName]="'grid-list'"
                        [displayTableName]="'OBJECTS' | translate"
                        [data]="listObjects$ | async"
                        [(tableSchema)]="tableColumnsSchema"
                        (currentSortDirection)="onSort($event)"
                        (currentPage)="onPagination($event)"
                        [(selectedRows)]="selectedRows"
                        [showReport]="true"
                        [showPagination]="true"
                        [showColumnsSelection]="true"
                        [hoverable]="false"
                        [selectable]="false"
                        [excelReportTitle]="('GRID' | translate) + '-' + grid.name"
                        [showTour]="true"
                    >
                        <div *ngIf="selectedRows.length > 0">
                            <button
                                type="button"
                                class="btn btn-outline-secondary"
                                title="{{ 'GO_TO_ALTERNATIVE' | translate }}"
                                (click)="toggleDR()"
                                [disabled]="loadingDisasterRecovery"
                            >
                                <fa-icon icon="alt" size="sm"></fa-icon>
                                <span class="d-none d-xl-inline-block ms-1">
                                    {{ "GO_TO_ALTERNATIVE" | translate }}
                                </span>
                                <span *ngIf="loadingDisasterRecovery" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                            </button>
                        </div>
                    </app-table-list>
                    <!-- Table w/ Thumbnails -->
                    <app-table-list
                        *ngIf="viewOption === 'list' && grid.thumbnails"
                        [tableName]="'grid-list-thumbnails'"
                        [displayTableName]="'OBJECTS' | translate"
                        [data]="listObjects$ | async"
                        [(tableSchema)]="tableColumnsSchemaThumbnails"
                        (currentSortDirection)="onSort($event)"
                        (currentPage)="onPagination($event)"
                        [(selectedRows)]="selectedRows"
                        [showReport]="true"
                        [showPagination]="true"
                        [showColumnsSelection]="true"
                        [hoverable]="false"
                        [selectable]="false"
                        [excelReportTitle]="('GRID' | translate) + '-' + grid.name"
                        [showTour]="true"
                    >
                        <div *ngIf="selectedRows.length > 0">
                            <button
                                type="button"
                                class="btn btn-outline-secondary"
                                title="{{ 'GO_TO_ALTERNATIVE' | translate }}"
                                (click)="toggleDR()"
                                [disabled]="loadingDisasterRecovery"
                            >
                                <fa-icon icon="alt" size="sm"></fa-icon>
                                <span class="d-none d-xl-inline-block ms-1">
                                    {{ "GO_TO_ALTERNATIVE" | translate }}
                                </span>
                                <span *ngIf="loadingDisasterRecovery" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                            </button>
                        </div>
                    </app-table-list>
                </div>
            </div>
            <!-- Grid View -->
            <div class="grid-panel" tourAnchor="firstAnchorThumb" [ngClass]="{ 'd-none': viewOption !== 'grid' }">
                <div class="grid-container" tourAnchor="firstGridDetailAnchor">
                    <!-- TODO: Replace all below with zx-grid eventually -->
                    <div
                        class="grid-content"
                        #gridContent
                        [ngStyle]="{
                            'height.%': grid?.rows ? 100 : 'auto'
                        }"
                    >
                        <div
                            class="grid-row"
                            *ngFor="let objects of objects$ | async; trackBy: trackByFunction"
                            [ngStyle]="{
                                'min-height': grid?.rows && fitToScreen ? 'auto' : tileHeight + 'px',
                                'flex-basis.%': 100 / gridRows,
                                'flex-grow': fitToScreen ? 1 : 0
                            }"
                        >
                            <div
                                class="grid-object"
                                *ngFor="let object of objects; trackBy: trackByFunction"
                                [ngStyle]="{ 'flex-basis.%': 100 / gridCols, 'max-width.%': 100 / gridCols }"
                                (click)="clickObject(object)"
                                [ngClass]="{ isSelectable: selectMode, selected: object.isSelected }"
                            >
                                <div *ngIf="!grid.thumbnails" class="grid-content-container">
                                    <app-grid-content
                                        [grid]="grid"
                                        [object]="object"
                                        [ngbPopover]="objectContent"
                                        [searchTerm]="searchTermArray"
                                    ></app-grid-content>
                                    <ng-template #objectContent>
                                        <app-grid-content [grid]="grid" [object]="object" [popover]="true"></app-grid-content>
                                    </ng-template>
                                </div>
                                <div *ngIf="grid.thumbnails" class="grid-content-container">
                                    <app-grid-content
                                        [grid]="grid"
                                        [object]="object"
                                        [overlay]="true"
                                        [displayAlerts]="displayAlerts"
                                        [displayAudio]="displayAudio"
                                        [displayTime]="displayTime"
                                        [infoBtm]="showInfoBtm"
                                        [searchTerm]="searchTermArray"
                                        [refreshInterval]="thumbnailRefreshSeconds * 1000"
                                    ></app-grid-content>
                                </div>
                            </div>
                        </div>
                        <div class="grid-no-results" *ngIf="!(objects$ | async).length">
                            <strong>{{ "NO_RESULTS" | translate }}</strong>
                        </div>
                    </div>
                    <!-- Pagination -->
                    <div class="grid-bottom" #gridBottom [ngClass]="{ 'p-0 border-0': !showBottomBar }">
                        <div class="toggle-bottombar-btn" *ngIf="isExpanded" (click)="toggleBottomBar()">
                            <fa-icon icon="angle-double-up" size="xs" *ngIf="!showBottomBar"></fa-icon>
                            <fa-icon icon="angle-double-down" size="xs" *ngIf="showBottomBar"></fa-icon>
                        </div>
                        <div class="d-flex flex-wrap grid-gap-3" *ngIf="showBottomBar">
                            <div>
                                <ngb-pagination [collectionSize]="total$ | async" [(page)]="page" [pageSize]="pageSize" [maxSize]="2"></ngb-pagination>
                            </div>
                            <!-- Showing -->
                            <div class="mt-2">
                                <small
                                    >{{ "SHOWING" | translate }}: <span *ngIf="startNum || endNum">{{ startNum }} - {{ endNum }} of</span>
                                    {{ total$ | async }}</small
                                >
                            </div>
                            <!-- Cycle -->
                            <div>
                                <div class="form-inline">
                                    <div class="form-group stay-inline mb-0">
                                        <button
                                            type="button"
                                            class="btn btn-outline-primary me-2"
                                            (click)="toggleGridCycle(cyclePaginationInterval)"
                                            title="{{ 'AUTO_PAGINATION' | translate }}"
                                        >
                                            <fa-icon icon="play" size="xs" *ngIf="!cyclePagination"></fa-icon>
                                            <fa-icon icon="pause" size="xs" *ngIf="cyclePagination"></fa-icon>
                                        </button>
                                        <label class="sr-only" for="cycle_pagination_interval">{{ "CYCLE_PAGES_EVERY" | translate }}</label>
                                        <input
                                            type="number"
                                            name="cycle_pagination_interval"
                                            class="form-control maxw-60px"
                                            [(ngModel)]="cyclePaginationInterval"
                                            (ngModelChange)="intervalChange()"
                                        />
                                        <label class="ms-1">{{ "SECONDS" | translate | lowercase }}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="selected-object-count" *ngIf="selectedObjects.length > 0">
                                {{ selectedObjects.length }}
                                {{ selectedObjects.length > 1 ? ("OBJECTS_SELECTED" | translate | lowercase) : ("OBJECT_SELECTED" | translate | lowercase) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
