<content-loading-animation *ngIf="loading"></content-loading-animation>
<form id="form" #form="ngForm" (ngSubmit)="form.valid && !nameControl.invalid && !tagsControl.invalid && onSubmit(form)" *ngIf="!loading">
    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div class="title">
                    <h1 *ngIf="!isClone && !isEdit">{{ "CREATE_NEW_RTMP_TARGET" | translate }}</h1>
                    <h1 *ngIf="isClone">{{ "CLONE_RTMP_TARGET" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_RTMP_TARGET" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area">
        <div class="container-fluid">
            <!-- RTMP -->
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <section id="info">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INFO' | translate }}">
                                {{ "INFO" | translate }}
                            </h3>
                        </div>

                        <!-- Name -->
                        <app-input-generic-field
                            zmid="form_name"
                            [isRestartRequiredTip]="isEdit"
                            [duplicateName]="targetNames"
                            [formControl]="nameControl"
                            [textFieldName]="'NAME' | translate"
                            [disabled]="saving"
                            [isParentFormSubmitted]="form.submitted"
                            type="text"
                        ></app-input-generic-field>

                        <!-- Access Tags -->
                        <zx-access-tags-select
                            zmid="form_access-tags"
                            [formControl]="tagsControl"
                            [label]="'TAGS' | translate"
                            name="resource_tag_ids"
                            type="delivery"
                            [isParentFormSubmitted]="form.submitted"
                        ></zx-access-tags-select>
                        <!-- Alerting Profile -->
                        <div class="form-group">
                            <label for="alertingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }">{{
                                "ALERTING_PROFILE" | translate
                            }}</label>
                            <zx-alerting-profile-select
                                name="alertingProfile"
                                [(model)]="target.alertingProfile"
                                [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }"
                            ></zx-alerting-profile-select>
                        </div>
                        <!-- Location -->
                        <div class="form-group">
                            <label for="location_text"
                                >{{ "LOCATION" | translate
                                }}<fa-icon icon="info-circle" [ngbTooltip]="LocationContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                                <ng-template #LocationContent>{{ "TOOLTIP.TARGET_LOCATION" | translate }}</ng-template></label
                            >
                            <zx-location-search [(model)]="target.location.address"></zx-location-search>
                        </div>
                    </section>

                    <section id="config">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'CONFIGURATION' | translate }}">
                                {{ "CONFIGURATION" | translate }}
                            </h3>
                        </div>

                        <!-- Channel Type -->
                        <fieldset class="form-group">
                            <legend for="channel_type">
                                {{ "CHANNEL_TYPE" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </legend>
                            <mat-button-toggle-group name="channel_type" aria-label="channel type" [(ngModel)]="channelType" (change)="resetChannelId()">
                                <mat-button-toggle value="passthrough_channel">{{ "ZM_CHANNEL" | translate }}</mat-button-toggle>
                                <mat-button-toggle value="medialive_channel">{{ "AWS_MEDIALIVE_CHANNEL" | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </fieldset>

                        <!-- Channel Select -->
                        <ng-container *ngIf="channelType === 'passthrough_channel'">
                            <div class="form-group">
                                <label for="delivery_channel_select">
                                    {{ "CHANNEL" | translate
                                    }}<fa-icon icon="info-circle" [ngbTooltip]="COContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                    ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                                </label>
                                <zx-delivery-channel-select
                                    id="delivery_channel_select"
                                    name="delivery_channel_select"
                                    [(model)]="selectedChannelID"
                                    [clearable]="true"
                                    [failover]="true"
                                ></zx-delivery-channel-select>
                            </div>
                            <!-- Source preference -->
                            <div class="form-group">
                                <label for="source_preference">
                                    {{ "SOURCE_PREFERENCE" | translate }}
                                </label>
                                <zx-delivery-channel-source-select
                                    id="preferred_source"
                                    name="preferred_source"
                                    [(channel_id)]="selectedChannelID"
                                    [(model)]="target.preferred_source"
                                    [disabled]="!selectedChannelID"
                                >
                                </zx-delivery-channel-source-select>
                            </div>
                            <!-- Single Output -->
                            <div class="form-group">
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="single_output"
                                        name="single_output"
                                        [(ngModel)]="target.single_output"
                                    />
                                    <label class="form-check-label" for="single_output">{{ "SINGLE_OUTPUT" | translate }}</label>
                                </div>
                                <small>{{ "SINGLE_OUTPUT_NOTE" | translate }}</small>
                            </div>
                        </ng-container>

                        <!-- MediaLive Channel Select -->
                        <ng-container *ngIf="channelType === 'medialive_channel'">
                            <div class="form-group">
                                <label for="medialive_channel_select">
                                    {{ "CHANNEL" | translate
                                    }}<fa-icon icon="info-circle" [ngbTooltip]="COContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                    ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                                </label>
                                <zx-medialive-channel-select
                                    id="medialive_channel_select"
                                    name="medialive_channel_select"
                                    [(model)]="selectedChannelID"
                                    [clearable]="true"
                                ></zx-medialive-channel-select>
                            </div>
                            <!-- Transcoding Profile -->
                            <div class="form-group">
                                <label for="transcodingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.transcodingProfile?.errors }"
                                    >{{ "TRANSCODING_PROFILE" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                    ><fa-icon
                                        *ngIf="isEdit"
                                        icon="redo"
                                        size="sm"
                                        [ngbTooltip]="RestartContent"
                                        triggers="hover click"
                                        [closeDelay]="500"
                                    ></fa-icon
                                    ><ng-template #RestartContent>{{ "RESTART_REQUIRED" | translate }}</ng-template></label
                                >
                                <zx-transcoding-profile-select
                                    name="transcodingProfile"
                                    [(model)]="transcodeProfile"
                                    required="true"
                                    [ngClass]="{ 'is-invalid': form.submitted && form.controls.transcodingProfile?.errors }"
                                ></zx-transcoding-profile-select>
                                <div *ngIf="form.controls.transcodingProfile?.invalid" class="invalid-feedback">
                                    <div *ngIf="form.controls.transcodingProfile?.errors.required">
                                        {{ "PROFILE" | translate }} {{ "IS_REQUIRED" | translate }}.
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <!-- Target -->
                        <div class="form-group">
                            <label for="rtmpTarget" [ngClass]="{ 'is-invalid': form.submitted && rtmpTarget.errors }"
                                >{{ "TARGET" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <input
                                type="text"
                                id="rtmpTarget"
                                name="rtmpTarget"
                                placeholder="{{ 'TARGET' | translate }}"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': form.submitted && rtmpTarget.errors }"
                                [(ngModel)]="target.target"
                                required
                                pattern="^(rtmp|rtmps)?://.+"
                                #rtmpTarget="ngModel"
                            />
                            <div *ngIf="rtmpTarget.invalid" class="invalid-feedback">
                                <div *ngIf="rtmpTarget.errors.required">{{ "TARGET" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                <div *ngIf="rtmpTarget.errors.pattern">{{ "TARGET" | translate }} {{ "INVALID" | translate }}.</div>
                            </div>
                            <small>e.g. rtmp://my.rtmp.server.com/live</small>
                        </div>
                        <!-- Alt Target -->
                        <div class="form-group" *ngIf="channelType !== 'medialive_channel'">
                            <label for="altTarget" [ngClass]="{ 'is-invalid': form.submitted && altTarget.errors }"
                                >{{ "ALT_TARGET" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <input
                                type="text"
                                id="altTarget"
                                name="altTarget"
                                placeholder="{{ 'ALT_TARGET' | translate }}"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': form.submitted && altTarget.errors }"
                                [(ngModel)]="target.alt_target"
                                pattern="^(rtmp|rtmps)?://.+"
                                #altTarget="ngModel"
                            />
                            <div *ngIf="altTarget.invalid" class="invalid-feedback">
                                <div *ngIf="altTarget.errors.pattern">{{ "ALT_TARGET" | translate }} {{ "INVALID" | translate }}.</div>
                            </div>
                        </div>

                        <!-- Stream Name -->
                        <div class="form-group">
                            <label for="streamName" [ngClass]="{ 'is-invalid': form.submitted && streamName.errors }"
                                >{{ "STREAM_NAME" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <input
                                type="text"
                                id="streamName"
                                name="streamName"
                                placeholder="{{ 'STREAM_NAME' | translate }}"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': form.submitted && streamName.errors }"
                                [(ngModel)]="target.stream_name"
                                required
                                #streamName="ngModel"
                            />
                            <div *ngIf="streamName.invalid" class="invalid-feedback">
                                <div *ngIf="streamName.errors.required">{{ "STREAM_NAME" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            </div>
                        </div>
                        <!-- Username -->
                        <div class="form-group" *ngIf="channelType !== 'medialive_channel'">
                            <label for="username" [ngClass]="{ 'is-invalid': form.submitted && username.errors }"
                                >{{ "USERNAME" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <input
                                type="text"
                                id="username"
                                name="username"
                                placeholder="{{ 'USERNAME' | translate }}"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': form.submitted && username.errors }"
                                [(ngModel)]="target.username"
                                #username="ngModel"
                            />
                        </div>
                        <!-- Password -->
                        <div class="form-group" *ngIf="channelType !== 'medialive_channel'">
                            <label for="password" [ngClass]="{ 'is-invalid': form.submitted && password.errors }">
                                {{ "PASSWORD" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </label>
                            <div class="input-group">
                                <input
                                    type="{{ show_password ? 'text' : 'password' }}"
                                    autocomplete="cc-csc"
                                    id="password"
                                    name="password"
                                    placeholder="{{ 'PASSWORD' | translate }}"
                                    class="form-control"
                                    [ngClass]="{ 'is-invalid': form.submitted && password.errors }"
                                    [(ngModel)]="target.password"
                                    #password="ngModel"
                                />
                                <button
                                    class="btn btn-outline-primary"
                                    (click)="show_password = !show_password"
                                    type="button"
                                    title="{{ 'SHOW_PASSWORD' | translate }}"
                                >
                                    <fa-icon icon="eye" *ngIf="!show_password"></fa-icon>
                                    <fa-icon icon="eye-slash" *ngIf="show_password"></fa-icon>
                                </button>
                                <button
                                    class="btn btn-outline-primary"
                                    (click)="copyPassword(target.password)"
                                    type="button"
                                    title="{{ 'COPY_PASSWORD' | translate }}"
                                >
                                    <fa-icon icon="clipboard"></fa-icon>
                                </button>
                            </div>
                        </div>
                        <!-- PID Mapping Profile -->
                        <div class="form-group" *ngIf="channelType === 'passthrough_channel'">
                            <label for="pidMappingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.pidMappingProfile?.errors }"
                                >{{ "PID_MAPPING_PROFILE" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <zx-pid-mapping-profile-select
                                name="pidMappingProfile"
                                [(model)]="pid_mapping_profile_id"
                                [ngClass]="{ 'is-invalid': form.submitted && form.controls.pidMappingProfile?.errors }"
                            ></zx-pid-mapping-profile-select>
                            <div *ngIf="form.controls.pidMappingProfile?.invalid" class="invalid-feedback">
                                <div *ngIf="form.controls.pidMappingProfile?.errors">{{ "PID_MAPPING_PROFILE" | translate }} {{ "ERROR" | translate }}.</div>
                            </div>
                        </div>
                    </section>
                    <!-- State -->
                    <section id="state" *ngIf="!isEdit">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INITIAL_STATE' | translate }}">
                                {{ "INITIAL_STATE" | translate }}
                            </h3>
                        </div>
                        <!-- Enabled -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="is_enabled" name="is_enabled" [(ngModel)]="startDisabled" />
                                <label class="form-check-label" for="is_enabled">{{ "DISABLED" | translate }}</label>
                            </div>
                        </div>
                        <!-- Muted -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="muted" name="muted" [(ngModel)]="target.muted" />
                                <label class="form-check-label" for="muted">{{ "MUTED" | translate }}</label>
                            </div>
                        </div>
                    </section>

                    <app-advanced-section-form
                        [showBilling]="true"
                        [showExternalID]="true"
                        [isTarget]="true"
                        [model]="target"
                        [form]="form"
                        [isEdit]="isEdit"
                    ></app-advanced-section-form>
                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <hr class="mt-0" />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary float-start" (click)="back()" *ngIf="!isEdit && !isClone" [disabled]="saving">
                            <fa-icon icon="chevron-left" size="sm"></fa-icon>
                            {{ "BACK" | translate }}
                        </button>
                        <button type="button" class="btn btn-outline-secondary" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary ms-2"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && form.invalid }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
