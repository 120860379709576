<div class="ellipsis">
    <span *ngIf="showTag" class="badge badge-primary me-1 tag" title="{{ 'SOURCE' | translate }}">S</span
    ><zx-status-icon [model]="source" [standby]="source?.acknowledged" title="{{ source | statusText | translate }}"></zx-status-icon
    ><fa-icon *ngIf="source.acknowledged" class="ms-1" icon="check" title="{{ 'ERROR_STATE_ACKNOWLEDGED' | translate }}"></fa-icon
    ><fa-icon
        *ngIf="source.active_mute && source.muted_until"
        class="ms-1"
        icon="bell-slash"
        title="{{ 'NOTIFICATIONS_DISABLED_UNTIL' | translate }} {{ timeZoneService.computeDateToTimeZone(source?.muted_until)() }}"
    ></fa-icon>
    <fa-icon *ngIf="source.active_mute && !source.muted_until" class="ms-1" icon="bell-slash" title="{{ 'NOTIFICATIONS_DISABLED' | translate }}"></fa-icon
    ><fa-icon *ngIf="source.status?.encrypted || source.status?.dtls" class="ms-1" icon="lock" title="{{ encryptionNote(source) }}"></fa-icon>
    <span *ngIf="source.readOnly" class="ms-1"
        >{{ source.name }}<span *ngIf="source.inputCluster?.name"> @ {{ source.inputCluster?.name }}</span></span
    >
    <span *ngIf="!source.readOnly"
        ><a class="ms-1" [routerLink]="['/' + urls.sources, source.id | uId, source.name]" (click)="$event.stopPropagation()"
            ><ngb-highlight title="{{ source.name }}" [result]="source.name" [term]="searchTerm"></ngb-highlight></a
        ><span *ngIf="source.inputCluster?.name" class="ms-1"
            >@
            <a [routerLink]="['/' + urls.clusters, source.broadcaster_cluster_id | uId, source.inputCluster?.name]" (click)="$event.stopPropagation()"
                ><ngb-highlight title="{{ source.inputCluster?.name }}" [result]="source.inputCluster?.name" [term]="searchTerm"></ngb-highlight></a></span
    ></span>
</div>
<div class="whitespace-nowrap" *ngIf="source.status && source.status?.bitrate">{{ source.status?.bitrate | number : "1.0-0" }} kbps</div>
