<content-loading-animation *ngIf="loading"></content-loading-animation>

<form id="form" #form="ngForm" (ngSubmit)="form.valid && !nameControl.invalid && !tagsControl.invalid && onSubmit(form)" *ngIf="!loading && report">
    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="const.FORM_COL_CLASS_BREAKPOINTS">
                <div zmid="title" class="title">
                    <h1 *ngIf="!isClone && !isEdit">{{ "CREATE_NEW_REPORT" | translate }}</h1>
                    <h1 *ngIf="isClone">{{ "CLONE_REPORT" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_REPORT" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="form-nav-bar">
        <ul class="nav justify-content-center">
            <li class="nav-item">
                <a
                    class="nav-link"
                    href="javascript:void(0)"
                    (click)="scrollTo('info')"
                    [ngClass]="{
                        active: currentSection === 'info',
                        error: form.submitted && infoHasErrors(form)
                    }"
                    >{{ "INFO" | translate }}</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    href="javascript:void(0)"
                    (click)="scrollTo('settings')"
                    [ngClass]="{
                        active: currentSection === 'settings',
                        error: form.submitted && settingsHasErrors(form)
                    }"
                    >{{ "CONFIGURATION" | translate }}</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    href="javascript:void(0)"
                    (click)="scrollTo('filter')"
                    [ngClass]="{ active: currentSection === 'filter', error: form.submitted && filterHasErrors(form) }"
                    >{{ "FILTER" | translate }}</a
                >
            </li>
        </ul>
    </div>
    <div class="content-area pt-0" scrollSpy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="const.FORM_COL_CLASS_BREAKPOINTS" id="scrollParent">
                    <section id="info">
                        <div class="form-section-title">
                            <h3
                                class="section-title mt-3"
                                title="{{ 'INFO' | translate }}"
                                [ngClass]="{ 'status-error': form.submitted && infoHasErrors(form) }"
                            >
                                {{ "INFO" | translate }}
                            </h3>
                        </div>
                        <!-- Name -->
                        <app-input-generic-field
                            zmid="form_name"
                            [formControl]="nameControl"
                            [textFieldName]="'NAME' | translate"
                            [disabled]="saving"
                            [isParentFormSubmitted]="form.submitted"
                            type="text"
                        ></app-input-generic-field>

                        <!-- Access Tags -->
                        <zx-access-tags-select
                            zmid="form_access-tags"
                            [formControl]="tagsControl"
                            [label]="'TAGS' | translate"
                            name="resource_tag_ids"
                            type="resource"
                            [isParentFormSubmitted]="form.submitted"
                        ></zx-access-tags-select>
                    </section>
                    <section id="settings">
                        <div class="form-section-title">
                            <h3
                                class="section-title"
                                title="{{ 'SETTINGS' | translate }}"
                                [ngClass]="{ 'status-error': form.submitted && settingsHasErrors(form) }"
                            >
                                {{ "CONFIGURATION" | translate }}
                            </h3>
                        </div>
                        <!-- Type -->
                        <fieldset class="form-group">
                            <legend for="type">{{ "REPORT_TYPE" | translate }}</legend>
                            <mat-button-toggle-group name="type" aria-label="report type" [(ngModel)]="report.type">
                                <mat-button-toggle value="usage" [disabled]="isEdit">{{ "USAGE_REPORT" | translate }}</mat-button-toggle>
                                <mat-button-toggle value="stats" [disabled]="isEdit">{{ "STATS_SUMMARY" | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </fieldset>
                        <!-- Types: Stats -->
                        <fieldset class="form-group bordered" *ngIf="report.type === 'stats'">
                            <legend>{{ "TYPES" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon></legend>
                            <div class="form-check form-check-inline">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="feeders"
                                    name="feeders"
                                    [(ngModel)]="report.feeders"
                                    [ngClass]="{
                                        'is-invalid': form.submitted && statsSummaryCheck()
                                    }"
                                />
                                <label class="form-check-label" for="feeders">{{ "FEEDERS" | translate }}</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="broadcasters"
                                    name="broadcasters"
                                    [(ngModel)]="report.broadcasters"
                                    [ngClass]="{
                                        'is-invalid': form.submitted && statsSummaryCheck()
                                    }"
                                />
                                <label class="form-check-label" for="broadcasters">{{ "BROADCASTERS" | translate }}</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="receivers"
                                    name="receivers"
                                    [(ngModel)]="report.receivers"
                                    [ngClass]="{
                                        'is-invalid': form.submitted && statsSummaryCheck()
                                    }"
                                />
                                <label class="form-check-label" for="receivers">{{ "RECEIVERS" | translate }}</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="sources"
                                    name="sources"
                                    [(ngModel)]="report.sources"
                                    [ngClass]="{
                                        'is-invalid': form.submitted && statsSummaryCheck()
                                    }"
                                />
                                <label class="form-check-label" for="sources">{{ "SOURCES" | translate }}</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="targets"
                                    name="targets"
                                    [(ngModel)]="report.targets"
                                    [ngClass]="{
                                        'is-invalid': form.submitted && statsSummaryCheck()
                                    }"
                                />
                                <label class="form-check-label" for="targets">{{ "TARGETS" | translate }}</label>
                            </div>
                            <div
                                [ngClass]="{
                                    'is-invalid': form.submitted && statsSummaryCheck()
                                }"
                            ></div>
                            <div *ngIf="form.submitted && statsSummaryCheck()" class="invalid-feedback">
                                <div>{{ "AT_LEAST_1_TYPE_IS_REQUIRED" | translate }}.</div>
                            </div>
                        </fieldset>
                        <!-- Types: Usage  -->
                        <fieldset class="form-group bordered" *ngIf="report.type === 'usage'">
                            <legend>{{ "TYPES" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon></legend>
                            <div class="form-check form-check-inline">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="sources"
                                    name="sources"
                                    [(ngModel)]="report.sources"
                                    [ngClass]="{
                                        'is-invalid': form.submitted && usageReport()
                                    }"
                                />
                                <label class="form-check-label" for="sources">{{ "SOURCES" | translate }}</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="targets"
                                    name="targets"
                                    [(ngModel)]="report.targets"
                                    [ngClass]="{
                                        'is-invalid': form.submitted && usageReport()
                                    }"
                                />
                                <label class="form-check-label" for="targets">{{ "TARGETS" | translate }}</label>
                            </div>
                            <div class="form-check form-check-inline" *ngIf="report.options">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="transcoding"
                                    name="transcoding"
                                    [(ngModel)]="report.options.transcoding"
                                    [ngClass]="{
                                        'is-invalid': form.submitted && usageReport()
                                    }"
                                />
                                <label class="form-check-label" for="transcoding">{{ "TRANSCODING" | translate }}</label>
                            </div>
                            <div
                                [ngClass]="{
                                    'is-invalid': form.submitted && usageReport()
                                }"
                            ></div>
                            <div *ngIf="form.submitted && usageReport()" class="invalid-feedback">
                                <div>{{ "AT_LEAST_1_TYPE_IS_REQUIRED" | translate }}.</div>
                            </div>
                        </fieldset>
                        <!-- Report Granularity -->
                        <div class="form-group">
                            <label for="granularity" [ngClass]="{ 'is-invalid': form.submitted && granularity.errors }">
                                {{ "GRANULARITY" | translate }}
                            </label>
                            <ng-select
                                id="granularity"
                                name="granularity"
                                required
                                [items]="granularityOptions"
                                [clearable]="false"
                                bindValue="value"
                                bindLabel="name"
                                placeholder="{{ 'SELECT_GRANULARITY' | translate }}"
                                [(ngModel)]="report.breakdown"
                                #granularity="ngModel"
                                [ngClass]="{ 'form-control is-invalid': form.submitted && granularity.errors }"
                            >
                            </ng-select>
                        </div>
                        <!-- Time -->
                        <fieldset class="form-group">
                            <legend for="time">{{ "SELECT_TIME_PERIOD_BY" | translate }}</legend>
                            <mat-button-toggle-group name="time" aria-label="set type by" [(ngModel)]="setTimeBy">
                                <mat-button-toggle value="period">{{ "PERIOD" | translate }}</mat-button-toggle>
                                <mat-button-toggle value="month">{{ "MONTH" | translate }}</mat-button-toggle>
                                <mat-button-toggle value="quarter">{{ "QUARTER" | translate }}</mat-button-toggle>
                                <mat-button-toggle value="range">{{ "CUSTOM_RANGE" | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </fieldset>
                        <!-- Month -->
                        <div class="form-group" *ngIf="setTimeBy === 'month'">
                            <label for="month" [ngClass]="{ 'is-invalid': form.submitted && month.errors }">
                                {{ "MONTH" | translate }}
                            </label>
                            <ng-select
                                id="month"
                                name="month"
                                required
                                [items]="monthOptions"
                                [clearable]="false"
                                bindValue="value"
                                bindLabel="name"
                                placeholder="{{ 'SELECT_MONTH' | translate }}"
                                [(ngModel)]="selectedMonth"
                                #month="ngModel"
                                [ngClass]="{ 'form-control is-invalid': form.submitted && month.errors }"
                            >
                            </ng-select>
                            <div *ngIf="month.invalid" class="invalid-feedback">
                                <div *ngIf="month.errors.required">{{ "MONTH" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            </div>
                        </div>
                        <!-- Quarter -->
                        <div class="form-group" *ngIf="setTimeBy === 'quarter'">
                            <label for="quarter" [ngClass]="{ 'is-invalid': form.submitted && quarter.errors }">
                                {{ "QUARTER" | translate }}
                            </label>
                            <ng-select
                                id="quarter"
                                name="quarter"
                                required
                                [items]="quarterOptions"
                                [clearable]="false"
                                bindValue="value"
                                bindLabel="name"
                                placeholder="{{ 'SELECT_QUARTER' | translate }}"
                                [(ngModel)]="selectedQuarter"
                                #quarter="ngModel"
                                [ngClass]="{ 'form-control is-invalid': form.submitted && quarter.errors }"
                            >
                            </ng-select>
                            <div *ngIf="quarter.invalid" class="invalid-feedback">
                                <div *ngIf="quarter.errors.required">{{ "QUARTER" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            </div>
                        </div>
                        <!-- Period -->
                        <div class="form-group" *ngIf="setTimeBy === 'period'">
                            <label for="period" [ngClass]="{ 'is-invalid': form.submitted && period.errors }">
                                {{ "PERIOD" | translate }}
                            </label>
                            <ng-select
                                id="period"
                                name="period"
                                required
                                [items]="periodOptions"
                                [clearable]="false"
                                bindValue="value"
                                bindLabel="name"
                                placeholder="{{ 'SELECT_PERIOD' | translate }}"
                                [(ngModel)]="selectedPeriod"
                                #period="ngModel"
                                [ngClass]="{ 'form-control is-invalid': form.submitted && period.errors }"
                            >
                            </ng-select>
                            <div *ngIf="period.invalid" class="invalid-feedback">
                                <div *ngIf="period.errors.required">{{ "PERIOD" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            </div>
                        </div>
                        <!-- Custom Range -->
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <!-- From -->
                                <div class="form-group position-relative" *ngIf="setTimeBy === 'range'">
                                    <label for="from" [ngClass]="{ 'is-invalid': form.submitted && from.errors }"
                                        >{{ "FROM_DATE" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                    ></label>
                                    <div class="input-group" [ngClass]="{ 'is-invalid': form.submitted && from.errors }">
                                        <input
                                            class="form-control"
                                            id="from"
                                            name="from"
                                            (click)="toggleFromPicker()"
                                            [(ngModel)]="fromDate"
                                            #from="ngModel"
                                            placeholder="{{ 'SELECT_FROM_DATE' | translate }}"
                                            required
                                            (keydown)="onlyDelete($event)"
                                            autocomplete="off"
                                            [ngClass]="{ 'is-invalid': form.submitted && from.errors }"
                                        />
                                        <button
                                            class="btn btn-outline-primary"
                                            (click)="toggleFromPicker()"
                                            type="button"
                                            title="{{ 'SELECT_FROM_DATE' | translate }}"
                                        >
                                            <fa-icon icon="calendar-alt" size="sm"></fa-icon>
                                        </button>
                                    </div>
                                    <div *ngIf="from.invalid" class="invalid-feedback">
                                        <div *ngIf="from.errors.required">{{ "FROM_DATE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                    </div>
                                    <div class="picker-container absolute" *ngIf="showFromPicker" (clickOutside)="clickOutsideFromPicker()">
                                        <div class="arrow"></div>
                                        <div class="picker-content shadow-sm">
                                            <fa-icon icon="times-circle" class="close-icon" (click)="closeFromPicker()"></fa-icon>
                                            <zx-date-time-picker
                                                name="startTimePicker"
                                                [(ngModel)]="fromDate"
                                                (ngModelChange)="fromDateChanged($event)"
                                                [allDates]="true"
                                                [hideTimePicker]="true"
                                            ></zx-date-time-picker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <!-- To -->
                                <div class="form-group position-relative" *ngIf="setTimeBy === 'range'">
                                    <label for="to" [ngClass]="{ 'is-invalid': form.submitted && (to.errors || checkDate(fromDate, toDate)) }"
                                        >{{ "TO_DATE" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                    ></label>
                                    <div class="input-group" [ngClass]="{ 'is-invalid': form.submitted && (to.errors || checkDate(fromDate, toDate)) }">
                                        <input
                                            class="form-control"
                                            id="to"
                                            name="to"
                                            (click)="toggleToPicker()"
                                            [(ngModel)]="toDate"
                                            #to="ngModel"
                                            placeholder="{{ 'SELECT_TO_DATE' | translate }}"
                                            (keydown)="onlyDelete($event)"
                                            autocomplete="off"
                                            [ngClass]="{ 'is-invalid': form.submitted && (to.errors || checkDate(fromDate, toDate)) }"
                                            required
                                        />
                                        <button
                                            class="btn btn-outline-primary"
                                            (click)="toggleToPicker()"
                                            type="button"
                                            title="{{ 'SELECT_TO_DATE' | translate }}"
                                        >
                                            <fa-icon icon="calendar-alt" size="sm"></fa-icon>
                                        </button>
                                    </div>
                                    <div *ngIf="form.submitted && checkDate(fromDate, toDate)" class="invalid-feedback">
                                        <div>{{ "TO_DATE" | translate }} {{ "MUST_BE_AFTER" | translate }} {{ "FROM_DATE" | translate }}.</div>
                                    </div>
                                    <div *ngIf="to.invalid" class="invalid-feedback">
                                        <div *ngIf="to.errors.required">{{ "TO_DATE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                    </div>
                                    <div class="picker-container absolute" *ngIf="showToPicker" (clickOutside)="clickOutsideToPicker()">
                                        <div class="arrow"></div>
                                        <div class="picker-content shadow-sm">
                                            <fa-icon icon="times-circle" class="close-icon" (click)="closeToPicker()"></fa-icon>
                                            <zx-date-time-picker
                                                name="endTimePicker"
                                                [(ngModel)]="toDate"
                                                (ngModelChange)="toDateChanged($event)"
                                                [allDates]="true"
                                                [hideTimePicker]="true"
                                            ></zx-date-time-picker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Timezone -->
                        <div class="form-group">
                            <label for="time_zone">
                                {{ "TIME_ZONE" | translate }}
                            </label>
                            <ng-select
                                id="time_zone"
                                name="time_zone"
                                bindLabel="text"
                                [items]="timeZones"
                                [clearable]="false"
                                placeholder="{{ 'SELECT_TIME_ZONE' | translate }}"
                                [(ngModel)]="selectedTimeZone"
                            >
                            </ng-select>
                        </div>
                    </section>
                    <section id="filter">
                        <div class="form-section-title">
                            <h3
                                class="section-title"
                                title="{{ 'FILTER' | translate }}"
                                [ngClass]="{ 'status-error': form.submitted && filterHasErrors(form) }"
                            >
                                {{ "FILTER" | translate }}
                            </h3>
                        </div>
                        <!-- Only Show Objects With Selected Tags -->
                        <div class="form-group">
                            <label for="includeResourceTags">{{ "ONLY_SHOW_OBJECTS_WITH_SELECTED_TAGS" | translate }}</label>
                            <zx-access-tags-select
                                name="includeResourceTags"
                                [formControl]="includeTagsControl"
                                [(model)]="report.includeResourceTags"
                                ro="true"
                            ></zx-access-tags-select>
                            <div class="form-check form-check-inline mt-2">
                                <input type="radio" id="matchAll" name="matchAll" class="form-check-input" [value]="1" [(ngModel)]="report.all_resource_tags" />
                                <label class="form-check-label" for="matchAll">{{ "MATCH_ALL" | translate }}</label>
                            </div>
                            <div class="form-check form-check-inline mt-2">
                                <input type="radio" id="matchAny" name="matchAny" class="form-check-input" [value]="0" [(ngModel)]="report.all_resource_tags" />
                                <label class="form-check-label" for="matchAny">{{ "MATCH_ANY" | translate }}</label>
                            </div>
                        </div>
                        <!-- Do Not Show Objects With Selected Tags -->
                        <div class="form-group">
                            <label for="excludeResourceTags">{{ "DO_NOT_SHOW_OBJECTS_WITH_SELECTED_TAGS" | translate }}</label>
                            <zx-access-tags-select
                                name="excludeResourceTags"
                                [formControl]="excludeTagsControl"
                                [(model)]="report.excludeResourceTags"
                                ro="true"
                            ></zx-access-tags-select>
                        </div>
                        <!-- Name Filter (include) -->
                        <div class="form-group">
                            <label for="name_filter_include">{{ "NAME" | translate }} {{ "FILTER" | translate }} ({{ "INCLUDE" | translate }})</label>
                            <input
                                type="text"
                                id="name_filter_include"
                                name="name_filter_include"
                                placeholder="{{ 'NAME' | translate }} {{ 'FILTER' | translate }} ({{ 'INCLUDE' | translate }})"
                                class="form-control"
                                [(ngModel)]="report.name_filter_include"
                            />
                        </div>
                        <!-- Name Filter (exclude) -->
                        <div class="form-group">
                            <label for="name_filter_exclude">{{ "NAME" | translate }} {{ "FILTER" | translate }} ({{ "EXCLUDE" | translate }})</label>
                            <input
                                type="text"
                                id="name_filter_exclude"
                                name="name_filter_exclude"
                                placeholder="{{ 'NAME' | translate }} {{ 'FILTER' | translate }} ({{ 'EXCLUDE' | translate }})"
                                class="form-control"
                                [(ngModel)]="report.name_filter_exclude"
                            />
                        </div>
                        <!-- Status -->
                        <div class="form-group">
                            <label for="status" [ngClass]="{ 'is-invalid': form.submitted && status.errors }"
                                >{{ "STATUS" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><fa-icon icon="info-circle" [ngbTooltip]="StatusContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                ><ng-template #StatusContent
                                    ><p class="mb-0">
                                        {{ "TOOLTIP.GRID_STATUS" | translate }}
                                    </p></ng-template
                                ></label
                            >
                            <ng-select
                                id="status"
                                name="status"
                                required
                                [items]="statusList"
                                [multiple]="true"
                                [closeOnSelect]="false"
                                [clearSearchOnAdd]="true"
                                [hideSelected]="true"
                                bindValue="id"
                                bindLabel="name"
                                placeholder="{{ 'SELECT_ONE_OR_MORE_STATUS_STATES' | translate }}"
                                [(ngModel)]="selectedStatuses"
                                [ngClass]="{ 'form-control is-invalid': form.submitted && status.errors }"
                                #status="ngModel"
                            >
                            </ng-select>
                            <div *ngIf="status.invalid" class="invalid-feedback">
                                <div *ngIf="status.errors.required">{{ "AT_LEAST_1_STATUS_IS_REQUIRED" | translate }}.</div>
                            </div>
                        </div>
                    </section>
                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <hr class="mt-0" />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary me-2" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && (form.invalid || nameControl.invalid || tagsControl.invalid) }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
