import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    inject,
    HostListener,
    ElementRef,
    AfterViewInit,
    ChangeDetectorRef
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom, interval, Observable, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { TargetsService } from "../targets.service";
import { AnyTarget, TargetApiType } from "../../channels/channel";
import { Constants } from "../../../constants/constants";
import { RecoveryState, Source, Tag, UserPermissions } from "../../../models/shared";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { SharedService } from "../../../services/shared.service";
import { UsersService } from "../../account-management/users/users.service";

// Components
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { GraphsService } from "src/app/services/graphs.service";
import { urlBuilder } from "@zixi/shared-utils";
import { OBJECT_TYPE } from "../../networks/network-objects-state/network-objects-state.service";
import { SourcesService } from "../../sources/sources.service";
import { FormControl } from "@angular/forms";
import { ResizeService } from "src/app/services/resize.service";
import { ObjectsActiveBroadcaster } from "@zixi/models";
import { DisasterRecoveryDialogComponent } from "src/app/components/shared/modals/disaster-recovery-dialog/disaster-recovery-dialog.component";
import { TargetLayouts } from "./target.layout";
import { NavigationService } from "src/app/components/navigation/navigation.service";
import { TagsService } from "../../configuration/tags/tags.service";

@Component({
    selector: "app-target",
    templateUrl: "./target.component.html"
})
export class TargetComponent extends TargetLayouts implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild("primaryDetailsArea", { read: ElementRef }) primaryDetailsArea: ElementRef;
    @ViewChild("secondaryDetailsArea", { read: ElementRef }) secondaryDetailsArea: ElementRef;

    @HostListener("window:resize", [])
    private onResize() {
        this.getDetailsAreaHeights();
    }

    target: AnyTarget;
    targetId: number;
    targetName: string;
    targetApiType: TargetApiType;

    RecoveryState = RecoveryState;
    recoveryState: RecoveryState;

    resourceTags: Tag[];
    tagType: string;
    targetDashboardLink: string;
    userPermissions: UserPermissions;

    activeTab: string;
    loadingDetails = true;
    private sourcesService = inject(SourcesService);
    targetSource: Source = null;

    showOverlayControl = new FormControl(false);
    private targetsSubscription: Subscription;

    isWidgetFullyLoaded = false;
    isMultiSelect = false;
    initIsMultiSelect;
    widgetsToRemoveOrAdd = [];
    isRefreshing = false;

    private navSubscription: Subscription;
    private splitterSubscription: Subscription;
    private resizeSubscription: Subscription;
    private refreshThumbnailSubscription: Subscription;

    primaryDetails = this.targetPrimaryDetails;
    secondaryDetails = this.targetSecondaryDetails;
    widgets = this.targetWidgets;
    widgetHeaders = this.targetWidgetHeaders;

    // TODO: need to handle different target types
    protected updateDetailContent(title: string) {
        switch (title) {
            // Primary
            case this.translatedNames.TAGS:
                return {
                    content: "-",
                    object: this.target.target,
                    tags: this.target.target.resourceTags,
                    type: "target",
                    canEdit: () => this.canEdit(this.target)
                };
            case this.translatedNames.PRIORITY:
                return {
                    content: this.tagsService.isObjectVip(this.target.target.resourceTags) ? "Yes" : "No"
                };
            case this.translatedNames.THUMBNAIL:
                return {
                    content: this.targetSource ? "-" : "",
                    object: this.targetSource
                };
            case this.translatedNames.PLAY_HLS:
                return {
                    content:
                        !this.target.target["playback_url"] &&
                        (this.target.target.type === "s3" ||
                            this.target.target.type === "mediastore" ||
                            this.target.target.type === "azure")
                            ? "-"
                            : "",
                    object: this.target
                };
            case this.translatedNames.PLAY_TARGET:
                return {
                    content: this.target.target["playback_url"] ? "-" : "",
                    object: this.target
                };
            // Secondary
            case this.translatedNames.PARENT_TARGET:
                return {
                    content: this.target.target?.parentZixiPull ? this.target.target?.parentZixiPull?.name : ""
                };
            case this.translatedNames.TYPE:
                return {
                    content: this.translate.instant(this.target.type_name.toUpperCase())
                };
            case this.translatedNames.ALERTING_PROFILE:
                return {
                    content: this.target.target.alertingProfile?.name ?? "",
                    link:
                        "/" +
                        this.constants.urls.configuration.eventsManagement +
                        "/" +
                        this.target.target.alertingProfile?.id
                };
            case this.translatedNames.LOCATION:
                return {
                    content: (this.target.target.location?.ip || this.target.target.location?.address)?.display_name
                        ? "-"
                        : "",
                    object: this.target.target
                };
            case this.translatedNames.NETWORK:
                return {
                    content: this.target.target.location?.ip
                        ? this.sharedService.getNetwork(this.target.target.location)
                        : "",
                    maxWidth: 240
                };
            //
            case this.translatedNames.ACTIVE_BROADCASTER:
                return {
                    content:
                        this.target._frontData?.active_broadcaster && this.target._frontData?.active_broadcaster.id
                            ? "-"
                            : "",
                    object: this.target._frontData?.active_broadcaster,
                    cluster_id:
                        this.target.target.deliveryChannel?.broadcaster_cluster_id ||
                        this.target.target?.activeBroadcasterObjects?.cluster_id
                };
            case this.translatedNames.AWS_MEDIACONNECT_FLOW:
                return {
                    content: this.target.target.mediaconnect_flow_id ? "-" : "",
                    object: this.target.target.mediaconnectFlow
                };
            case this.translatedNames.ZEC:
                return {
                    content: this.target.pull && this.target.target["zec_id"] ? "-" : "",
                    object: this.target.target["zec"]
                };
            case this.translatedNames.RECEIVER:
                return {
                    content: this.target.pull && this.target.target["receiver_id"] ? "-" : "",
                    object: this.target.target["receiver"],
                    output: this.target.target["output_name"] ?? ""
                };
            case this.translatedNames.BROADCASTER:
                return {
                    content: this.target.pull && this.target.target["broadcaster_id"] ? "-" : "",
                    object: this.target.target["broadcaster"],
                    output: this.target.target["output_name"] ?? ""
                };
            case this.translatedNames.CHANNEL:
                return {
                    content:
                        (this.target.adaptive && this.target.target.adaptive_channel_id !== null) ||
                        ((this.target.pull || this.target.srt || this.target.push) &&
                            this.target.target.delivery_channel_id !== null &&
                            this.target.target.mediaconnect_flow_id == null) ||
                        ((this.target.udp_rtp || this.target.rist) &&
                            this.target.target.delivery_channel_id !== null) ||
                        ((this.target.rtmp || this.target.medialive_http) &&
                            this.target.target.medialive_channel_id == null &&
                            this.target.target.delivery_channel_id !== null)
                            ? "-"
                            : "",
                    object:
                        this.target.adaptive && this.target.target.adaptive_channel_id !== null
                            ? this.target.target.adaptiveChannel
                            : ((this.target.pull || this.target.srt || this.target.push) &&
                                  this.target.target.delivery_channel_id !== null &&
                                  this.target.target.mediaconnect_flow_id == null) ||
                              ((this.target.udp_rtp || this.target.rist) &&
                                  this.target.target.delivery_channel_id !== null) ||
                              ((this.target.rtmp || this.target.medialive_http) &&
                                  this.target.target.medialive_channel_id == null &&
                                  this.target.target.delivery_channel_id != null)
                            ? this.target.target.deliveryChannel
                            : ""
                };
            //
            case this.translatedNames.PID_MAPPING:
                return {
                    content: this.target.target["pid_mapping"]?.name ?? "",
                    link:
                        "/" +
                        Constants.urls.transformation.pid_mapping_profiles +
                        "/" +
                        this.target.target["pid_mapping"]?.name
                };
            case this.translatedNames.ENCAPSULATION:
                return {
                    content:
                        this.target.adaptive && this.target.target["encapsulation"]
                            ? this.translate.instant(this.target.target["encapsulation"].toUpperCase())
                            : ""
                };
            case this.translatedNames.REGION:
                return {
                    content:
                        this.target.adaptive &&
                        (this.target.target.type === "s3" || this.target.target.type === "mediastore")
                            ? this.target.target["aws_region"]
                            : ""
                };
            case this.translatedNames.ACCESS_KEY_ID:
                return {
                    content:
                        this.target.adaptive &&
                        (this.target.target.type === "s3" || this.target.target.type === "mediastore")
                            ? this.target.target["aws_access_key_id"]
                            : ""
                };
            case this.translatedNames.PLAYBACK_URL:
                return {
                    content:
                        this.target.adaptive &&
                        this.target.target["playback_url"] &&
                        this.target.target["playback_url"] !== ""
                            ? this.target.target["playback_url"]
                            : ""
                };
            case this.translatedNames.UPLOAD_DVR_PLAYLIST:
                return {
                    content: !this.target.adaptive
                        ? ""
                        : this.target.target["record_dvr"]
                        ? this.translate.instant("YES")
                        : this.translate.instant("NO")
                };
            case this.translatedNames.PROPAGATE_TAGS_TO_AWS:
                return {
                    content: !this.target.adaptive
                        ? ""
                        : this.target.target["propagate_tags"]
                        ? this.translate.instant("YES")
                        : this.translate.instant("NO")
                };
            case this.translatedNames.DELETE_OUTDATED:
                return {
                    content: !this.target.adaptive
                        ? ""
                        : this.target.target["delete_outdated"]
                        ? this.translate.instant("YES")
                        : this.translate.instant("NO")
                };
            case this.translatedNames.IGNORE_CERTIFICATE:
                return {
                    content: !this.target.adaptive
                        ? ""
                        : this.target.target["ignore_certificate"]
                        ? this.translate.instant("YES")
                        : this.translate.instant("NO")
                };
            case this.translatedNames.RECEIVER_NAME_ID:
                return {
                    content:
                        this.target.pull && this.target.target["receiver_name"]
                            ? this.target.target["receiver_name"]
                            : ""
                };
            case this.translatedNames.CLIENT_IP:
                return {
                    content: this.target.pull && this.target.target.status?.ip ? this.target.target.status?.ip : ""
                };
            case this.translatedNames.PASSWORD:
                return {
                    content: this.target.target["password"] ?? "",
                    password: true
                };
            case this.translatedNames.ENCRYPTION_KEY:
                return {
                    content: this.target.target["zixi_encryption_key"] ?? "",
                    password: true
                };
            case this.translatedNames.LATENCY:
                return {
                    content:
                        this.target.pull && !this.target.target.latency
                            ? this.translate.instant("REMOTE_CONFIGURATION")
                            : this.target.target.latency
                            ? this.target.target.latency
                            : "",
                    unit: this.target.target.latency ? "ms" : ""
                };
            case this.translatedNames.APPLICATION:
                return {
                    content: this.target.target?.status?.app ?? ""
                };
            case this.translatedNames.DEVICE:
                return {
                    content: this.target.target?.status?.device ?? ""
                };
            case this.translatedNames.BUILD:
                return {
                    content: this.target.target?.status?.build ?? ""
                };
            case this.translatedNames.PORT:
                return {
                    content: this.target.srt && this.target.target["pull_mode"] !== 0 ? this.target.target["port"] : ""
                };
            case this.translatedNames.LOCAL_NIC_CIDR:
                return {
                    content: this.target.target["bind_cidr"] ?? ""
                };
            case this.translatedNames.TARGET:
                return {
                    content:
                        this.target.srt &&
                        this.target.target["pull_mode"] === 0 &&
                        this.target.target["host"] &&
                        this.target.target["port"]
                            ? this.target.target["host"] + ":" + this.target.target["port"]
                            : (this.target.udp_rtp || this.target.rist) &&
                              this.target.target["host"] &&
                              this.target.target["port"]
                            ? this.target.target["host"] + ":" + this.target.target["port"]
                            : this.target.cdi &&
                              this.target.target["destination_ip"] &&
                              this.target.target["destination_port"]
                            ? this.target.target["destination_ip"] + ":" + this.target.target["destination_port"]
                            : ""
                };
            case this.translatedNames.STREAM_ID:
                return {
                    content:
                        this.target.push && this.target.target["stream_id"]
                            ? this.target.target["stream_id"]
                            : this.target.srt &&
                              this.target.target["pull_mode"] === 0 &&
                              this.target.target["srt_stream_id"]
                            ? this.target.target["srt_stream_id"]
                            : ""
                };
            case this.translatedNames.ENCRYPTION:
                return {
                    content:
                        this.target.srt && this.target.target["encryption"]
                            ? this.target.target["encryption"]
                            : this.target.srt && !this.target.target["encryption"]
                            ? this.translate.instant("DISABLED")
                            : ""
                };
            case this.translatedNames.TARGET_HOST:
                return {
                    content: this.target.target["target"] ?? ""
                };
            case this.translatedNames.ALT_TARGET_HOST:
                return {
                    content: this.target.target["alt_target"] ?? ""
                };
            case this.translatedNames.IGNORE_DTLS_CERTIFICATE:
                return {
                    content:
                        this.target.push &&
                        (this.target.target["ignore_dtls_cert"] === true ||
                            this.target.target["ignore_dtls_cert"] === 1)
                            ? "Yes"
                            : this.target.push &&
                              (this.target.target["ignore_dtls_cert"] === false ||
                                  this.target.target["ignore_dtls_cert"] === 0)
                            ? "No"
                            : ""
                };
            case this.translatedNames.SMPTE_2022_FEC:
                return {
                    content:
                        this.target.target["smpte_2022_fec"] > 0
                            ? this.target.target["smpte_2022_fec"] +
                              "D (" +
                              this.target.target["smpte_2022_fec_rows"] +
                              "x" +
                              this.target.target["smpte_2022_fec_cols"] +
                              ")"
                            : ""
                };
            case this.translatedNames.REMUX:
                return {
                    content: this.target.target["remux_kbps"]
                        ? this.sharedService.getKBPSContent(this.target.target["remux_kbps"])
                        : "",
                    unit: "kbps"
                };
            case this.translatedNames.SMOOTHING:
                return {
                    content: this.target.target["smoothing"] ? this.target.target["smoothing"] : "",
                    unit: "ms"
                };
            case this.translatedNames.TRANSCODING_PROFILE:
                return {
                    content: this.target.target["transcodeProfile"]?.name ?? "",
                    link:
                        "/" +
                        Constants.urls.transformation.transcoding_profiles +
                        "/" +
                        this.target.target["transcodeProfile"]?.name
                };
            case this.translatedNames.STREAM_NAME:
                return {
                    content:
                        this.target.rtmp && this.target.target["stream_name"] ? this.target.target["stream_name"] : ""
                };
            case this.translatedNames.USERNAME:
                return {
                    content: this.target.rtmp && this.target.target["username"] ? this.target.target["username"] : ""
                };
            case this.translatedNames.BITRATE:
                return {
                    content:
                        this.target.rtmp && this.target.target["kbps"]
                            ? this.target.target["kbps"]
                            : this.target.rtmp && !this.target.target["kbps"]
                            ? "Auto Detect"
                            : "",
                    unit: this.target.rtmp && this.target.target["kbps"] ? "kbps" : ""
                };
            case this.translatedNames.ENTITLEMENT_ARN:
                return {
                    content:
                        this.target.type === "entitlement" && this.target.target["entitlement_arn"]
                            ? this.target.target["entitlement_arn"]
                            : ""
                };
            //
            case this.translatedNames.S3_URI:
                return {
                    content:
                        (this.target.target.type === "s3" && this.target.adaptive) ||
                        (this.target.medialive_http && this.target.target["destination"] === "s3")
                            ? this.target.target["ingest_url"]
                            : ""
                };
            case this.translatedNames.MEDIASTORE_URI:
                return {
                    content:
                        (this.target.target.type === "mediastore" && this.target.adaptive) ||
                        (this.target.medialive_http && this.target.target["destination"] === "mediastore")
                            ? this.target.target["ingest_url"]
                            : ""
                };
            case this.translatedNames.HTTP_URI:
                return {
                    content:
                        this.target.medialive_http && this.target.target["destination"] === "http"
                            ? this.target.target["ingest_url"]
                            : ""
                };
            case this.translatedNames.INGEST_URL:
                return {
                    content:
                        this.target.target.type !== "mediastore" &&
                        this.target.target.type !== "s3" &&
                        this.target.adaptive
                            ? this.target.target["ingest_url"]
                            : ""
                };
            default:
                return {
                    content: "",
                    statusClass: ""
                };
        }
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private ts: TargetsService,
        private ss: SourcesService,
        private modalService: ModalService,
        protected sharedService: SharedService,
        protected userService: UsersService,
        private mixpanelService: MixpanelService,
        protected translate: TranslateService,
        private titleService: TitleService,
        protected gs: GraphsService,
        private ngbModal: NgbModal,
        private navigationService: NavigationService,
        private resizeService: ResizeService,
        private changeDetectorRef: ChangeDetectorRef,
        private tagsService: TagsService
    ) {
        super(translate, gs, userService, sharedService);
        this.route.paramMap.subscribe(async params => {
            this.targetId = urlBuilder.decode(params.get("targetId"));
            this.targetName = params.get("name");
            const targetType = params.get("type");

            switch (targetType) {
                case Constants.urls.targetTypes.http:
                    this.targetApiType = TargetApiType.Http;
                    this.tagType = OBJECT_TYPE.HTTP_PUBLISHING_TARGET;
                    break;
                case Constants.urls.targetTypes.pull:
                    this.targetApiType = TargetApiType.Pull;
                    this.tagType = OBJECT_TYPE.ZIXI_PULL_TARGET;
                    break;
                case Constants.urls.targetTypes.push:
                    this.targetApiType = TargetApiType.Push;
                    this.tagType = OBJECT_TYPE.ZIXI_PUSH_TARGET;
                    break;
                case Constants.urls.targetTypes.udp_rtp:
                    this.targetApiType = TargetApiType.UdpRtp;
                    this.tagType = OBJECT_TYPE.UDP_RTP_TARGET;
                    break;
                case Constants.urls.targetTypes.rtmp:
                    this.targetApiType = TargetApiType.Rtmp;
                    this.tagType = OBJECT_TYPE.RTMP_PUSH_TARGET;
                    break;
                case Constants.urls.targetTypes.rist:
                    this.targetApiType = TargetApiType.Rist;
                    this.tagType = OBJECT_TYPE.RIST_TARGET;
                    break;
                case Constants.urls.targetTypes.srt:
                    this.targetApiType = TargetApiType.Srt;
                    this.tagType = OBJECT_TYPE.SRT_TARGET;
                    break;
                case Constants.urls.targetTypes.ndi:
                    this.targetApiType = TargetApiType.Ndi;
                    this.tagType = OBJECT_TYPE.NDI_TARGET;
                    break;
                case Constants.urls.targetTypes.cdi:
                    this.targetApiType = TargetApiType.CDI;
                    this.tagType = "mediaconnect_cdi_targets";
                    break;
                case Constants.urls.targetTypes.jpegxs:
                    this.targetApiType = TargetApiType.JPEGXS;
                    this.tagType = "mediaconnect_jpegxs_targets";
                    break;
                case Constants.urls.targetTypes.medialive_http:
                    this.targetApiType = TargetApiType.MediaLiveHttp;
                    this.tagType = "medialive_http_targets";
                    break;
                case Constants.urls.targetTypes.entitlement:
                    this.targetApiType = TargetApiType.Entitlement;
                    this.tagType = "mediaconnect_entitlement_targets";
                    break;
                case Constants.urls.targetTypes.mediaconnect:
                    this.targetApiType = TargetApiType.Mediaconnect;
                    this.tagType = "mediaconnect";
                    break;
                default:
                    return this.cancel();
            }

            // Resource Tags
            this.sharedService
                .getResourceTagsByType(this.tagType ? this.tagType : "resource")
                .pipe(take(1))
                .subscribe((tags: Tag[]) => {
                    this.resourceTags = tags;
                });

            if (this.targetId) {
                this.target = this.ts.getCachedTarget(this.targetId, this.targetApiType);
                const targetSourceID = this.ts.getTargetSourceId(this.target.target);
                if (targetSourceID) {
                    this.targetSource = await firstValueFrom(this.sourcesService.refreshSource(targetSourceID));
                } else this.targetSource = null;
            }
            if (!this.target) return this.cancel();

            // Set Title
            this.titleService.setTitle("TARGET", "", this.targetName);
            this.updateAllThings();
        });

        /* this.route.queryParams.subscribe(params => {
            setTimeout(() => {
                if (params.f && params.f === "insights") {
                    if (this.targetTabset) this.targetTabset.select("insights-tab");
                    else this.scrollTo("ngb-panel-insights");
                }
            }, 0);
        }); */
    }

    updateAllThings() {
        this.updateWidgetsToRemoveOrAdd();
        this.addOrRemoveFromWidgets();
        this.addOrRemoveFromDetails();
        this.primaryDetails = this.getUpdatesDetailsContent(this.primaryDetails);
        this.secondaryDetails = this.getUpdatesDetailsContent(this.secondaryDetails);
        this.recoveryState = this.getDisasterRecoveryState();
    }

    updateWidgetsToRemoveOrAdd() {
        this.widgetsToRemoveOrAdd = [
            { title: this.translatedNames.STREAM, toHide: this.isTargetDown(this.target) || !this.targetSource },
            {
                title: this.translatedNames.HISTORY,
                toHide: !(
                    this.target.target.mediaconnect_flow_id == null &&
                    this.target.target.medialive_channel_id == null &&
                    !this.target.dynamic &&
                    this.target.type !== "cdi" &&
                    this.target.type !== "jpegxs" &&
                    !this.target.medialive_http
                )
            },
            { title: this.translatedNames.CHANGES, toHide: !this.canEdit(this.target) },
            { title: this.translatedNames.INCIDENTS, toHide: !this.targetBackendType() },
            {
                title: this.translatedNames.TRANSCODING_PROFILES,
                toHide: !(this.target.target as any)?.transcodingProfiles?.length
            },
            { title: this.translatedNames.CHILD_TARGETS, toHide: !this.target.dynamic }
        ];
    }

    protected addOrRemoveFromDetails() {
        const thumbnailIndex = this.primaryDetails.findIndex(
            details => details.title === this.translatedNames.THUMBNAIL
        );
        if (!this.targetSource || this.isTargetDown(this.target)) this.primaryDetails[thumbnailIndex].isHidden = true;
        else if (thumbnailIndex !== -1) this.primaryDetails[thumbnailIndex].isHidden = false;

        this.primaryDetails = [...this.primaryDetails];
    }

    async refresh() {
        this.isRefreshing = true;
        await firstValueFrom(this.ts.refreshTarget(this.targetApiType, this.target.objId, true));
        this.isRefreshing = false;
    }

    traceroute() {
        this.ts.targetTracerouteModal(this.target);
    }

    isTargetDown(target: AnyTarget) {
        if (!target.target?.status?.stats?.net_bitrate) return true;
        return false;
    }

    async ngOnInit() {
        // local storage
        if (localStorage.getItem("isInterfaceLocked"))
            this.isLocked = localStorage.getItem("isInterfaceLocked") === "true" ? true : false;

        if (localStorage.getItem("isTargetMultiselect"))
            this.isMultiSelect = localStorage.getItem("isTargetMultiselect") === "true" ? true : false;

        this.navSubscription = this.navigationService.toggle$.subscribe(() =>
            setTimeout(() => this.getDetailsAreaHeights(), 0)
        );
        this.splitterSubscription = this.sharedService.splitterResized$.subscribe(() => this.getDetailsAreaHeights());
        this.resizeSubscription = this.resizeService.getCurrentSize.subscribe(x => {
            this.isMobile = x < 4;
        });

        this.targetsSubscription = this.ts.targets.subscribe(async targets => {
            this.target = targets.find(
                (t: AnyTarget) => t.target.name === this.targetName && t.apiType === this.targetApiType
            );

            if (this.target && this.target.target.hasFullDetails) {
                const targetSourceID = this.ts.getTargetSourceId(this.target.target);
                if (targetSourceID) {
                    this.targetSource = await firstValueFrom(this.sourcesService.refreshSource(targetSourceID));
                } else this.targetSource = null;

                this.targetDashboardLink = this.gs.custom(this.gs.target(this.target));
                this.updateAllThings();
                this.loadingDetails = false;
            }
        });

        this.userService.userPermissions.pipe(take(1)).subscribe(perm => {
            this.userPermissions = perm;
            this.updateWidgetsToRemoveOrAdd();
            this.addOrRemoveFromWidgets();
        });

        // Start Thumbnail Refresh
        this.startThumbnailRefresh();

        // set initial layout
        await this.revertLayoutChanges();
        this.loadingDetails = false;
        this.isWidgetFullyLoaded = true;
        setTimeout(() => this.getDetailsAreaHeights(), 0);
        this.recoveryState = this.getDisasterRecoveryState();
    }

    ngOnDestroy() {
        this.targetsSubscription?.unsubscribe();
        this.navSubscription?.unsubscribe();
        this.splitterSubscription?.unsubscribe();
        this.resizeSubscription?.unsubscribe();
        this.stopThumbnailRefresh();
    }

    ngAfterViewInit(): void {
        this.loadingDetails = false;
        this.changeDetectorRef.detectChanges();
        this.getDetailsAreaHeights();
    }

    startThumbnailRefresh() {
        this.refreshThumbnailSubscription = interval(8000).subscribe(() => {
            this.refreshThumbnail();
        });
    }

    stopThumbnailRefresh() {
        this.refreshThumbnailSubscription.unsubscribe();
    }

    refreshThumbnail() {
        if (!this.targetSource) return;
        this.ss.refreshSourceThumbnail(this.targetSource.id);
    }

    cancel() {
        this.gotoTargets();
    }

    gotoTargets() {
        this.router.navigate([Constants.urls.targets]);
    }

    async deleteTarget() {
        await this.modalService.confirm(
            "DELETE",
            "TARGET",
            async () => {
                const id = this.target.id;
                const result = await this.ts.deleteTarget(this.target.target);
                if (result) {
                    this.mixpanelService.sendEvent("delete target", { id });
                    this.gotoTargets();
                } else {
                    return false;
                }
            },
            this.target.target.name,
            { warning: this.target.dynamic ? "DYNAMIC_PULL_DELETE_WARNING" : null }
        );
    }

    editTarget(name: string) {
        return urlBuilder.getTargetActionUrl(this.targetId, this.target.apiType, name, "edit");
    }

    cloneTarget(name: string) {
        return this.router.navigate(urlBuilder.getTargetActionUrl(this.targetId, this.target.apiType, name, "clone"));
    }

    config() {
        this.ts.targetConfigHelp(this.target);
    }

    toggleMute() {
        this.mixpanelService.sendEvent((this.target.target.active_mute ? "unmute" : "mute") + " target");
        this.ts.updateTarget(this.target.target, {
            muted: !this.target.target.active_mute,
            muted_until: null,
            flapping: null
        });
    }

    async muteUntil(date: Date) {
        this.mixpanelService.sendEvent("mute " + "target" + " until: " + date.toISOString());
        await this.ts.updateTarget(this.target.target, {
            muted: true,
            muted_until: date.toISOString(),
            flapping: null
        });
    }

    async toggle() {
        let action = "";
        const model = {
            is_enabled: this.target.target.is_enabled
        };
        if (this.target.target.is_enabled === 1) {
            action = "DISABLE";
            model.is_enabled = 0;
        } else {
            action = "ENABLE";
            model.is_enabled = 1;
        }

        await this.modalService.confirm(
            action,
            "TARGET",
            async () => {
                const result = await this.ts.updateTarget(this.target.target, model);
                if (result) {
                    this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " target", {
                        id: this.target.id
                    });
                    return true;
                } else {
                    return false;
                }
            },
            this.target.target.name
        );
    }

    canEdit(target: AnyTarget) {
        if (!target) return false;
        return this.sharedService.canEditZixiObject(target.target, this.resourceTags, this.userPermissions);
    }

    async switchChannel() {
        const result = await this.modalService.switchChannel(
            [this.target],
            this.target.adaptive ? "adaptive" : "delivery"
        );
        if (result) {
            this.mixpanelService.sendEvent("switch channel", {
                id: this.target.id
            });
            this.ts.refreshTarget(this.targetApiType, this.target.objId, true);
        }
    }

    public refreshTarget = () => {
        this.ts.refreshTarget(this.target.apiType, this.target.target.id, true);
    };

    refreshTargetPromise() {
        return firstValueFrom(this.ts.refreshTarget(this.target.apiType, this.target.target.id, true));
    }

    targetBackendType() {
        return this.target.apiType === "push"
            ? "zixi_push"
            : this.target.apiType === "pull"
            ? "zixi_pull"
            : this.target.apiType === "rtmp"
            ? "rtmp_push"
            : this.target.apiType === "rist"
            ? "rist"
            : this.target.apiType === "udp_rtp"
            ? "udp_rtp"
            : this.target.apiType === "http"
            ? "publishing_target"
            : this.target.apiType === "medialive_http"
            ? "medialive_http_targets"
            : this.target.apiType === "srt"
            ? "srt_targets"
            : this.target.apiType === "ndi"
            ? "ndi_targets"
            : this.target.apiType === "cdi"
            ? "mediaconnect_cdi_targets"
            : this.target.apiType === "jpegxs"
            ? "mediaconnect_jpegxs_targets"
            : this.target.apiType === "entitlement"
            ? "mediaconnect_entitlement_targets"
            : null;
    }

    getDisasterRecoveryState() {
        const target = this.target.target;
        return this.ts.getDisasterRecoveryState(target);
    }

    async goToDR() {
        const modal = this.ngbModal.open(DisasterRecoveryDialogComponent, {
            backdrop: "static",
            centered: true,
            size: "lg"
        });
        modal.componentInstance.objects = [{ id: this.target.target.id, type: this.target.type }];

        modal.result.then(() => {
            this.refreshTarget();
            this.recoveryState = this.getDisasterRecoveryState();
        });
    }
}
