<content-loading-animation *ngIf="loading"></content-loading-animation>
<form id="form" #form="ngForm" (ngSubmit)="form.valid && !nameControl.invalid && !tagsControl.invalid && onSubmit(form)" *ngIf="!loading">
    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div class="title">
                    <h1 *ngIf="!isClone && !isEdit">{{ "CREATE_NEW_SRT_TARGET" | translate }}</h1>
                    <h1 *ngIf="isClone">{{ "CLONE_SRT_TARGET" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_SRT_TARGET" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area">
        <div class="container-fluid">
            <!-- SRT -->
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <section id="info">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INFO' | translate }}">
                                {{ "INFO" | translate }}
                            </h3>
                        </div>

                        <!-- Name -->
                        <app-input-generic-field
                            zmid="form_name"
                            [isRestartRequiredTip]="isEdit"
                            [duplicateName]="targetNames"
                            [formControl]="nameControl"
                            [textFieldName]="'NAME' | translate"
                            [disabled]="saving"
                            [isParentFormSubmitted]="form.submitted"
                            type="text"
                        ></app-input-generic-field>

                        <!-- Access Tags -->
                        <zx-access-tags-select
                            zmid="form_access-tags"
                            [formControl]="tagsControl"
                            [label]="'TAGS' | translate"
                            name="resource_tag_ids"
                            type="delivery"
                            [isParentFormSubmitted]="form.submitted"
                        ></zx-access-tags-select>
                        <!-- Alerting Profile -->
                        <div class="form-group">
                            <label for="alertingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }">{{
                                "ALERTING_PROFILE" | translate
                            }}</label>
                            <zx-alerting-profile-select
                                name="alertingProfile"
                                [(model)]="target.alertingProfile"
                                [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }"
                            ></zx-alerting-profile-select>
                        </div>
                        <!-- Location -->
                        <div class="form-group">
                            <label for="location_text"
                                >{{ "LOCATION" | translate
                                }}<fa-icon icon="info-circle" [ngbTooltip]="LocationContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                                <ng-template #LocationContent>{{ "TOOLTIP.TARGET_LOCATION" | translate }}</ng-template></label
                            >
                            <zx-location-search [(model)]="target.location.address"></zx-location-search>
                        </div>
                    </section>

                    <section id="config">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'CONFIGURATION' | translate }}">
                                {{ "CONFIGURATION" | translate }}
                            </h3>
                        </div>

                        <!-- Channel Type -->
                        <fieldset class="form-group">
                            <legend for="channel_type">
                                {{ "CHANNEL_TYPE" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </legend>
                            <mat-button-toggle-group name="channel_type" aria-label="channel type" [(ngModel)]="channelType" (change)="resetChannelId()">
                                <mat-button-toggle value="passthrough_channel" [disabled]="isEdit">{{ "ZM_CHANNEL" | translate }}</mat-button-toggle>
                                <mat-button-toggle value="mediaconnect_flow" [disabled]="isEdit">{{ "AWS_MEDIACONNECT_FLOW" | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </fieldset>

                        <!-- Pass-through Channel Select -->
                        <div class="form-group" *ngIf="channelType === 'passthrough_channel'">
                            <label for="delivery_channel_select">
                                {{ "CHANNEL" | translate
                                }}<fa-icon icon="info-circle" [ngbTooltip]="COContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                ><ng-template #COContent
                                    ><p class="mb-0">
                                        {{ "TOOLTIP.TARGET_CHANNEL_OPTIONAL" | translate }}
                                    </p></ng-template
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </label>
                            <zx-delivery-channel-select
                                id="delivery_channel_select"
                                name="delivery_channel_select"
                                [(model)]="selectedChannelID"
                                [clearable]="true"
                                [failover]="true"
                            ></zx-delivery-channel-select>
                        </div>

                        <!-- Source preference -->
                        <div class="form-group" *ngIf="channelType === 'passthrough_channel'">
                            <label for="source_preference">
                                {{ "SOURCE_PREFERENCE" | translate }}
                            </label>
                            <zx-delivery-channel-source-select
                                id="preferred_source"
                                name="preferred_source"
                                [(channel_id)]="selectedChannelID"
                                [(model)]="target.preferred_source"
                                [disabled]="!selectedChannelID"
                            >
                            </zx-delivery-channel-source-select>
                        </div>

                        <!-- MediaConnect Flow Select -->
                        <div class="form-group" *ngIf="channelType === 'mediaconnect_flow'">
                            <label for="delivery_channel_select">
                                {{ "AWS_MEDIACONNECT_FLOW" | translate
                                }}<fa-icon icon="info-circle" [ngbTooltip]="COContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                ><ng-template #COContent
                                    ><p class="mb-0">
                                        {{ "TOOLTIP.TARGET_CHANNEL_OPTIONAL" | translate }}
                                    </p></ng-template
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </label>
                            <zx-mediaconnect-flow-select
                                id="mediaconnect_flow_select"
                                name="mediaconnect_flow_select"
                                [(model)]="selectedChannelID"
                                [clearable]="true"
                                (modelChange)="AWSflowSelected()"
                            ></zx-mediaconnect-flow-select>
                        </div>

                        <!-- VPC on flow selection -->
                        <div *ngIf="vpcNamesOnFlow && selectedChannelID && channelType === 'mediaconnect_flow'" class="form-group">
                            <label for="aws_flow_vpc_id"> {{ "VPC" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon> </label>
                            <ng-select
                                id="aws_flow_vpc_id"
                                name="aws_flow_vpc_id"
                                [items]="vpcNamesOnFlow"
                                [clearable]="true"
                                placeholder="{{ 'SELECT' | translate }} {{ 'VPC_INTERFACE_NAME' | translate }}"
                                [(ngModel)]="selectedFlowVPCName"
                                #awsFlowVPCSelection="ngModel"
                            >
                            </ng-select>
                        </div>

                        <!-- Type -->
                        <fieldset class="form-group">
                            <legend for="type">
                                {{ "TYPE" | translate }}
                                <fa-icon icon="lock" size="sm"></fa-icon>
                            </legend>
                            <mat-button-toggle-group name="type" aria-label="type" [(ngModel)]="target.pull_mode">
                                <mat-button-toggle [value]="0" [disabled]="channelType === 'mediaconnect_flow'" [disabled]="isEdit"
                                    >{{ "PUSH" | translate }} ({{ "CALLER" | translate }})</mat-button-toggle
                                >
                                <mat-button-toggle [value]="1" [disabled]="channelType === 'mediaconnect_flow'" [disabled]="isEdit"
                                    >{{ "PULL" | translate }} ({{ "LISTENER" | translate }})</mat-button-toggle
                                >
                            </mat-button-toggle-group>
                        </fieldset>

                        <!-- Single Output -->
                        <div class="form-group" *ngIf="target.pull_mode === 0">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="single_output" name="single_output" [(ngModel)]="target.single_output" />
                                <label class="form-check-label" for="single_output">{{ "SINGLE_OUTPUT" | translate }}</label>
                            </div>
                            <small>{{ "SINGLE_OUTPUT_NOTE" | translate }}</small>
                        </div>

                        <!-- Host, Port, and CIDR -->
                        <div class="row mb-3">
                            <div class="col-6" *ngIf="target.pull_mode === 0">
                                <label for="host" [ngClass]="{ 'is-invalid': form.submitted && host.errors }"
                                    >{{ "HOST" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                    ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                                <input
                                    type="text"
                                    id="host"
                                    name="host"
                                    placeholder="{{ 'HOST' | translate }}"
                                    class="form-control"
                                    [ngClass]="{ 'is-invalid': form.submitted && host.errors }"
                                    [(ngModel)]="target.host"
                                    required
                                    pattern="^([a-zA-Z0-9\-\.]+)(:([0-9]+))?$"
                                    #host="ngModel"
                                />
                                <div *ngIf="host.invalid" class="invalid-feedback">
                                    <div *ngIf="host.errors.required">{{ "HOST" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                </div>
                            </div>
                            <div class="col-6">
                                <label for="port" [ngClass]="{ 'is-invalid': form.submitted && port.errors }"
                                    >{{ "PORT" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                    ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                                <input
                                    type="number"
                                    id="port"
                                    name="port"
                                    placeholder="{{ 'PORT' | translate }}"
                                    class="form-control"
                                    [ngClass]="{ 'is-invalid': form.submitted && port.errors }"
                                    [(ngModel)]="target.port"
                                    #port="ngModel"
                                    required
                                    [min]="0"
                                    [max]="65535"
                                />
                                <div *ngIf="port.invalid" class="invalid-feedback">
                                    <div *ngIf="port.errors.required">{{ "PORT" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                    <div *ngIf="port.errors.min || port.errors.max">
                                        {{ "PORT" | translate }} {{ "MUST_BE_BETWEEN_0_AND_65535" | translate }}.
                                    </div>
                                </div>
                            </div>
                            <div class="col-6" *ngIf="target.pull_mode === 1">
                                <label
                                    *ngIf="channelType === 'passthrough_channel'"
                                    for="bind_cidr"
                                    [ngClass]="{ 'is-invalid': form.submitted && bind_cidr.errors }"
                                    >{{ "BIND_TO_LOCAL_NIC_BY_CIDR" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                                <label
                                    *ngIf="channelType === 'mediaconnect_flow'"
                                    for="bind_cidr"
                                    [ngClass]="{ 'is-invalid': form.submitted && bind_cidr.errors }"
                                    >{{ "CIDR_ALLOW_LIST" | translate
                                    }}<fa-icon *ngIf="channelType === 'mediaconnect_flow' && !selectedFlowVPCName" icon="asterisk" size="xs"></fa-icon>
                                    <fa-icon icon="info-circle" [ngbTooltip]="CIDRTContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                                    <ng-template #CIDRTContent
                                        ><p class="mb-0">
                                            {{ "TOOLTIP.MEDIACONNECT_WHITELIST" | translate }}
                                        </p>
                                    </ng-template>
                                    <app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                                <input
                                    type="text"
                                    id="bind_cidr"
                                    name="bind_cidr"
                                    placeholder="e.g. 192.168.15.10/32"
                                    class="form-control"
                                    [ngClass]="{ 'is-invalid': form.submitted && bind_cidr.errors }"
                                    [(ngModel)]="target.bind_cidr"
                                    pattern="^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/(3[0-2]|[12]?[0-9]))$"
                                    #bind_cidr="ngModel"
                                    [required]="channelType === 'mediaconnect_flow' && !selectedFlowVPCName"
                                    [disabled]="channelType === 'mediaconnect_flow' && selectedFlowVPCName"
                                />
                                <div *ngIf="channelType === 'passthrough_channel' && bind_cidr.invalid" class="invalid-feedback">
                                    <div *ngIf="bind_cidr.errors.pattern">{{ "CIDR" | translate }} {{ "INVALID" | translate }}.</div>
                                </div>
                                <div *ngIf="channelType === 'mediaconnect_flow' && bind_cidr.invalid" class="invalid-feedback">
                                    <div *ngIf="bind_cidr.errors.required">{{ "CIDR_ALLOW_LIST" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                    <div *ngIf="bind_cidr.errors.pattern">{{ "INVALID_CIDR" | translate }}.</div>
                                </div>
                            </div>
                        </div>

                        <!-- Stream ID -->
                        <app-input-generic-field
                            zmid="form_srt_stream_id"
                            [formControl]="srtStreamIdControl"
                            [textFieldName]="'STREAM_ID' | translate"
                            [disabled]="saving"
                            [isParentFormSubmitted]="form.submitted"
                            type="text"
                            *ngIf="target.pull_mode === 0"
                        ></app-input-generic-field>

                        <!-- Latency -->
                        <div class="form-group">
                            <label for="latency" [ngClass]="{ 'is-invalid': form.submitted && latency.errors }">
                                {{ "LATENCY" | translate }} [ms]<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </label>
                            <input
                                type="number"
                                id="latency"
                                name="latency"
                                placeholder="{{ 'LATENCY' | translate }}"
                                class="form-control"
                                min="0"
                                pattern="^\d+$"
                                [(ngModel)]="target.latency"
                                [ngClass]="{ 'is-invalid': form.submitted && latency.errors }"
                                required
                                #latency="ngModel"
                            />
                            <div *ngIf="latency.invalid" class="invalid-feedback">
                                <div *ngIf="latency.errors.required">{{ "LATENCY" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                <div *ngIf="latency.errors.pattern">
                                    {{ "LATENCY" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate | lowercase }}.
                                </div>
                            </div>
                        </div>

                        <!-- Password -->
                        <div class="form-group" *ngIf="channelType === 'passthrough_channel'">
                            <label for="password" [ngClass]="{ 'is-invalid': form.submitted && password.errors }">
                                {{ "PASSWORD" | translate }}
                                <app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </label>
                            <div class="input-group">
                                <input
                                    type="{{ show_password ? 'text' : 'password' }}"
                                    autocomplete="cc-csc"
                                    id="password"
                                    name="password"
                                    placeholder="{{ 'PASSWORD' | translate }}"
                                    class="form-control"
                                    [ngClass]="{ 'is-invalid': form.submitted && password.errors }"
                                    [(ngModel)]="target.password"
                                    #password="ngModel"
                                    minlength="10"
                                    maxlength="80"
                                />
                                <button
                                    class="btn btn-outline-primary"
                                    (click)="show_password = !show_password"
                                    type="button"
                                    title="{{ 'SHOW_PASSWORD' | translate }}"
                                >
                                    <fa-icon icon="eye" *ngIf="!show_password"></fa-icon>
                                    <fa-icon icon="eye-slash" *ngIf="show_password"></fa-icon>
                                </button>
                                <button
                                    class="btn btn-outline-primary"
                                    (click)="copyPassword(target.password)"
                                    type="button"
                                    title="{{ 'COPY_PASSWORD' | translate }}"
                                >
                                    <fa-icon icon="clipboard"></fa-icon>
                                </button>
                            </div>
                            <div *ngIf="password.invalid" class="invalid-feedback">
                                <div *ngIf="password.errors.minlength || password.errors.maxlength">{{ "SRT_PASSWORD_LENGTH" | translate }}.</div>
                            </div>
                        </div>
                        <!-- ZM-5875 - removed for now since not actually supported on the BX -->
                        <!-- Remux Bitrate -->
                        <!--<div class="form-group">
                            <label for="remux_kbps" [ngClass]="{ 'is-invalid': form.submitted && remux_kbps.errors }"
                                >{{ "REMUX_BITRATE" | translate }} [kbps]<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <input
                                type="number"
                                id="remux_kbps"
                                name="remux_kbps"
                                placeholder="{{ 'REMUX_BITRATE' | translate }} [kbps]"
                                class="form-control"
                                pattern="^\d+$"
                                [(ngModel)]="target.remux_kbps"
                                [ngClass]="{ 'is-invalid': form.submitted && remux_kbps.errors }"
                                #remux_kbps="ngModel"
                            />
                            <div *ngIf="remux_kbps.invalid" class="invalid-feedback">
                                <div *ngIf="remux_kbps.errors.pattern">{{ "REMUX_BITRATE" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate }}.</div>
                            </div>
                        </div>-->
                        <!-- Remux Mode -->
                        <!--<fieldset class="form-group">
                            <legend for="remux_mode">
                                {{ "REMUX_MODE" | translate }}
                            </legend>
                            <mat-button-toggle-group name="remux_mode" aria-label="remux mode" [(ngModel)]="target.remux_mode">
                                <mat-button-toggle value="pad" [disabled]="!target.remux_kbps">{{ "PAD" | translate }}</mat-button-toggle>
                                <mat-button-toggle value="remux" [disabled]="!target.remux_kbps">{{ "REMUX" | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </fieldset> -->
                        <!-- PID Mapping Profile -->
                        <div class="form-group" *ngIf="channelType === 'passthrough_channel'">
                            <label for="pidMappingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.pidMappingProfile?.errors }"
                                >{{ "PID_MAPPING_PROFILE" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <zx-pid-mapping-profile-select
                                name="pidMappingProfile"
                                [(model)]="pid_mapping_profile_id"
                                [ngClass]="{ 'is-invalid': form.submitted && form.controls.pidMappingProfile?.errors }"
                            ></zx-pid-mapping-profile-select>
                            <div *ngIf="form.controls.pidMappingProfile?.invalid" class="invalid-feedback">
                                <div *ngIf="form.controls.pidMappingProfile?.errors">{{ "PID_MAPPING_PROFILE" | translate }} {{ "ERROR" | translate }}.</div>
                            </div>
                        </div>
                        <!-- Encryption -->
                        <fieldset class="form-group" *ngIf="channelType === 'passthrough_channel'">
                            <legend for="encryption">
                                {{ "ENCRYPTION" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </legend>
                            <mat-button-toggle-group name="encryption" aria-label="encryption" [(ngModel)]="target.encryption">
                                <mat-button-toggle value="">{{ "DISABLED" | translate }}</mat-button-toggle>
                                <mat-button-toggle value="aes128">{{ "AES_128" | translate }}</mat-button-toggle>
                                <mat-button-toggle value="aes192">{{ "AES_192" | translate }}</mat-button-toggle>
                                <mat-button-toggle value="aes256">{{ "AES_256" | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </fieldset>
                        <!-- Prefer Private Broadcaster IP -->
                        <div class="form-group" *ngIf="target.pull_mode === 1 && channelType === 'passthrough_channel'">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="use_private_ip" name="use_private_ip" [(ngModel)]="target.use_private_ip" />
                                <label class="form-check-label" for="use_private_ip"
                                    >{{ "PREFER_PRIVATE_BROADCASTER_IP" | translate
                                    }}<fa-icon
                                        *ngIf="mode === 'monitor'"
                                        icon="info-circle"
                                        [ngbTooltip]="TRContent"
                                        triggers="hover click"
                                        [closeDelay]="500"
                                    ></fa-icon
                                    ><fa-icon
                                        *ngIf="isEdit"
                                        icon="redo"
                                        size="sm"
                                        [ngbTooltip]="RestartContent"
                                        triggers="hover click"
                                        [closeDelay]="500"
                                    ></fa-icon
                                    ><ng-template #RestartContent>{{ "RESTART_REQUIRED" | translate }}</ng-template></label
                                >
                            </div>
                        </div>
                    </section>
                    <!-- State -->
                    <section id="state" *ngIf="!isEdit">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INITIAL_STATE' | translate }}">
                                {{ "INITIAL_STATE" | translate }}
                            </h3>
                        </div>
                        <!-- Enabled -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="is_enabled" name="is_enabled" [(ngModel)]="startDisabled" />
                                <label class="form-check-label" for="is_enabled">{{ "DISABLED" | translate }}</label>
                            </div>
                        </div>
                        <!-- Muted -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="muted" name="muted" [(ngModel)]="target.muted" />
                                <label class="form-check-label" for="muted">{{ "MUTED" | translate }}</label>
                            </div>
                        </div>
                    </section>

                    <app-advanced-section-form
                        [showBilling]="true"
                        [showExternalID]="true"
                        [isTarget]="true"
                        [model]="target"
                        [form]="form"
                        [isEdit]="isEdit"
                    ></app-advanced-section-form>

                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <hr class="mt-0" />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary float-start" (click)="back()" *ngIf="!isEdit && !isClone" [disabled]="saving">
                            <fa-icon icon="chevron-left" size="sm"></fa-icon>
                            {{ "BACK" | translate }}
                        </button>
                        <button type="button" class="btn btn-outline-secondary" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary ms-2"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && form.invalid }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
