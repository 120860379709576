import { Component, Input, ViewChild } from "@angular/core";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { Source } from "src/app/models/shared";

@Component({
    selector: "app-live-event-sources-status-column",
    templateUrl: "./live-event-sources-status-column.component.html",
    styleUrls: ["./live-event-sources-status-column.component.scss"]
})
export class LiveEventSourcesStatusColumnComponent {
    @Input() sources: Source[] = [];
    @ViewChild("popover") popover: NgbPopover;
    private isMouseInsidePopoverContent = false;

    // Handles mouse leaving the button
    onButtonMouseLeave() {
        setTimeout(() => {
            if (!this.isMouseInsidePopoverContent) {
                this.popover.close();
            }
        }, 500); // Slight delay to check mouse position
    }

    // Handles mouse leaving the popover content
    onPopoverMouseLeave() {
        this.isMouseInsidePopoverContent = false;
        this.popover.close();
    }

    // Handles mouse entering the popover content
    onPopoverMouseEnter() {
        this.isMouseInsidePopoverContent = true;
    }
}
