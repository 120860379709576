<content-loading-animation *ngIf="loading"></content-loading-animation>
<form id="form" novalidate #form="ngForm" (ngSubmit)="form.valid && !nameControl.invalid && !tagsControl.invalid && onSubmit(form)" *ngIf="!loading">
    <app-form-title [titles]="['MONITOR_ONLY', 'SOURCE']" [isEdit]="isEdit" [isClone]="isClone"></app-form-title>
    <div class="content-area">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <app-form-section-title [title]="'INFO' | translate"></app-form-section-title>
                    <!-- Name -->
                    <div>
                        <app-input-generic-field
                            zmid="form_name"
                            duplicateName="sourceNames"
                            [formControl]="nameControl"
                            [textFieldName]="'NAME' | translate"
                            [disabled]="isEdit || saving || true"
                            [isParentFormSubmitted]="form.submitted"
                            type="text"
                            underLabel="Monitor only sources inherit their name from the monitored input"
                        ></app-input-generic-field>
                    </div>

                    <!-- Access Tags -->
                    <zx-access-tags-select
                        zmid="form_access-tags"
                        [formControl]="tagsControl"
                        [label]="'TAGS' | translate"
                        name="resource_tag_ids"
                        [isParentFormSubmitted]="form.submitted"
                    ></zx-access-tags-select>

                    <!-- Alerting Profile -->
                    <div class="form-group">
                        <label for="alertingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }">{{
                            "ALERTING_PROFILE" | translate
                        }}</label>
                        <zx-alerting-profile-select
                            name="alertingProfile"
                            [(model)]="source.alertingProfile"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }"
                        ></zx-alerting-profile-select>
                    </div>
                    <!-- Location -->
                    <div class="form-group">
                        <label for="location_text"
                            >{{ "LOCATION" | translate
                            }}<fa-icon icon="info-circle" [ngbTooltip]="LocationContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                            <ng-template #LocationContent>{{ "TOOLTIP.SOURCE_LOCATION" | translate }}</ng-template></label
                        >
                        <zx-location-search [(model)]="source.location.address"></zx-location-search>
                    </div>

                    <div class="form-section-title">
                        <h3 class="section-title" title="{{ 'INPUT' | translate }}">
                            {{ "INPUT" | translate }}
                        </h3>
                    </div>

                    <!-- Broadcaster -->
                    <div class="form-group" *ngIf="!isEdit">
                        <label for="broadcaster_id" [ngClass]="{ 'is-invalid': form.submitted && form.controls.broadcaster_id?.errors }"
                            >{{ "BROADCASTER" | translate }}<fa-icon *ngIf="!isEdit" icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <zx-broadcaster-select
                            name="broadcaster_id"
                            [(model)]="source.broadcaster_id"
                            required="true"
                            (modelChange)="getBroadcasterDetails(source.broadcaster_id, true)"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.broadcaster_id?.errors }"
                        ></zx-broadcaster-select>
                        <div *ngIf="form.controls.broadcaster_id?.invalid" class="invalid-feedback">
                            <div *ngIf="form.controls.broadcaster_id?.errors.required">{{ "BROADCASTER" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="isEdit">
                        <label for="broadcaster_cluster_id"> {{ "CLUSTER" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon></label>
                        <zx-cluster-select name="broadcaster_cluster_id" [(model)]="source.broadcaster_cluster_id" [disabled]="true"></zx-cluster-select>
                    </div>
                    <!-- Broadcaster Inputs -->
                    <div class="form-group" *ngIf="!isEdit">
                        <label for="broadcaster_inputs" [ngClass]="{ 'is-invalid': form.submitted && selectedBroadcasterInputs.length === 0 }"
                            >{{ "BROADCASTER_INPUTS" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><fa-icon icon="info-circle" [ngbTooltip]="BIContent" triggers="hover click" [closeDelay]="500"></fa-icon
                            ><ng-template #BIContent
                                ><p class="mb-0">
                                    {{ "TOOLTIP.SOURCE_BROADCASTER_INPUTS" | translate }}
                                </p></ng-template
                            ></label
                        >
                        <div class="table-responsive">
                            <table class="table table-sm bordered m-0" [ngClass]="{ 'is-invalid': form.submitted && selectedBroadcasterInputs.length === 0 }">
                                <thead>
                                    <tr>
                                        <th scope="col">{{ "INPUT" | translate }}</th>
                                        <th scope="col">{{ "BITRATE" | translate }}</th>
                                        <th scope="col">{{ "NAME" | translate }}</th>
                                        <th scope="col" class="w-50px text-end">
                                            <fa-icon class="" icon="info-circle" [ngbTooltip]="BIIContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                                            <ng-template #BIIContent>
                                                <p class="mb-0">{{ "TOOLTIP.SOURCE_BROADCASTER_INPUT_INSTRUCTIONS" | translate }}</p>
                                            </ng-template>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody
                                    *ngIf="
                                        source.broadcaster_id &&
                                        !broadcasterDetailsLoading &&
                                        (broadcasterInputs.length > 0 || selectedBroadcasterInputs.length > 0)
                                    "
                                >
                                    <tr *ngFor="let input of selectedBroadcasterInputs">
                                        <td title="{{ input | broadcasterInput }}" class="maxw-100px ellipsis">
                                            <zx-status-icon
                                                class="me-1"
                                                [model]="input | broadcasterInputStatus"
                                                [status]="input | broadcasterInputStatus"
                                            ></zx-status-icon>
                                            <span class="d-inline">{{ input | broadcasterInput }}</span>
                                        </td>
                                        <td>{{ input.bitrate | bitrate }}</td>
                                        <td title="{{ input.id }}" class="maxw-100px ellipsis">{{ input.id }}</td>
                                        <td class="text-end">
                                            <button
                                                type="button"
                                                class="btn btn-sm btn-xs btn-round-xs btn-outline-secondary"
                                                (click)="deselectBroadcasterInput(input)"
                                            >
                                                <fa-icon icon="minus" size="sm"></fa-icon>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">
                                            <div class="form-inline">
                                                <div class="form-group stay-inline">
                                                    <label for="broadcasterInputsFilter">{{ "FILTER" | translate }}:</label>
                                                    <input
                                                        type="text"
                                                        name="broadcasterInputsFilter"
                                                        class="ms-1 form-control form-control-sm form-control-xs maxw-140px"
                                                        [(ngModel)]="broadcasterInputsFilter"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngFor="
                                            let input of getBroadcasterInputs(broadcasterInputs, broadcasterInputsFilter)
                                                | slice : (page - 1) * pageSize : (page - 1) * pageSize + pageSize
                                        "
                                        [ngClass]="{ 'cursor-pointer row-hover': !(selectedBroadcasterInputs.length >= this.MAX_INPUTS) }"
                                        (click)="!(selectedBroadcasterInputs.length >= this.MAX_INPUTS) && selectBroadcasterInput(input)"
                                    >
                                        <td title="{{ input | broadcasterInput }}" class="maxw-100px ellipsis">
                                            <zx-status-icon
                                                class="me-1"
                                                [model]="input | broadcasterInputStatus"
                                                [status]="input | broadcasterInputStatus"
                                            ></zx-status-icon>
                                            <span class="d-inline" title="{{ input | broadcasterInput }}">{{ input | broadcasterInput }}</span>
                                        </td>
                                        <td>{{ input.bitrate | bitrate }}</td>
                                        <td class="maxw-100px ellipsis">
                                            <ngb-highlight title="{{ input.id }}" [result]="input.id" [term]="broadcasterInputsFilter"></ngb-highlight>
                                        </td>
                                        <td class="text-end">
                                            <button
                                                type="button"
                                                class="btn btn-sm btn-xs btn-round-xs btn-outline-primary"
                                                [disabled]="selectedBroadcasterInputs.length >= this.MAX_INPUTS"
                                                (click)="selectBroadcasterInput(input)"
                                            >
                                                <fa-icon icon="plus" size="sm"></fa-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="source.broadcaster_id && broadcasterDetailsLoading">
                                    <tr>
                                        <td colspan="4" class="text-center">{{ "LOADING" | translate }}...</td>
                                    </tr>
                                </tbody>
                                <tbody
                                    *ngIf="
                                        source.broadcaster_id &&
                                        !broadcasterDetailsLoading &&
                                        getBroadcasterInputs(broadcasterInputs, broadcasterInputsFilter).length === 0
                                    "
                                >
                                    <tr>
                                        <td colspan="4" class="text-center">{{ "NO_INPUTS" | translate }}</td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="!source.broadcaster_id">
                                    <tr>
                                        <td colspan="4" class="text-center">{{ "SELECT_BROADCASTER" | translate }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="form.submitted && selectedBroadcasterInputs.length === 0" class="invalid-feedback">
                            {{ "AT_LEAST_1_BROADCASTER_INPUT_IS_REQUIRED" | translate }}.
                        </div>
                        <div class="mt-2" *ngIf="broadcasterInputs && getBroadcasterInputs(broadcasterInputs, broadcasterInputsFilter).length > pageSize">
                            <ngb-pagination
                                class="mb-0"
                                [collectionSize]="getBroadcasterInputs(broadcasterInputs, broadcasterInputsFilter).length"
                                [(page)]="page"
                                [pageSize]="pageSize"
                                [maxSize]="2"
                            ></ngb-pagination>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="isEdit">
                        <label for="input_id">{{ "BROADCASTER_INPUT" | translate }}</label>
                        <input type="text" id="input_id" name="input_id" class="form-control" [(ngModel)]="source.name" [disabled]="true" />
                    </div>

                    <div class="form-section-title">
                        <h3 class="section-title" title="{{ 'CONFIGURATION' | translate }}">
                            {{ "CONFIGURATION" | translate }}
                        </h3>
                    </div>

                    <!-- PID Changes -->
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="monitor_pids_change"
                                name="monitor_pids_change"
                                [(ngModel)]="source.monitor_pids_change"
                            />
                            <label class="form-check-label" for="monitor_pids_change">{{ "ALERT_ON_PID_CHANGES" | translate }}</label>
                        </div>
                    </div>
                    <!-- SCTE-35 Log -->
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="log_scte"
                                name="log_scte"
                                (change)="!source.process_scte_reports ? (source.report_scte_warnings = false) : false"
                                [(ngModel)]="source.process_scte_reports"
                            />
                            <label class="form-check-label" for="log_scte">{{ "LOG_SCTE_35" | translate }}</label>
                        </div>
                        <small>{{ "SCTE_VERSION_AND_CLUSTER_CONFIGURATION_REQUIRED" | translate }}</small>
                    </div>
                    <!-- SCTE-35 Alert -->
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="alert_scte"
                                name="alert_scte"
                                [disabled]="!source.process_scte_reports"
                                [(ngModel)]="source.report_scte_warnings"
                            />
                            <label class="form-check-label" for="alert_scte">{{ "ENABLE_SCTE_35_ALERTS" | translate }}</label>
                        </div>
                        <small>{{ "REQUIRES_SCTE_35_LOG" | translate }}</small>
                    </div>
                    <!-- Common Fields -->
                    <app-source-form-common-fields
                        [form]="form"
                        [isEdit]="isEdit"
                        [source]="source"
                        [allowScte35Insert]="false"
                    ></app-source-form-common-fields>
                    <!-- State -->
                    <section id="state" *ngIf="!isEdit">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INITIAL_STATE' | translate }}">
                                {{ "INITIAL_STATE" | translate }}
                            </h3>
                        </div>
                        <!-- Enabled -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="is_enabled" name="is_enabled" [(ngModel)]="startDisabled" />
                                <label class="form-check-label" for="is_enabled">{{ "DISABLED" | translate }}</label>
                            </div>
                        </div>
                        <!-- Muted -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="muted" name="muted" [(ngModel)]="source.muted" />
                                <label class="form-check-label" for="muted">{{ "MUTED" | translate }}</label>
                            </div>
                        </div>
                    </section>
                    <!-- Advanced -->
                    <app-advanced-section-form
                        [showAutopullBilling]="true"
                        [showAutoMTU]="true"
                        [model]="source"
                        [form]="form"
                        [isEdit]="isEdit"
                        [showFrozenVideoMinimalDuration]="true"
                        [showBlancVideoMinimalDuration]="true"
                    ></app-advanced-section-form>

                    <div *ngIf="savingMultiple">
                        <ul class="list-style-none">
                            <li *ngFor="let newSource of newSources">
                                <fa-icon *ngIf="!newSource._frontData?.finished" icon="clock" class="status-pending"></fa-icon>
                                <fa-icon
                                    *ngIf="newSource._frontData?.finished && !newSource._frontData?.error"
                                    icon="check-circle"
                                    class="status-good"
                                ></fa-icon>
                                <fa-icon *ngIf="newSource._frontData?.error" icon="minus-circle" class="status-error"></fa-icon>
                                {{ newSource.name }}&nbsp;-&nbsp;
                                <span *ngIf="!newSource._frontData?.finished">
                                    <span class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span
                                    >{{ "PROCESSING" | translate }}...
                                </span>
                                <span *ngIf="newSource._frontData?.finished && !newSource._frontData?.error">&nbsp;-&nbsp;{{ "CREATED" | translate }}</span>
                                <span *ngIf="newSource._frontData?.finished && newSource._frontData?.error"
                                    >&nbsp;-&nbsp;{{ "ERROR" | translate }}: {{ newSource._frontData?.errorMsg }}</span
                                >
                            </li>
                        </ul>
                    </div>
                    <div *ngIf="!savingMultiple">
                        <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    </div>
                    <hr class="mt-0" />

                    <div class="text-end">
                        <button
                            type="button"
                            class="btn btn-outline-secondary float-start"
                            (click)="back()"
                            *ngIf="!isEdit && !savingMultiple"
                            [disabled]="saving"
                        >
                            <fa-icon icon="chevron-left" size="sm"></fa-icon>
                            {{ "BACK" | translate }}
                        </button>
                        <button type="button" class="btn btn-outline-secondary" (click)="cancel()" *ngIf="!savingMultiple" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary ms-2"
                            *ngIf="!savingMultiple"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && (form.invalid || tagsControl.invalid) }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                        <button type="button" class="btn btn-secondary" (click)="done()" *ngIf="savingMultiple" [disabled]="saving">
                            {{ "DONE" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
