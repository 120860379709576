<!-- Standard -->
<ngb-alert [dismissible]="false" [type]="'info'" *ngIf="channel.active_flapping && channel.is_enabled">
    <strong>{{ "INFO" | translate }}</strong> - {{ "FLAPPING_STATE" | translate }}
    <zx-date-time-zone [dateTime]="channel.flapping" />
</ngb-alert>
<zx-active-states [activeStates]="channel.activeStates" [refreshFunction]="refreshChannel" [canEdit]="canEdit" [objectName]="channel.name"></zx-active-states>
<!-- Extra -->
<div *ngIf="channel.type === 'delivery'">
    <div *ngFor="let src of channel.sources">
        <zx-active-states
            [activeStates]="src.source.activeStates"
            [refreshFunction]="refreshChannel"
            [canEdit]="canEdit"
            [objectName]="channel.name"
        ></zx-active-states>
    </div>
</div>
<div *ngIf="channel.type === 'adaptive' && channel.is_transcoding">
    <zx-active-states
        [activeStates]="channel.bitrates[0].source.activeStates"
        [refreshFunction]="refreshChannel"
        [canEdit]="canEdit"
        [objectName]="channel.name"
    ></zx-active-states>
</div>
<!-- Repeat -->
<div *ngIf="channel.type === 'adaptive'">
    <div *ngIf="channel.adaptive && !channel.is_transcoding">
        <div *ngFor="let bitrate of channel.bitrates">
            <zx-active-states
                [activeStates]="bitrate.source.activeStates"
                [refreshFunction]="refreshChannel"
                [canEdit]="canEdit"
                [objectName]="channel.name"
            ></zx-active-states>
        </div>
    </div>
</div>
